import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge'
import { Typography } from '@material-ui/core';

export function Action(props) {
    const { title, badgeCount, MaterialIcon, handleClick, row, color = 'primary' } = props
    return (
      <Tooltip title={title}>
        <Badge badgeContent={badgeCount} color="secondary">
          <IconButton onClick={e => handleClick(e, row)}>
            <MaterialIcon style={{ color: color }} />
          </IconButton>
        </Badge>
      </Tooltip>
    )
  }

  export function ActionTooltip(props){
    const {title , label, handleClick, MaterialIcon, color, selected} = props
    return(
        <Tooltip title={title}>
            <IconButton aria-label={label} onClick={e => handleClick(e, selected)}>
              <MaterialIcon  style={{color: color}}/>  
              <Typography style={{marginLeft: 5}}>{label || ""}</Typography>
            </IconButton>
        </Tooltip>
    )
  }