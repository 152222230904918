
export const tableTypes = {
    MONEY: 'money',
    MONEY2: 'money2',
    DATE: 'date',
    DATE_TIME: 'date_time',
    STATUS: 'status',
    SIGNED_NUMBER: 'signed_number',
    PRECISION2: 'precision2',
    PERCENT: 'percent',
    TEXT: 'text',
    CUSTOM: 'custom',
    CUSTOM_ROW: 'custom_row',
    NAME: 'name',
    BOOLEAN: 'boolean'
}

