import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, Grid, InputLabel } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { clearAllMask } from '../../util/utils'

import i18n from '../../../i18n';

class FormFazenda extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            nome: "",
            area: 0,
            cnpj: "",
            ie: "",
            telefone_fazenda: "",
            telefone_cobranca: "",
            email_fazenda: "",
            email_financeiro: "",
            localizacao: { type: "Point", coordinates: [0, 0] },
            delimitacao: undefined,
            status: 1,
            exibir: false,
            logo_id: 0,
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = () => {
        this.state.telefone_cobranca = clearAllMask(this.state.telefone_cobranca)
        this.state.telefone_fazenda = clearAllMask(this.state.telefone_fazenda)
        this.state.cnpj = clearAllMask(this.state.cnpj)
        this.state.ie = clearAllMask(this.state.ie)
        const data = filterAndDeleteValues(this.state, {
            nome: [""], area_ha: [0], cnpj: [''], ie: [''], telefone_fazenda: [''], telefone_cobranca: [''], localizacao:[{}],
            email_fazenda: [''], email_financeiro: [""], delimitacao: [undefined], logo_id: [0]
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeLocation = (e) => {
        const { name, value } = e.target;
        if (name === 'lng') this.state.localizacao.coordinates[0] = value
        else if (name === "lat") this.state.localizacao.coordinates[1] = value
        this.setState({})
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_nome"
                        label={i18n.t('filterfazenda.nomefazenda', 'Nome da Fazenda')}
                        name="nome"
                        fullWidth
                        type="text"
                        value={this.state.nome}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                    >
                    </TextValidator>
                </FormControl>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextValidator}
                                format="###.###.###.##.##"
                                id="ie"
                                label={i18n.t('filterfazenda.inscricaoestadual', 'Inscrição Estadual')}
                                name="ie"
                                fullWidth
                                type="text"
                                value={this.state.ie}
                                onChange={this.handleChange}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextField}
                                format="##.###.###/####-##"
                                id="cnpj"
                                label="CNPJ"
                                name="cnpj"
                                fullWidth
                                type="text"
                                value={this.state.cnpj}
                                onChange={this.handleChange}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextValidator}
                                format="#####-####"
                                id="telefone_fazenda"
                                label={i18n.t('filterfazenda.telefonefazenda', 'Telefone da Fazenda')}
                                name="telefone_fazenda"
                                required
                                fullWidth
                                type="text"
                                value={this.state.telefone_fazenda}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextField}
                                format="#####-####"
                                id="telefone_cobranca"
                                label={i18n.t('filterfazenda.telefonecobranca', 'Telefone para Cobrança')}
                                name="telefone_cobranca"
                                fullWidth
                                type="text"
                                value={this.state.telefone_cobranca}
                                onChange={this.handleChange}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="email_fazenda"
                                label={i18n.t('filterfazenda.emailfazenda', 'Email da Fazenda')}
                                name="email_fazenda"
                                fullWidth
                                required
                                type="text"
                                value={this.state.email_fazenda}
                                onChange={this.handleChange}
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    i18n.t('validator.required', 'Este campo é obrigatório'), 
                                    i18n.t('validator.isemail', 'Não é um email válido')
                                ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="email_financeiro"
                                label={i18n.t('filterfazenda.emailfinanceiro', 'Email Financeiro')}
                                name="email_financeiro"
                                fullWidth
                                type="text"
                                value={this.state.email_financeiro}
                                onChange={this.handleChange}
                                validators={['isEmail']}
                                errorMessages={[i18n.t('validator.isemail', 'Não é um email válido') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="latitude"
                                label={i18n.t('filterfazenda.latitude', 'Latitude')}
                                name="lat"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao.coordinates[1]}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="longitude"
                                label={i18n.t('fazenda.longitude', 'Longitude')}
                                name="lng"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao.coordinates[0]}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="area"
                                label={i18n.t('fazenda.areatotal', 'Área Total (ha)')}
                                name="area"
                                fullWidth
                                required
                                type="number"
                                value={this.state.area}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>
              
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{i18n.t('fazenda.status', 'Status')}</InputLabel>
                            <NativeSelect
                                id='ns_status'
                                fullWidth
                                value={this.state.status}
                                name='status'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{i18n.t('status.ativo', 'Ativo')}</option>
                                <option value={0}>{i18n.t('status.inativo', 'Inativo')}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{i18n.t('fazenda.exibirmapa', 'Exibir no Mapa')}</InputLabel>
                            <NativeSelect
                                id='exibir'
                                fullWidth
                                value={this.state.exibir}
                                name='exibir'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{i18n.t('formfazenda.sim', 'Sim')}</option>
                                <option value={0}>{i18n.t('formfazenda.nao', 'Não')}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormFazenda)