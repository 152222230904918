
import geodesy from 'leaflet-geodesy'
import { GeometryUtil } from 'leaflet'
import * as turf from '@turf/turf'

export function reverseGeocodingPolygon(vetGeo = []) {
    let copyValues = [...vetGeo]
    return copyValues.map(vg => [vg[1], vg[0]])
}

export function reverseGeocodingPoint(vetGeo = []) {
    return [vetGeo[1], vetGeo[0]]
}

export function polygonToGeojson(values = []) {
    let copyValues = [...values]
    copyValues = copyValues.map(v => [v.lat, v.lng])
    copyValues.push(copyValues[0])  // Fecha o poligono
    return copyValues
}

export function geojsonToPolygon(values = []) {
    let copyValues = [...values]
    copyValues = copyValues.map(v => { return { lat: v[0], lng: v[1] } })
    return copyValues
}

export function sectorTurf(coordinates, radius, bearing1, bearing2) {

     var point = turf.point(coordinates);
     var raio = radius/1000;
    // var bearing1 = 0;
    // var bearing2 = 90;
    const coords = turf.sector(point, raio, bearing1, bearing2  );
    
   
    return coords
}


export function convertCircleToPolygon(coordinates = [0, 0], radius = 100, edges = 32) {
    const polyGeo = geodesy.circle(coordinates, radius, { parts: edges })
    const coords = polygonToGeojson(polyGeo.getLatLngs()[0])
    return { type: "Polygon", coordinates: [coords] }
}

export function calculateAreaHa(coordinates) {
    try {
        const polygonCoord = geojsonToPolygon(coordinates)
        const value = Math.round((GeometryUtil.geodesicArea(polygonCoord) / 10000) * 1000) / 1000
        return value
    } catch (error) {
        return 'NaN'
    }
}

/** Aprimorar */
export function getCenterPolygon(coordinates) {
    if (coordinates && coordinates.lenght > 0) {
        return coordinates[0]
    }
}