import React from 'react'
import { headRowsContrato } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add,  Edit } from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { contratoService } from '../../services/contratoService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { Typography, IconButton } from '@material-ui/core'
import { makeActionFormDialog } from '../default/dialogs/DialogRefInterface'
import FormContrato from './FormContrato'
import i18n from '../../../i18n'

class TableContrato extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsContrato,
        }
        this.objectTableRef = React.createRef()
    }

    getContratos = () => {
        contratoService.get({ fazenda_id: this.props.data.fazenda_id}, response => {
            if (validateShowResponseToast(response, '', false)) {
                this.setState({
                    rows: response.data
                })
            }
        })
    }

    createContrato = () => {
        makeActionFormDialog(FormContrato, i18n.t('tablecontrato.novocontrato', 'Novo Contrato'),
         false, { fazenda_id: this.props.data.fazenda_id }, "Salvar", data => {
            contratoService.create(data, response => {
                if (validateShowResponseToast(response)) {
                    this.getContratos()
                }
            })
        }).current.open()
    }

    componentDidMount() {
        this.getContratos()
    }

    selectedToolActions = []

    toolActions = [
        {
            title: i18n.t('tableactions.add', "Adicionar"),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                this.createContrato()
            },
        },
    ]

    actions = [
        {
            title: i18n.t('tableactions.edit', "Editar"),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormContrato, i18n.t('tablecontrato.editarcontrato', 'Editar Contrato'), 
                false, selected, i18n.t('tablecontrato.salvar', 'Salvar'), data => {
                    contratoService.update(data, response => {
                        if (validateShowResponseToast(response)) {
                            this.getContratos()
                        }
                    })
                }).current.open()
            },
        },
    ]

    render() {
        if (this.state.rows.length > 0) {
            return (
                <ObjectTable
                    rowId='id'
                    tableName=''
                    orderBy="createdAt"
                    headRows={this.state.headRows}
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={10}
                    rowsOptions={[5, 10, 20]}
                    dense={true}

                    ref={this.objectTableRef}
                    rows={this.state.rows || []}
                />
            )
        } else {
            return <div>
                <Typography align="center">
                    { i18n.t('tablecontrato.msg_naohacontratos', 'Não há contratos para esta fazenda') } 
                    <IconButton 
                        onClick={ (e) => {
                            e.stopPropagation()
                            this.createContrato()
                            }
                        }
                        style={{color: appColors.ICONS_CONFIRM}}>
                        <Add/> { i18n.t('tablecontrato.novo', 'Novo') } 
                    </IconButton>
                </Typography>
            </div>
        }
    }
}

export default TableContrato