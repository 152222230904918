import moment from "moment";
import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "moment/locale/pt-br";
import i18n from "../i18n";
import "./App.css";
import FormLogin from "./components/authenticate/FormLogin";
import FormRecover from "./components/authenticate/FormRecover";
import FormSendRecover from "./components/authenticate/FormSendRecover";
import Dashboard from "./components/dashboard/Dashboard";
import ActionFormDialog from "./components/default/dialogs/ActionFormDialog";
import AlertDialog from "./components/default/dialogs/AlertDialog";
import {
  makeAlertDialog,
  makeProgressDialog,
  makeObjectListDialog,
  makeObjectListDialog2,
  makeActionFormDialog,
  makeActionFormDialog2,
} from "./components/default/dialogs/DialogRefInterface";
import ObjectListDialog from "./components/default/dialogs/ObjectListDialog";
import ProgressDialog from "./components/default/dialogs/ProgressDialog";
import { appColors } from "./constants/app_constants";
import browserHistory from "./helpers/browser_history";
import { userService } from "./services/user_service";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-toastify/dist/ReactToastify.css";

function NotFoundPage() {
  return <h2>{i18n.t("app.pagenotfound", "404 - Página Não Encontrada!")}</h2>;
}

function Login({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("user") ? (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        ) : (
          <Component {...props} browserHistory={browserHistory} />
        )}
    />
  );
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )}
    />
  );
}

async function recoverSelectedLanguage() {
  const lang = await localStorage.getItem("language");
  if(lang !== null) {
    return lang
  }else{
    return 'pt-BR'
  }
}

class App extends Component {
  constructor(props) {
    super(props);
  }

  async componentWillMount() {
    const lang = await recoverSelectedLanguage()
    await i18n.changeLanguage(lang);
    this.setState({ ...this.state });
  }

  render() {
    document.body.style = `background: ${appColors.PRIMARY};`;
    return (
      <div className="App">
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"pt-br"}
        >
          <AlertDialog ref={makeAlertDialog()} />
          <ProgressDialog ref={makeProgressDialog()} />
          <ObjectListDialog ref={makeObjectListDialog()} />
          <ObjectListDialog ref={makeObjectListDialog2()} />
          <ActionFormDialog ref={makeActionFormDialog()} />
          <ActionFormDialog ref={makeActionFormDialog2()} />
          <Router history={browserHistory}>
            <Switch>
              <Login
                authed={userService.getAll()}
                exact
                path="/"
                component={FormLogin}
              />
              <Login
                authed={userService.getAll()}
                exact
                path="/recuperar/:token"
                component={FormRecover}
              />
              <Login
                authed={userService.getAll()}
                exact
                path="/recuperar"
                component={FormSendRecover}
              />

              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/users"
                component={Dashboard}
              />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Router>
          <ToastContainer autoClose={3000} position="bottom-right" draggable />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default App;
