import React, { Suspense } from "react"
import { render } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux"
import promise from 'redux-promise'
import { appColors } from "./app/constants/app_constants"
import "./index.css"
import App from "./app/App"
import "./i18n"

import reducers from "./app/reducers/reducers"

require("dotenv").config();

// Cor da laranja da logo da empresa
const colorPrimary = appColors.PRIMARY;

const theme = createMuiTheme({
  palette: {
    primary: { 500: colorPrimary }, 
    secondary: {
      main: appColors.SECONDARY,
    },
  },
  action: appColors.ICONS_CONFIRM,
  status: {
    danger: "orange",
  },
});

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = applyMiddleware(promise)(createStore)(reducers, devTools)

render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
)
