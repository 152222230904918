export const dashboardActions = {
    DASHBOARD_OPEN_MENU: "DOM",
    DASHBOARD_OPEN_SETTINGS: "DOS",

    RELOAD_DISPOSITIVOS: "RDS",
    RELOAD_FAZENDAS: "RFS",
    RELOAD_PARCELAS: "RPS",
    RELOAD_EQUIPAMENTOS: "RES"
}

export const windyMapActions = {
    CHANGE_WINDY_MAP_OPACITY: "CWMO",
    CHANGE_WINDY_MAP_ZOOM: "CWMZ",
    
    SHOW_DISPOSITIVOS: "SDS",
    SHOW_PARCELAS: "SPS",
    SHOW_FAZENDAS: "SFS",
    SHOW_EQUIPAMENTOS :"SES" 
}
