import React from 'react';
import { buildEstacaoSimplificadaAttributes } from './chart_attributes';
import { estacaoSimplificadaDataService } from '../../services/estacaoSimplificadaDataService';
import DispositivoChart from './DispositivoChart';

export default (props) => {
    return (
        <DispositivoChart
            dispositivo={props.data}
            service={ estacaoSimplificadaDataService }
            allAttributes={buildEstacaoSimplificadaAttributes()}
        ></DispositivoChart>
    )
}