import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';

export const contratoService = {
   get,
   create,
   update
};

async function get(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const query = make_query(data)
    response = await getAxiosInstance(`/contratos${query}`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
    return response || errorResponse
}

async function create(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/contratos').post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function update(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/contratos/${data.id}`).put(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
