import React, { forwardRef } from 'react'
import { Grid, Slider, Typography, Container, FormControlLabel, Checkbox } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { handleChangeWindyOpacity, handleChangeZoom, 
  showParcelas, showFazendas, showDispositivos , showEquipamentos
} from '../../reducers/windymap/actions'
import i18n from '../../../i18n'

const MapSettings = forwardRef((props, ref) => {

  function handleChange(e) {
    const { checked, name } = e.target
    props[name](checked)
  }

  const { handleChangeWindyOpacity, opacity, handleChangeZoom, zoom } = props

  return (
    <Container style={{ maxWidth: 300 }}>
      <Typography style={{ marginBottom: 20 }}>{ i18n.t('mapsettings.configuracoesmapa', 'Configurações do mapa') }</Typography>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={4}>
          <Typography>Windy</Typography>
        </Grid>
        <Grid item xs={8}>
          <Slider
            defaultValue={opacity}
            min={0}
            max={1}
            step={.05}
            onChange={handleChangeWindyOpacity}
          ></Slider>
        </Grid>
      </Grid>
      <Grid container direction="row" >
        <Grid item xs={4}>
          Zoom
          </Grid>
        <Grid item xs={8}>
          <Slider
            defaultValue={zoom}
            min={1}
            max={20}
            step={0.5}
            onChange={handleChangeZoom}
          ></Slider>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isShowFazendas}
                onChange={handleChange}
                value="primary"
                name="showFazendas"
              />
            }
            label={ i18n.t('mapsettings.mostrarfazendas', 'Mostrar Fazendas') }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isShowDispositivos}
                onChange={handleChange}
                value="primary"
                name="showDispositivos"
              />
            }
            label={ i18n.t('mapsettings.mostrardispositivos', 'Mostrar Dispositivos') }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isShowParcelas}
                onChange={handleChange}
                value="primary"
                name="showParcelas"
              />
            }
            label={ i18n.t('mapsettings.mostrarparcelas', 'Mostrar Parcelas') }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isShowEquipamentos}
                onChange={handleChange}
                value="primary"
                name="showEquipamentos"
              />
            }
            label={ i18n.t('mapsettings.mostrarequipamentos', 'Mostrar Equipamentos') }
          />
        </Grid>
      </Grid>
    </Container>
  )
})

const mapStateToProps = (state) => {
  return {
    opacity: state.windyMap.opacity,
    zoom: state.windyMap.zoom,
    isShowFazendas: state.windyMap.isShowFazendas,
    isShowDispositivos: state.windyMap.isShowDispositivos,
    isShowParcelas: state.windyMap.isShowParcelas,
    isShowEquipamentos: state.windyMap.isShowEquipamentos
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ handleChangeWindyOpacity, handleChangeZoom,
                              showFazendas, showParcelas, showDispositivos , showEquipamentos }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MapSettings)
