import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { userService } from '../../services/user_service';
import { toast } from 'react-toastify'
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import { appColors } from '../../constants/app_constants';
import i18n from '../../../i18n';

class FormRecover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            senha: '',
            confirm_senha: '',
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentWillUpdate() {

    }

    switch_route = (route) => {
        if (this.props.browserHistory) {
            this.props.browserHistory.push(route)
        }
    }

    requestRecover = () => {
        const data = { senha: this.state.senha }
        data.token = this.props.match.params.token
        if (this.state.senha !== '' && this.state.senha === this.state.confirm_senha) {
            userService.recoverSenha(data, response => {
                if (validateShowResponseToast(response, "", false)) {
                    toast.success(
                        i18n.t('formrecover.msg_novasenhasucesso', "Nova senha cadastrada com sucesso!")
                    )
                }
            })
        } else {
            toast.warn(
                i18n.t('formrecover.msg_senhanaoconfere', "Senhas não conferem")
            )
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.requestRecover()
        }
    }

    handleClick(e) {
        e.preventDefault();
        this.requestRecover()
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                <img style={{ margin: 10 }} width={'40%'}src="/static/images/logo.png"></img>
       
                    <Typography color="secondary" component="h1" variant="h5">
                        { i18n.t('formrecover.novasenha', "Nova Senha") } 
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            className={classes.textStyle}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="senha"
                            label={ i18n.t('formrecover.senha', "Senha")}
                            type="password"
                            id="senha"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.senha}
                        />
                        <TextField
                            className={classes.textStyle}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirm_senha"
                            label={ i18n.t('formrecover.confirmarsenha', "Confirmar Senha" ) }
                            type="password"
                            id="confirm_senha"
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.confirm_senha}
                        />
                        <Button
                            fullWidth
                            onClick={this.handleClick}
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                        >
                            { i18n.t('formrecover.trocasenha', 'Trocar Senha' ) }
                        </Button>
                        <Grid container justify="center">
                            <Grid item xs={6}>
                                <Link onClick={e => this.switch_route('/')}variant="body2" style={{color: appColors.SECONDARY}}>
                                    { i18n.t('formrecover.voltarlogin', 'Voltar para login') }
                        </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        );
    }
}

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textStyle: {
        backgroundColor: appColors.LIGHT,
      },
});

export default withStyles(styles)(FormRecover);
