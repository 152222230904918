import React from 'react';
import { buildEstacaoSateliteAttributes } from './chart_attributes.js';
import { estacaoSateliteDataService } from '../../services/estacaoSateliteDataService.js';
import DispositivoChart from './DispositivoChart.jsx';

export default (props) => {
    return (
        <DispositivoChart
            dispositivo={props.data}
            service={ estacaoSateliteDataService }
            allAttributes={buildEstacaoSateliteAttributes()}
        ></DispositivoChart>
    )
}