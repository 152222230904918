import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, Grid, InputLabel, Button } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { clearAllMask } from '../../util/utils'
import { estadosCivis } from '../../constants/model_constants'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import moment from 'moment'
import i18n from '../../../i18n'

class FormPessoa extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            nome: "",
            rg: "",
            cpf: "",
            estado_civil: "",
            nacionalidade: "",
            data_nascimento: moment(),
            cargo: "",
            foto_id: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = () => {
        this.state.rg = clearAllMask(this.state.rg)
        this.state.cpf = clearAllMask(this.state.cpf)
        const data = filterAndDeleteValues(this.state, {
            nome: [''],  cpf: [''], data_nascimento: [''], foto_id: [''], id: [0]
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeLocation = (e) => {
        const { name, value } = e.target;
        if (name === 'lng') this.state.localizacao.coordinates[0] = value
        else if (name === "lat") this.state.localizacao.coordinates[1] = value
        this.setState({})
    }


    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_nome"
                        label="Nome Completo"
                        name="nome"
                        fullWidth
                        type="text"
                        value={this.state.nome}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                    >
                    </TextValidator>
                </FormControl>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                fullWidth
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={ i18n.t('pessoa.data_nascimento', 'Data de Nascimento') }
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_nascimento"
                                value={this.state.data_nascimento}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextValidator}
                                format="##.###.###-#"
                                id="rg"
                                label="RG"
                                name="rg"
                                fullWidth
                                type="text"
                                value={this.state.rg}
                                onChange={this.handleChange}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <NumberFormat
                                customInput={TextValidator}
                                format="###.###.###-##"
                                id="cpf"
                                label="CPF"
                                name="cpf"
                                fullWidth
                                type="text"
                                value={this.state.cpf}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </NumberFormat>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('pessoa.estado_civil', 'Estado Cívil') }</InputLabel>
                            <NativeSelect
                                id='ns_estado_civil'
                                fullWidth
                                value={this.state.estado_civil}
                                name='estado_civil'
                                onChange={this.handleChange}
                            >
                                <option value={""}></option>
                                {estadosCivis.map(ec => {
                                    return <option value={ec.id}>{ec.desc}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                customInput={TextField}
                                format="#####-####"
                                id="nacionalidade"
                                label={ i18n.t('pessoa.nacionalidade', 'Nacionalidade') }
                                name="nacionalidade"
                                fullWidth
                                type="text"
                                value={this.state.nacionalidade}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="cargo"
                                label={ i18n.t('pessoa.cargo', 'Cargo') }
                                name="cargo"
                                fullWidth
                                type="text"
                                value={this.state.cargo}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >Status</InputLabel>
                            <NativeSelect
                                id='ns_status'
                                fullWidth
                                value={this.state.status}
                                name='status'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{ i18n.t('status.ativo', 'Ativo') }</option>
                                <option value={0}>{ i18n.t('status.inativo', 'Inativo') }</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormPessoa)