import React from 'react'
import { tableTypes } from "../components/default/table/table_types"
import ShowFuncoes from '../components/_pessoa_fazenda/ShowFuncoes'
import i18n from '../../i18n'

export function col(id,  label, type="text", disablePadding=false, numeric=false, handleCustom=undefined, handleValue){
  return {id, label, type, disablePadding, numeric, handleCustom, handleValue}
}

export function headRowsUser(){
  return [
    col('nome', i18n.t('headrows.user_nome', 'Nome')),
    col('email', i18n.t('headrows.user_email', 'Email'), 'email'),
    col('nivel_permissao', i18n.t('headrows.user_nivel_permissao', 'Nível Permissão'), tableTypes.CUSTOM, undefined, undefined, (row) => {
      if(row.nivel_permissao===1) return i18n.t('headrows.nivel_permisao.baixo', 'Baixo')
      else if(row.nivel_permissao===2) return i18n.t('headrows.nivel_permisao.medio', 'Médio')
      else if(row.nivel_permissao===3) return i18n.t('headrows.nivel_permisao.alto', 'Alto')
     }),
    col('status', i18n.t('headrows.user_status', 'Status'), tableTypes.STATUS)
  ]
} 

export function headRowsFazenda(){
  return [
    col('nome', i18n.t('headrows.fazenda_nome', 'Nome')),
    col('area', i18n.t('headrows.fazenda_area', 'Área Total (ha)')),
    col('ie', i18n.t('headrows.fazenda_ie', 'Inscrição Estadual' )),
    col('telefone_fazenda', i18n.t('headrows.fazenda_telefone_fazenda', 'Telefone Principal')),
    col('status', i18n.t('headrows.fazenda_status', 'Status' ), tableTypes.STATUS)
    /** TODO adicionar demais campos, foto, etc */
  ]
} 

export function headRowsPessoa(){
  return [
    col('nome', i18n.t('headrows.pessoa_nome', 'Nome')),
    col('cpf', i18n.t('headrows.pessoa_cpf', 'CPF')),
    col('contato_email', i18n.t('headrows.pessoa_contato_email', 'Email')),
    col('contato_celular', i18n.t('headrows.pessoa_contato_celular', 'Telefone de Contato')),
  ]
}

export function headRowsParcela() {
  return [
    col('nome', i18n.t('headrows.parcela_nome', 'Descrição')),
    col('cultura', i18n.t('headrows.parcela_cultura', 'Cultura')),
    col('solo', i18n.t('headrows.parcela_solo', 'Tipo Solo')),
    col('equipamento_descricao', i18n.t('headrows.parcela_equipamento_descricao', 'Tipo Irrigação')),
    col('area', i18n.t('headrows.parcela_area', 'Área Total (ha)')),
    col('data_plantio', i18n.t('headrows.parcela_plantio', 'Plantio'), tableTypes.DATE),
    col('status', i18n.t('headrows.parcela_status', 'Status' ), tableTypes.STATUS)
  ]
}
 
export function headRowsEquipamento() {
  return [
    col('nome', i18n.t('headrows.equipamento_nome', 'Descrição')),
    col('raio', i18n.t('headrows.equipamento_raio', 'Raio ')),
    col('uniformidade', i18n.t('headrows.equipamento_uniformidade', 'Uniformidade')),
    col('area', i18n.t('headrows.parcela_area', 'Área Total (ha)')),
    col('data_instalacao', i18n.t('headrows.equipamento_data_instalacao', 'Data de Instação'), tableTypes.DATE),
    col('status', i18n.t('headrows.equipamento_status', 'Status' ), tableTypes.STATUS)
  ]
}

export function headRowsDispositivo() {
  return [
    col('nome', i18n.t('headrows.dispositivo_nome', 'Descrição')),
    col('idx_dispositivo', i18n.t('headrows.dispositivo_id', 'Id')),
    col('tipo', i18n.t('headrows.dispositivo_tipo_dispositivo', 'Tipo Dispositivo')),
    col('data_instalacao', i18n.t('headrows.dispositivo_data_instalacao', 'Data Instalação'), tableTypes.DATE),
  ]
}

export function headRowsPessoaFazenda(){
  return [
    col('pessoa.nome', i18n.t('headrows.pessoafazenda_nome', 'Nome')),
    col('fazenda.nome', i18n.t('headrows.pessoafazenda_fazenda', 'Fazenda')),
    col('vinculo', i18n.t('headrows.pessoafazenda_vinculo', 'Vínculos'), tableTypes.CUSTOM_ROW, false, false, pf => {
      return <ShowFuncoes data={pf}/>
    }),
    col('status', i18n.t('headrows.pessoafazenda_status', 'Status'), tableTypes.STATUS)
  ]
}

export function headRowsContrato(){
  return [
    col('data_inicio', i18n.t('headrows.contrato_data_inicio', 'Data Inicial'), tableTypes.DATE),
    col('data_final', i18n.t('headrows.contrato_data_final', 'Data Final'), tableTypes.DATE),
    col('visitas_contrato', i18n.t('headrows.contrato_visitas_contrato', 'Número Visitas')),
    col('visitas_realizadas', i18n.t('headrows.contrato_visitas_realizadas', 'Visitas Realizadas')),
    col('status', i18n.t('headrows.contrato_status', 'Status'), tableTypes.STATUS)
  ]
}
