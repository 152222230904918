import React from 'react'
import PropTypes from 'prop-types'
import { CardContent, Grid, Button, Typography, Card  } from '@material-ui/core'
import Charts from '../_graficos/ApexOverride'
import i18n from '../../../i18n'
import { appColors } from '../../constants/app_constants'
import getFotoFile from '../../services/qualiagroService'
import AliceCarousel from 'react-alice-carousel';
import { withStyles } from '@material-ui/core/styles'
import RelatorioOrbital from '../../components/_parcela/RelatorioOrbital'
import FilterRelatorio from '../../components/_parcela/FilterRelatorio'
import {
    SkipNext, SkipPrevious, PictureAsPdf, Description
} from '@material-ui/icons';
import { IMG_BACK } from '../../constants/base64images/BACKGROUND'
import {
    makeObjectListDialog, makeAlertDialog, makeActionFormDialog,
} from "../default/dialogs/DialogRefInterface"

class ChartOrbital extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            orbital: {},
            options: {},
            series: [],
        }
    }

    dateConvert(date) {
        var dateParse = new Date(date)
        return dateParse.toLocaleDateString()
    }

    componentDidMount() {
        this.setState({ orbital: this.props.data })
        const data = this.props.data
        // if (data.orbitals) {
        //     this.setState({
        //         galleryItems:
        //             data.orbitals.map((i) =>
        //                 <Card id={'chart'} key={i} style={{ width: '380px', height: '400px', marginLeft: "auto", marginRight: "auto", }} >
        //                     {/* <CardContent>
        //                         <img src={getFotoFile(i.id)} style={{ width: '350px', height: '330px', borderRadius: '5%', marginLeft: "auto", marginRight: "auto" }}  ></img>
        //                     </CardContent>
        //                     <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM }}>
        //                         {this.dateConvert(i.createdAt)}
        //                     </Typography> */}
        //                 </Card>)
        //     })
        // }
    }

    componentWillMount() {
        if (this.props.data.orbitals) {
            this.setState({
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        color: ['00B050'],
                        zoom: {
                            enabled: false
                        }
                    }, stroke: {
                        width: 7,
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: this.props.data.orbitals.map((i) => this.dateConvert(i.createdAt))
                    },
                },
                series: [{
                    name: "Umidade",
                    data: this.props.data.orbitals.map((i) => i.uniformidade != null ? i.uniformidade : 0)
                }],
            })
        }
    }
    handleFilter = () => {
        makeActionFormDialog(FilterRelatorio, "Filtrar Relatório Umidade Orbital", false, undefined, "Filtrar", (data) => {
           
            this.state.orbital.dateFilter = data
            this.setState({})

            makeActionFormDialog(RelatorioOrbital, '', false,
                this.state.orbital, false, data => {
                }).current.open()

        }).current.open()
    }

    render() {
        const { classes } = this.props
        return (
            <>

                <Grid container item className={classes.form} spacing={2}>
                    <Charts
                        options={this.state.options}
                        series={this.state.series}
                        type="line"
                        width={360}
                        height={300}
                    ></Charts>
                   
                    {/* <Grid container item className={classes.form} spacing={2}>
                        <AliceCarousel
                            dotsDisabled={true}
                            buttonsDisabled={true}
                            items={this.state.galleryItems}
                            ref={(el) => (this.Carousel = el)}
                            infinite={false} />
                        <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={() => this.Carousel.slideNext()}>Próximo <SkipNext /> </Button>
                        <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={() => this.Carousel.slidePrev()}><SkipPrevious /> Anterior </Button>
                    </Grid> */}
                    <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={this.handleFilter}> <Description />  Relatório</Button>
                </Grid>


            </>
        )
    }
}


const styles = (theme) => ({
    root: {
        color: '#ffffff',
        // backgroundColor: '#201F29',
    },
    nested: {
        paddingRight: theme.spacing(4),
    },
    labelColor: {
        color: 'white'
    },
    image: {
        width: '280px',
        height: '280px',
        borderRadius: '50%'
    },
    form: {
        minHeigth: 420,
        //minHeigth: 820,
        maxWidth: 380,
        minWidth: 380,
        color: appColors.ICONS_CONFIRM,
        // padding: '2vh',
        // marginLeft: "auto", marginRight: "auto",

    },
})


export default withStyles(styles)(ChartOrbital)