import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import moment from 'moment'
import {  formatValueByType } from './formatter'
import { CSVLink } from "react-csv"

import EnhancedTableHead from "./EnhancedTableHead"
import ObjectTableToolbar from './ObjectTableToolbar'
import { template_table } from '../templates_pdf/template_table';
import { makeSum } from '../../../util/utils';
import { findValue } from './table_util'
import FormatValue from './FormatValue'
import { Action } from './actions'
import { tableTypes } from './table_types'
import i18n from '../../../../i18n'

class ObjectTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableName: this.props.tableName || "",
      actions: this.props.actions || [],
      selectedToolActions: this.props.selectedToolActions || [],
      toolActions: this.props.toolActions || [],
      rowId: this.props.rowId || "",
      rowsPerPage: this.props.rowSize || 10,
      rowsPerPageOptions: this.props.rowsOptions || [5, 10, 15],
      emptyRows: 0,
      page: 0,
      order: this.props.order || "asc",
      orderBy: this.props.rowId || 'id',
      selected: [],
      dense: this.props.dense || true,

      isExport: false,
      isExportSelected: false,
      csvHeaders: [],
      rowsSum: []
    }

    this.handleChangeDense = this.handleChangeDense.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.handleClearSelected = this.handleClearSelected.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.linkCSVRef = React.createRef()
  }

  exportCSV = (csvHeaders = undefined, isExport = true, isExportSelected = false, rowsSum = []) => {
    if (!csvHeaders) csvHeaders = this.props.headRows.map(hr => { return { label: hr.label, key: hr.id } })
    this.setState({
      csvHeaders: csvHeaders,
      isExport: isExport,
      isExportSelected: isExportSelected,
      rowsSum: rowsSum,
    }, () => {
      if (this.linkCSVRef.current) {
        this.linkCSVRef.current.link.click()
      }
    })
  }

  exportPDF = (headRows, isExportSelected = false,
    pdfName = `${this.state.tableName}_${moment().format('DD_MM_YYYY')}`,
    orientation = 'p', title = '', rowsSum = [], rowId = "") => {
    this.setState({
      isExportSelected: isExportSelected
    }, () => {
      template_table(this.getExportRowsPDF(), headRows, orientation, title, rowsSum, rowId).save(pdfName)
    })
  }

  updateTable = (data = undefined) => {
    this.forceUpdate()
    if (data) {
      this.setState(data)
    }
  }

  getExportRowsPDF = () => {
    const exportRows = this.props.rows.filter(row => {
      if (this.state.isExportSelected) {
        return this.state.selected.indexOf(row[this.state.rowId]) > -1
      }
      return true
    })
    return exportRows
  }

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getExportRows = () => {
    let exportRows = this.props.rows.filter(row => {
      if (this.state.isExportSelected) {
        return this.state.selected.indexOf(row[this.state.rowId]) > -1
      }
      return true
    })
    if (this.state.rowsSum.length > 0) {
      exportRows.push(makeSum(exportRows, this.state.rowsSum, this.props.headRows, this.state.rowId))
    }
    exportRows = exportRows.map(row => {
      let lRow = { ...row }
      this.props.headRows.map(hr => {
        let value = formatValueByType(lRow[hr.id], hr.type)
        lRow[hr.id] = value === 'NaN' ? '----' : value
      })
      return lRow
    })
    return exportRows
  }

  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  isSelected = (name) => this.state.selected.indexOf(name) !== -1

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property })
  }

  handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = this.props.rows.map(n => n[this.state.rowId])
      this.setState({ selected: newSelecteds })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick(event, id) {
    event.stopPropagation()
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected })
  }

  handleClearSelected() {
    this.setState({ selected: [] })
  }

  handleChangePage(event, newPage) {
    if (this.state.page < newPage) {
      if ((newPage + 1) * this.state.rowsPerPage > this.props.perPage) {
        if (this.props.handleLoadPage) {
          newPage = 0
          this.props.handleLoadPage(1)
        }
      }
    }
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value,
      emptyRows: event.target.value - Math.min(event.target.value, this.props.rows.length - this.state.page * event.target.value)
    })
  }
  handleChangeDense(event) {
    this.setState({ dense: event.target.checked })
  }

  handleLabelFromTo = ({ from, to, count }) => {
    const lFrom = this.props.from || undefined
    const lTo = this.props.to || undefined
    return `${i18n.t('objecttable.showing', 'Mostrando')} 
      ${lFrom ? lFrom + from - 1 : from}-${lFrom ? lFrom + to - 1 : to} ${i18n.t('objecttable.of', 'de')} 
      ${count} ${i18n.t('objecttable.registers', 'registros')}`
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>

          <ObjectTableToolbar
            numSelected={this.props.showCheckBox ? this.state.selected.length : 0}
            selectedToolActions={this.props.selectedToolActions}
            toolActions={this.props.toolActions}
            selected={this.state.selected}
            tableName={this.state.tableName}
          >
          </ObjectTableToolbar>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tabldfeTitle"
              size={this.state.dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={this.props.showCheckBox ? this.state.selected.length : 0}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.rows ? this.props.rows.length || 0 : 0}
                headRows={this.props.headRows}
                tableName={this.state.tableName}
                showCheckBox={this.props.showCheckBox}
              />
              <TableBody>
                {this.stableSort(this.props.rows, this.getSorting(this.state.order, this.state.orderBy))
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row[this.state.rowId]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        key={row[this.state.rowId]}
                        hover={true}
                        onClick={event => this.handleClick(event, row[this.state.rowId])}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {this.props.showCheckBox ?
                          <TableCell key={`tablecell_${index}`} padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          :
                          <TableCell key={`div_${index}`}></TableCell>
                        }

                        {this.props.headRows.map(hr => {
                          const alignCell = hr.numeric ? 'center' : 'justify'
                          const type = hr.type ? hr.type : undefined
                          const cellTitle = this.props.cellTitle ? row[this.props.cellTitle] : ""
                          let value = findValue(row, hr.id)
                          if(hr.handleCustom) value = hr.handleCustom(row)
                          if(hr.handleCustom && hr.handleValue && type === tableTypes.CUSTOM_ROW){
                            const lValue = hr.handleValue(row)
                            row[hr.id] = lValue
                          }else{
                            row[hr.id] = row[hr.id] ? row[hr.id] : value
                          }
                          return (
                            <TableCell
                              key={hr.id}
                              align={'left'}
                              title={cellTitle}
                            >
                              <FormatValue
                                value={value}
                                type={type}
                                row={row}
                              />
                            </TableCell>
                          )
                        })}
                        <TableCell align="right" key={`tablecell2 ${index}`} 
                        style={{ paddingRight: 30, whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          {this.props.actions.map(act => {
                            return (
                              <Action
                                key={act.title}
                                title={act.title}contrair
                                badgeCount={act.badgeCount}
                                MaterialIcon={act.MaterialIcon}
                                handleClick={act.handleClick}
                                row={row}
                                color={act.color}
                              />
                            )
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {this.state.emptyRows > 0 && (
                  <TableRow style={{ height: 49 * this.state.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <CSVLink
            target="_blank"
            data={this.state.isExport ? this.getExportRows() : []}
            headers={this.state.csvHeaders}
            ref={this.linkCSVRef}
            filename={`${this.state.tableName}_${moment().format("DD_MM_YYYY")}.csv`}
          >
          </CSVLink>
          <TablePagination
            rowsPerPageOptions={this.state.rowsPerPageOptions}
            component="div"
            labelRowsPerPage={`${this.state.tableName} ${ i18n.t('objecttable.perpage', 'por Página')}`}
            count={this.props.count || this.props.rows.length}
            labelDisplayedRows={this.handleLabelFromTo}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': i18n.t('objecttable.prev', 'Anterior'),
            }}
            nextIconButtonProps={{
              'aria-label': i18n.t('objecttable.next', 'Próxima'),
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        {this.props.showDense ?
          <FormControlLabel
            control={<Switch checked={this.state.dense} onChange={this.handleChangeDense} />}
            label={i18n.t('objecttable.contract', 'Contrair Células')}
          /> :
          <div />
        }

      </div>
    )
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "blue"
    }
  }
})

export default withStyles(styles)(ObjectTable)