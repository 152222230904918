import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { headRowsParcela } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit, FilterList} from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import {  makeActionFormDialog } from '../default/dialogs/DialogRefInterface'
import { parcelaService } from '../../services/parcelaService'
import FormParcela from './FormParcela'
import FilterParcela from './FilterParcela'
import { reloadParcelas } from '../../reducers/dashboard/actions'
import i18n from '../../../i18n'

class TableParcela extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsParcela(),
            fazenda: {},
            equipamento: {},
            lastFilter: {}
         }
         this.objectTableRef = React.createRef()
    }
 
    getParcelas(){
        if(this.state.fazenda.id || this.state.id_equipamento){
            this.state.lastFilter.fazenda_id = this.state.fazenda ?  this.state.fazenda.id : this.state.equipamento.id_fazenda
            this.state.lastFilter.id_equipamento = this.state.equipamento.id_equipamento
           
        }
        
        parcelaService.get(this.state.lastFilter, response => {
            if(validateShowResponseToast(response, "", false)){
                this.setState({
                    rows: response.data
                })
            } 
        })
    }

    getFazendaParams = () => {
        const fazendaParams = {}
        if(this.state.fazenda.id){
            fazendaParams.fazenda_id = this.state.fazenda.id
            fazendaParams.fazenda =  this.state.fazenda
            fazendaParams.id_equipamento = this.state.equipamento.id_equipamento
            fazendaParams.equipamento =  this.state.equipamento
        }else{
            fazendaParams.id_equipamento = this.state.equipamento.id_equipamento
            fazendaParams.equipamento =  this.state.equipamento
        }
        return fazendaParams
    }

    onStart = () => {
        if(this.props.data){
          
            if( this.props.data.id_fazenda) {
            this.state.equipamento = this.props.data
            this.state.lastFilter.fazenda_id =  this.props.data.id_fazenda///this.props.data.id
            this.state.lastFilter.status = 1 // ATIVO
            }else{
                this.state.fazenda = this.props.data
                this.state.lastFilter.fazenda_id =  this.props.data.id_fazenda///this.props.data.id
                this.state.lastFilter.status = 1 // ATIVO
            }

        }
        this.getParcelas()
    }
  
    componentDidMount(){
       this.onStart()
    }

    selectedToolActions = [] 

    toolActions = [
        {
            title: i18n.t('tableactions.filter', 'Filtrar'),
            color: appColors.PRIMARY,
            MaterialIcon: FilterList,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterParcela, i18n.t('tableparcela.filterparcelas', 'Filtrar Parcelas'), 
                false, this.getFazendaParams(), i18n.t('tableactions.filter', 'Filtrar'), data => {
                    parcelaService.get(data, response => {
                        if(validateShowResponseToast(response, "", false)){
                            this.setState({
                                rows: response.data
                            })
                        }
                    })
                }).current.open()
            },
        },
        {
            title: i18n.t('tableactions.add', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormParcela, i18n.t('tableparcela.novaparcela', 'Nova Parcela'), false, 
                    this.getFazendaParams(), i18n.t('tableactions.save', 'Salvar'), data => {
                        
                    parcelaService.create(data, response => {
                        if(validateShowResponseToast(response, i18n.t('tableparcela.parcela', 'Parcela'),)){
                            this.getParcelas()
                            this.props.reloadParcelas()
                        }
                    })
                }).current.open()
            },
        },
    ]
    actions = [
        {
            title: i18n.t('tableactions.edit', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormParcela, i18n.t('tableparcela.editarparcela', 'Editar Parcela'), 
                false, selected, i18n.t('tableactions.save', 'Salvar'), data => {
                    parcelaService.update(data, response => {
                        if(validateShowResponseToast(response, i18n.t('tableparcela.parcela', 'Parcela'))){
                            this.getParcelas()
                            this.props.reloadParcelas()
                        }
                    })
            }).current.open()
            },
        },
    ]

    render(){
        return(
            <ObjectTable 
                    rowId='id'
                    tableName='' 
                    headRows={this.state.headRows} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={10}
                    rowsOptions={[10, 20, 40]}
                    dense={true}
                    ref={this.objectTableRef}
                    rows={this.state.rows || []}
                /> 
            )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ reloadParcelas}, dispatch)
}

export default connect(null, mapDispatchToProps)(TableParcela)