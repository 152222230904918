import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {  headRowsFazenda } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit, FilterList, LocalPizza, People, Receipt,AvTimer } from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { fazendaService } from '../../services/fazendaService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import {  makeActionFormDialog, makeObjectListDialog2 } from '../default/dialogs/DialogRefInterface'
import FormFazenda from './FormFazenda'
import FilterFazenda from './FilterFazenda'
import TableParcela from '../_parcela/TableParcela'
import TableEquipamento from '../_equipamento/TableEquipamento'
import { reloadFazendas } from '../../reducers/dashboard/actions'
import TablePessoaFazenda from '../_pessoa_fazenda/TablePessoaFazenda'
import TableContrato from '../_contrato/TableContrato'
import i18n from '../../../i18n'

class TableFazenda extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsFazenda(),
         }
         this.objectTableRef = React.createRef()
    }

    getFazendas(){
        fazendaService.get({}, response => {
            if(validateShowResponseToast(response, "", false)){
                this.setState({
                    rows: response.data
                })
            }
        })
    }
  
    componentDidMount(){
       this.getFazendas()
    }

    selectedToolActions = [] 

    toolActions = [
        {
            title: i18n.t('tableactions.filter', 'Filtrar'),
            color: appColors.PRIMARY,
            MaterialIcon: FilterList,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterFazenda, i18n.t('tablefazenda.filtrarfazendas', 'Filtrar Fazendas'), 
                false, null, i18n.t('tablefazenda.salvar', 'Salvar'), data => {
                    fazendaService.get(data, response => {
                        if(validateShowResponseToast(response, "", false)){
                            this.setState({
                                rows: response.data
                            })
                        }
                    })
                }).current.open()
            },
        },
        {
            title: i18n.t('tableactions.add', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFazenda, i18n.t('tablefazenda.novafazenda', 'Nova Fazenda'), 
                false, null, i18n.t('tablefazenda.salvar', 'Salvar'), data => {
                    fazendaService.create(data, response => {
                        if(validateShowResponseToast(response, 'Fazenda')){
                            this.props.reloadFazendas({status: 1})
                            this.getFazendas()
                        }
                    })
                }).current.open()
            },
        },
       
    ]
    actions = [
        // {
        //     title: i18n.t('tablefazenda.parcelas', 'Parcelas'),
        //     color: appColors.PRIMARY,
        //     MaterialIcon: LocalPizza,
        //     handleClick: (e, selected) => {
        //         e.stopPropagation()
        //         makeObjectListDialog2(TableParcela, i18n.t('tablefazenda.parcelas', 'Parcelas'), {}, selected, null, null).current.open()
        //     },
        // },
        {
            title: i18n.t('tablefazenda.equipamentos', 'Equipamentos'),
            color: appColors.PRIMARY,
            MaterialIcon: AvTimer,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeObjectListDialog2(TableEquipamento, i18n.t('tablefazenda.equipamentos', 'Equipamentos'), {}, selected, null, null).current.open()
            },
        },
        {
            title: i18n.t('tablefazenda.peoples', 'Pessoas'),
            color: appColors.PRIMARY,
            MaterialIcon: People,
            handleClick: (e, selected) => {
                e.stopPropagation()
                const data = {...selected, fazenda_id: selected.id}
                makeObjectListDialog2(TablePessoaFazenda, `${i18n.t('tablefazenda.pessoasvinculadas', 'Pessoas vinculadas a')} ${selected.nome}`, 
                { minWidth: 1000, minHeight: 500 }, data).current.open()
            },
        },
        {
            title: i18n.t('tablefazenda.contratos', 'Contratos'),
            color: appColors.PRIMARY,
            MaterialIcon: Receipt,
            handleClick: (e, selected) => {
                e.stopPropagation()
                const data = {...selected, fazenda_id: selected.id}
                makeObjectListDialog2(TableContrato, `${ i18n.t('tablefazenda.contratos', 'Contratos') } ${selected.nome}`, 
                { minWidth: 1000, minHeight: 500 }, data).current.open()
            },
        },
        {
            title: i18n.t('tableactions.edit', 'Editar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormFazenda, i18n.t('tablefazenda.editarfazendas', 'Editar Fazenda'), 
                false, selected, i18n.t('tablefazenda.save', 'Salvar'), data => {
                    fazendaService.update(data, response => {
                        if(validateShowResponseToast(response)){
                            this.getFazendas()
                            this.props.reloadFazendas({status: 1})
                        }
                    })
                }).current.open()
            },
        },
    ]

    render(){
        return(
            <ObjectTable 
                    rowId='id'
                    tableName='' 
                    headRows={this.state.headRows} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={10}
                    rowsOptions={[5, 10, 20]}
                    dense={true}
                
                    ref={this.objectTableRef}
                    rows={this.state.rows || []}
                /> 
            )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ reloadFazendas}, dispatch)
}

export default connect(null, mapDispatchToProps)(TableFazenda)