import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    FormControl, TextField, NativeSelect,
    Grid, InputLabel, Button, Typography
}
    from '@material-ui/core'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeActionFormDialog2 } from '../default/dialogs/DialogRefInterface'
import DrawMap from '../_mapas/DrawMap'
import { appColors } from '../../constants/app_constants'
import { toast } from 'react-toastify'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import { AvTimer } from '@material-ui/icons'
import moment from 'moment'
import { calculateAreaHa, reverseGeocodingPolygon } from '../../util/geo_util'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { fazendaService } from '../../services/fazendaService'
import { coresParcelas } from '../../constants/model_constants'
import { equipamentoService } from '../../services/equipamentoService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import i18n from '../../../i18n'

class FormEquipamento extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            id_fazenda: 0,
            nome: "",
            cor: "blue",
            data_instalacao: moment(),
            area: "",
            localizacao_centro: { type: "Point", coordinates: [0, 0] },
            uniformidade:"",
            delimitacao: undefined,
            status: 1,
            raio: undefined,
            fazenda: {},
            equipamentos: {},
            fazendas: [],
            mapEquip:true
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
       
        if (data.id_fazenda) {
           
            this.getFazendas({ id: data.id_fazenda })
        }
        this.setState({ ...data })
    }


    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    isDisable = () => {
        try {
            return !this.state.fazenda.id
        } catch (error) {
            return true
        }
    }

    handleSubmit = () => {
        this.state.fazendas = undefined
        const data = filterAndDeleteValues(this.state, {
            id_fazenda: [0], nome: [''], solo: [''], area: [0], fazendas: [undefined], equipamentos: [undefined]
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleInput = async (e) => {
        if (!this.state.fazenda.id) {
            this.getFazendas({ nome: `%${e.target.value}%` })
        }
    }

    handleClose = () => {
        this.setState({ ...this.state })
    }

    handleOption = (option) => {
        try {
            this.state.id_fazenda = option.id
            this.state.fazenda = option
        } catch (error) { }
    }

    getDelimitacaoDescricao() {
        if (this.state.delimitacao) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formequipamento.delimitacaoselecionada', `Delimitação Selecionada - Área (ha)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formequipamento.selecionedelimitacao', `Selecione a delimitação do Equipamento - Área (ha)`)}
            </Typography>
        }
    }

    getRaio() {
      
        if (this.state.raio) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formequipamento.raio', `Raio Selecionado - (m)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formequipamento.selecioneoraio', `Selecione o Raio`)}
            </Typography>
        }
    }

   
    getEquipamentos = () => {
        try {
            const fields = ['id', 'nome', 'area', 'delimitacao', 'cor']
            equipamentoService.get({ id_fazenda: this.state.id_fazenda, status: 1, fields }, response => {
                if (validateShowResponseToast(response, '', false, false)) {
                    this.setState({
                        equipamentos: response.data.filter(dt => dt.id !== this.state.id)
                    })
                }
            })
        } catch (error) {
        }
    }

    getLocalizacao = () => {
        try {
            return this.state.fazenda.localizacao.coordinates
        } catch (error) {
            return undefined
        }
    }

    getFazendas = (query) => {
        query.status = 1
        query.fields = ['id', 'nome', 'localizacao']
        fazendaService.get(query, response => {
            if (response.status === 200) {
                this.setState({
                    fazendas: response.data,
                    fazenda: response.data[0]
                }, () => {
                    this.getEquipamentos()
                })
            }
        })
    }

    handleOpenDelimitacao = (e) => {

        makeActionFormDialog2(DrawMap,
            i18n.t('formequipamento.selecioneequipamento', `Selecione o Equipamento`), false,
            {
                polygon: this.state.delimitacao,
                center: this.getLocalizacao(),
                fazenda: this.state.fazenda,
                equipamento: this.state.equipamentos,
                localizacao_centro:this.state.localizacao_centro,
                raio : this.state.raio 

            }, "", data => {

                if (data.polygon ) {

                    const area = data.area //calculateAreaHa(data.polygon.coordinates[0])
                    const coordinates = Array.from(data.polygon.coordinates[0])
                    const polygon = { type: 'Polygon', coordinates: [reverseGeocodingPolygon(coordinates)] }

                    this.setState({
                        delimitacao: polygon,
                        area:area,
                        raio: data.raio,
                        localizacao_centro: data.localizacao_centro,
                        

                    })
                } else {
                    this.setState({
                        delimitacao: undefined,
                        area: 0,
                        raio:0,
                        localizacao_centro: undefined,
                       

                    })
                }
                toast.success(i18n.t('formequipamento.equipamentosalvo', 'Delimitação do equipamento salvo com sucesso'))
            }).current.open()
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <Grid container item className={classes.container} spacing={2}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="ac_id_fazenda"
                            fullWidth
                            options={this.state.fazendas}
                            onInputChange={this.handleInput}
                            onClose={this.handleClose}
                            value={this.state.fazenda}
                            noOptionsText={i18n.t('formequipamento.digitenome', 'Digite o nome da fazenda para obter opções')}
                            getOptionLabel={option => {
                                this.handleOption(option)
                                return option.nome
                            }}

                            renderInput={params => <TextField {...params} label="Fazenda" />}
                        />
                    </FormControl>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_area"
                                label={this.getDelimitacaoDescricao()}
                                name="area"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.area}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_raio"
                                label={this.getRaio()}
                                name="raio"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.raio}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    
                    <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="latitude"
                                label={ i18n.t('dispositivo.latitude', 'Latitude') }
                                name="lat"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao_centro.coordinates[1]}
                                disabled={true}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="longitude"
                                label={ i18n.t('dispositivo.longitude', 'Longitude') }
                                name="lng"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao_centro.coordinates[0]}
                                disabled={true}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>


                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            className={classes.buttom}
                            disabled={this.isDisable()}
                            onClick={this.handleOpenDelimitacao}
                        > <AvTimer />{i18n.t('formfazenda.selecione', 'Selecione')}</Button>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_nome"
                                label={i18n.t('equipamento.nome', "Descrição do Equipamento")}
                                name="nome"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.nome}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_uniformidade"
                                label={i18n.t('equipamento.uniformidade', "Uniformidade")}
                                name="uniformidade"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.uniformidade}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>

                </Grid>


                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={i18n.t('equipamento.datainstalacao', "Data de Instalção Equipamento")}
                                disabled={this.isDisable()}
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_instalacao"
                                value={this.state.data_instalacao}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid container item className={classes.container} spacing={2}>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >Status</InputLabel>
                            <NativeSelect
                                id='ns_status'
                                fullWidth
                                value={this.state.status}
                                disabled={this.isDisable()}
                                name='status'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{i18n.t('status.ativo', 'Ativo')}</option>
                                <option value={0}>{i18n.t('status.inativo', 'Inativo')}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>

            </ValidatorForm >
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    },
    buttom: {
        backgroundColor: appColors.ICONS_CONFIRM,
        color: 'white'
    }
})

export default withStyles(styles)(FormEquipamento)