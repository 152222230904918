import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, Grid, InputLabel } from '@material-ui/core'
import { toast } from 'react-toastify'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { userNiveisPermissoes } from '../../constants/model_constants'
import i18n from '../../../i18n'

class FormUsuario extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            nome: "",
            email: "",
            senha: "",
            usuario: "",
            confirm_senha: "",
            status: 1,
            nivel_permissao: 1,
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = (e) => {
        const data = filterAndDeleteValues(this.state, { senha: [""] })
        if (this.props.handleFormState && this.state.confirm_senha === this.state.senha) {
            this.props.handleFormState(data)
        } else {
            toast.warn(i18n.t('formuser.passnomatch', "Senhas não conferem!"))
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onFocus = event => {
        if(event.target.autocomplete)
        {
          event.target.autocomplete = "whatever";
        }
     }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm className={
                classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit()
                }}
            >
                <FormControl fullWidth item  className={classes.container}>
                    <TextValidator
                        id="tf_email"
                        label={ i18n.t('user.email', "Email") }
                        name="email"
                        fullWidth
                        autoFill='off'
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        validators={['isEmail', 'required']}
                        errorMessages={[
                            i18n.t('validator.isemail', 'Não é um email válido'), 
                            i18n.t('validator.required', 'Este campo é obrigatório')
                        ]}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item  className={classes.container}>
                    <TextValidator
                        id="tf_usuario"
                        label={ i18n.t('user.usuario', "Nome de Usuário") }
                        name="usuario"
                        autoFill='off'
                        fullWidth
                        type="text"
                        value={this.state.usuario}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                    >
                    </TextValidator>
                </FormControl>
                <Grid container item  className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('user.nivel_permissao', "Nível de Permissão") }</InputLabel>
                            <NativeSelect
                                id='ns_nivel_permissao'
                                fullWidth
                                value={this.state.nivel_permissao}
                                name='nivel_permissao'
                                onChange={this.handleChange}
                            >
                            {userNiveisPermissoes.map(np => {
                                return <option value={np.id}>{np.desc}</option>
                            })}
                               
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('user.status', "Status") }</InputLabel>
                            <NativeSelect
                                id='ns_status'
                                fullWidth
                                value={this.state.status}
                                name='status'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{i18n.t('status.ativo', 'Ativo')}</option>
                                <option value={0}>{i18n.t('status.inativo', 'Inativo')}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.container} spacing={2}>
                    <Grid item xs={6} className={classes.container}>
                        <FormControl fullWidth>
                            <TextField
                                id="tf_senha"
                                label={ i18n.t('user.senha', "Senha") }
                                name="senha"
                                fullWidth
                                type="password"
                                value={this.state.senha}
                                onChange={this.handleChange}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} className={classes.container}>
                        <FormControl fullWidth>
                            <TextField
                                id="tf_confirm_senha"
                                label={ i18n.t('user.confirm_senha', "Confirmar Senha") }
                                name="confirm_senha"
                                fullWidth
                                type="password"
                                value={this.state.confirm_senha}
                                onChange={this.handleChange}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormUsuario)