import React from 'react'
import { Grid } from '@material-ui/core'
import i18n from '../../../i18n'

function renderAttr(attr, desc){
    try{
        if(attr){
            return <Grid xs={12}>{`* ${desc}`}</Grid>
        }
    }catch(error){}
}

export default function (props) {
    const { data } = props
    return (
        <Grid container spacing={1}>
            {renderAttr(data.proprietario, i18n.t('showfuncoes.proprietario', 'Proprietário'))}
            {renderAttr(data.consultor, i18n.t('showfuncoes.consultor', 'Consultor'))}
            {renderAttr(data.responsavel_financeiro, i18n.t('showfuncoes.responsavel_financeiro', 'Responsável Financeiro'))}
            {renderAttr(data.responsavel, i18n.t('showfuncoes.responsavel', 'Responsável'))}
            {renderAttr(data.chefe_regional, i18n.t('showfuncoes.chefe_regional', 'Chefe Regional'))}
        </Grid>
    )
}