import { formatDateTime } from "../../util/utils";

export const CHART_ID = "apexChart"

const DEFAULT_STROKE = 3;

const DEFAULT_TYPE = "line";

function Serie(name, type, data, id) {
  return { name, type, data, id };
}

function AxisX(categories) {
  return { 
    categories,
    type: "datetime",
    
  };
}

function AxisY(color, title, seriesName = "", opposite = false) {
  return {
    seriesName,
    opposite,
    decimalsInFloat: 2,
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: true,
      color,
    },
    labels: {
      style: {
        colors: color,
      },
    },
    title: {
      text: title,
      style: {
        color: color,
      },
    },
    tooltip: {
      enabled: true,
    },
  };
}

function Options(title, stroke, xaxis, yaxis, colors) {
  return {
    colors,
    stroke,
    xaxis,
    yaxis,
    chart: {
      id: CHART_ID,
      height: 300,
      type: "line",
      stacked: false,
      events: {
       
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: title,
      align: "left",
      offsetX: 110,
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
}

/**
 * @param {Array} rows - Array de modelo de dados array<chave, valor> 
 * @param {String} attrCategory - String representando o atributo da coluna categoria
 * @param {Array} attributes - Atributos [{id: '', label: '', valuesLabel: '', type: 'line', stroke: 4, opposite: false}]
 * @returns {Array} - {series: [], categories: []}
 */
function buildChartSeries(rows, attrCategory, attributes = []) {
  const series = [];
  const categories = [];
  const yaxis = []
  const colors = Array.from([]) 
  const stroke = {width: []}
  for (let i = 0; i < attributes.length; i++) {
    const serie = Serie(attributes[i].label, attributes[i].type || DEFAULT_TYPE, 
        [], attributes[i].id, attributes[i].opposite)
    
    const axiY = AxisY(attributes[i].color, attributes[i].valuesLabel, attributes[i].label)
    stroke.width.push(attributes[i].stroke || DEFAULT_STROKE)
    series.push(serie)
    colors.push(attributes[i].color)
    yaxis.push(axiY)
  }
  rows.forEach(row => {
    categories.push(row[attrCategory]);
    series.forEach(srs => {
      const value = row[srs.id]
      srs.data.push(value);
    });
  });
  return { series, categories, yaxis, stroke, colors};
}

export function buildChart(title, rows, attrCategory, attributes){
    const buildSeries = buildChartSeries(rows, attrCategory, attributes)
    const stroke = buildSeries.stroke
    const series = buildSeries.series
    const xaxis = AxisX(buildSeries.categories)
    const yaxis = buildSeries.yaxis
    const colors = buildSeries.colors
    return {
        options: Options(title, stroke, xaxis, yaxis, colors),
        series: series
    }
}