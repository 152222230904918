import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    FormControl, TextField, NativeSelect,
    Grid, InputLabel, Button, Typography
}
    from '@material-ui/core'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeActionFormDialog2 } from '../default/dialogs/DialogRefInterface'
import DrawMap from '../_mapas/DrawMap'
import { appColors } from '../../constants/app_constants'
import { toast } from 'react-toastify'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import { LocalPizza } from '@material-ui/icons'
import moment from 'moment'
import { calculateAreaHa, reverseGeocodingPolygon } from '../../util/geo_util'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { fazendaService } from '../../services/fazendaService'
import { equipamentoService } from '../../services/equipamentoService'
import { coresParcelas } from '../../constants/model_constants'
import { parcelaService } from '../../services/parcelaService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import i18n from '../../../i18n'

class FormParcela extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            fazenda_id: 0,
            nome: "",
            cor: "blue",
            cultura: "",
            solo: "",
            data_plantio: moment(),
            data_inicio_manejo: moment(),
            data_fim: moment(),
            area: "",
            umidade_inicio_solo: "",
            excesso_maior: "",
            manter_cc: "",
            espacamento_linha: "",
            espacamento_plantas: "",
            equipamento_descricao: "",
            delimitacao: undefined,
            status: 1,
            raio: undefined,
            grau_inicial: undefined,
            grau_fim: undefined,
            id_equipamento: 0,
            equipamento: {},
            equipamentos: [],
            fazenda: {},
            parcelas: {},
            fazendas: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        if (data.fazenda_id) {
            this.getFazendas({ id: data.fazenda_id })
            // this.getEquipamentos({ id_fazenda: data.fazenda_id })
        }
        this.setState({ ...data })
    }


    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    isDisable = () => {
        try {
            return !this.state.fazenda.id
        } catch (error) {
            return true
        }
    }

    handleSubmit = () => {
        this.state.fazendas = undefined
        const data = filterAndDeleteValues(this.state, {
            fazenda_id: [0], nome: [''], solo: [''], area: [0], fazendas: [undefined], parcelas: [undefined] ,id_equipamento:[0]
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleInput = async (e) => {
        if (!this.state.fazenda.id) {
            this.getFazendas({ nome: `%${e.target.value}%` })
        }
    }

    handleInputEquip = async (e) => {
      
        if (!this.state.equipamento.id) {
          
            this.getEquip({ nome: `%${e.target.value}%` })
        }
    }

    handleClose = () => {
        this.setState({ ...this.state })
    }

    handleOption = (option) => {
        try {
            this.state.fazenda_id = option.id
            this.state.fazenda = option
        } catch (error) { }
    }

    handleOptionEquip = (option) => {

        try {

            this.state.id_equipamento = option.id
            this.state.equipamento = option
        } catch (error) { }
    }

    getDelimitacaoDescricao() {
        if (this.state.delimitacao) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formparcela.delimitacaoselecionada', `Delimitação Selecionada - Área (ha)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formparcela.selecionedelimitacao', `Selecione a delimitação da Parcela - Área (ha)`)}
            </Typography>
        }
    }

    getRaio() {
      
        if (this.state.raio) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formparcela.raio', `Raio Selecionado - (m)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formparcela.selecioneoraio', `Selecione o Raio`)}
            </Typography>
        }
    }

    getGrauInicial() {
        if (this.state.grau_inicial) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formparcela.grauinicial', `Grau Inicial Selecionado - (º)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formparcela.selecioneograuinicial', `Selecione o Grau Inicial`)}
            </Typography>
        }
    }
    getGrauFinal() {
        if (this.state.grau_fim) {
            return <Typography style={{ color: appColors.ICONS_CONFIRM }}>
                {i18n.t('formparcela.grauinicial', `Grau Final Selecionado - (º)`)}
            </Typography>
        } else {
            return <Typography style={{ color: appColors.ICONS_DANGER }}>
                {i18n.t('formparcela.selecioneograuinicial', `Selecione o Grau Final`)}
            </Typography>
        }
    }

    getParcelas = () => {
        try {
            const fields = ['id', 'nome', 'area', 'delimitacao', 'cor']
            parcelaService.get({ fazenda_id: this.state.fazenda_id, status: 1, fields }, response => {
                if (validateShowResponseToast(response, '', false, false)) {
                    this.setState({
                        parcelas: response.data.filter(dt => dt.id !== this.state.id)
                    })
                }
            })
        } catch (error) {
        }
    }

    getLocalizacao = () => {
        try {
            return this.state.fazenda.localizacao.coordinates
        } catch (error) {
            return undefined
        }
    }

    getFazendas = (query) => {
        query.status = 1
        query.fields = ['id', 'nome', 'localizacao']
        fazendaService.get(query, response => {
            if (response.status === 200) {
                this.setState({
                    fazendas: response.data,
                    fazenda: response.data[0]
                }, () => {
                    this.getEquipamentos()
                })
            }
        })
    }

    getEquip = (query) => {
       
        query.fields = ['id', 'nome', 'localizacao']
        equipamentoService.get(query, response => {
            if (response.status === 200) {
                this.setState({
                    equipamentos: response.data,
                    equipamento: response.data[0]
                }, () => {
                    this.getEquipamentos()
                })
            }
        })
    }


    getEquipamentos = (query) => {
        try {

            const fields = ['id', 'nome']
            equipamentoService.get({ fazenda_id: this.state.fazenda_id, status: 1, fields }, response => {
                if (validateShowResponseToast(response, '', false, false)) {
                    this.setState({
                        equipamentos: response.data,
                        equipamento: response.data[0]
                    })
                }
            })
        } catch (error) {
        }
    }

    handleOpenDelimitacao = (e) => {

        makeActionFormDialog2(DrawMap,
            i18n.t('formparcela.selecioneparcela', `Selecione a Parcela`), false,
            {
                polygon: this.state.delimitacao,
                center: this.getLocalizacao(),
                fazenda: this.state.fazenda,
                parcelas: this.state.parcelas,
          
                localizacao_centro:this.state.equipamento.localizacao_centro,
                raio : this.state.equipamento.raio ,
                grau_inicial:this.state.grau_inicial,
                grau_fim: this.state.grau_fim

            }, "", data => {

              
                if (data.polygon) {

                    const area = calculateAreaHa(data.polygon.coordinates[0])
                    const coordinates = Array.from(data.polygon.coordinates[0])
                    const polygon = { type: 'Polygon', coordinates: [reverseGeocodingPolygon(coordinates)] }
                    

                    this.setState({
                        delimitacao: polygon,
                        area,
                        raio: data.raio,
                        grau_inicial: data.grau_inicial,
                        grau_fim: data.grau_fim
                    })
                } else {
                    this.setState({
                        delimitacao: undefined,
                        area: 0,

                    })
                }
                toast.success(i18n.t('formparcela.parcelasalva', 'Delimitação da parcela salva com sucesso'))
            }).current.open()
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>

                <Grid container item className={classes.container} spacing={2}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="ac_fazenda_id"
                            fullWidth
                            options={this.state.fazendas}
                            onInputChange={this.handleInput}
                            onClose={this.handleClose}
                            value={this.state.fazenda}
                            noOptionsText={i18n.t('formparcela.digitenome', 'Digite o nome da fazenda para obter opções')}
                            getOptionLabel={option => {
                                this.handleOption(option)
                                return option.nome
                            }}

                            renderInput={params => <TextField {...params} label="Fazenda" />}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Autocomplete
                            id="equipamento_id"
                            fullWidth
                            options={this.state.equipamentos}
                            onInputChange={this.handleInputEquip}
                            onClose={this.handleClose}
                            value={this.state.equipamento}
                            noOptionsText={i18n.t('formparcela.digitenome', 'Digite o nome da fazenda para obter opções')}
                            getOptionLabel={option => {
                                this.handleOptionEquip(option)
                                return option.nome
                            }}

                            renderInput={params => <TextField {...params} label="Equipamento" />}
                        />
                    </FormControl>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_area"
                                label={this.getDelimitacaoDescricao()}
                                name="area"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.area}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={4}> 
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_raio"
                                label={this.getRaio()}
                                name="raio"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.raio}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={5}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_grau_inicial"
                                label={this.getGrauInicial()}
                                name="grau_inicial"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.grau_inicial}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_grau_fim"
                                label={this.getGrauFinal()}
                                name="grau_fim"
                                fullWidth
                                type="number"
                                disabled={true}
                                value={this.state.grau_fim}
                                onChange={this.handleChange}
                                validators={['minFloat:0.01']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            className={classes.buttom}
                            disabled={this.isDisable()}
                            onClick={this.handleOpenDelimitacao}
                        > <LocalPizza />{i18n.t('formfazenda.selecione', 'Selecione')}</Button>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_nome"
                                label={i18n.t('parcela.nome', "Descrição da Parcela")}
                                name="nome"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.nome}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_equipamento"
                                label={i18n.t('parcela.equipamento_descricao', "Equipamento de Irrigação")}
                                name="equipamento_descricao"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.equipamento_descricao}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth item className={classes.container}>
                            <TextValidator
                                id="tf_solo"
                                label={i18n.t('parcela.solo', "Tipo de Solo")}
                                name="solo"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.solo}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth item className={classes.container}>
                            <TextValidator
                                id="tf_cultura"
                                label={i18n.t('parcela.cultura', "Cultura")}
                                name="cultura"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.cultura}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_umidade_inicio_solo"
                                label={i18n.t('parcela.umidade_inicio_solo', "Umidade Inicial do Solo")}
                                name="umidade_inicio_solo"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.umidade_inicio_solo}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_excesso_maior"
                                label={i18n.t('parcela.excesso_maior', "Excesso Maior")}
                                name="excesso_maior"
                                disabled={this.isDisable()}
                                fullWidth
                                type="text"
                                value={this.state.excesso_maior}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_espacamento_plantas"
                                label={i18n.t('parcela.espacamento_plantas', "Espaçamento entre Plantas (cm)")}
                                name="espacamento_plantas"
                                disabled={this.isDisable()}
                                fullWidth
                                type="number"
                                value={this.state.espacamento_plantas}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextValidator
                                id="tf_espacamento_linha"
                                label={i18n.t('parcela.espacamento_linha', "Espaçamento entre Linhas (cm)")}
                                name="espacamento_linha"
                                disabled={this.isDisable()}
                                fullWidth
                                type="number"
                                value={this.state.espacamento_linha}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={i18n.t('parcela.data_inicio_manejo', "Data de Início do Manejo")}
                                disabled={this.isDisable()}
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_inicio_manejo"
                                value={this.state.data_inicio_manejo}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disabled={this.isDisable()}
                                disableToolbar
                                label={i18n.t('parcela.data_plantio', "Data do Plantio")}
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_plantio"
                                value={this.state.data_plantio}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disabled={this.isDisable()}
                                disableToolbar
                                label={i18n.t('parcela.data_colheita', "Data de Colheita")}
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_fim"
                                value={this.state.data_fim}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{i18n.t('parcela.cor', 'Cor da Delimitação')}</InputLabel>
                            <NativeSelect
                                id='ns_cor'
                                fullWidth
                                value={this.state.cor}
                                disabled={this.isDisable()}
                                name='cor'
                                onChange={this.handleChange}
                            >
                                {coresParcelas.map(cp => {
                                    return <option value={cp.id}>{cp.desc}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >Status</InputLabel>
                            <NativeSelect
                                id='ns_status'
                                fullWidth
                                value={this.state.status}
                                disabled={this.isDisable()}
                                name='status'
                                onChange={this.handleChange}
                            >
                                <option value={1}>{i18n.t('status.ativo', 'Ativo')}</option>
                                <option value={0}>{i18n.t('status.inativo', 'Inativo')}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>

            </ValidatorForm >
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    },
    buttom: {
        backgroundColor: appColors.ICONS_CONFIRM,
        color: 'white'
    }
})

export default withStyles(styles)(FormParcela)