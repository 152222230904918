import { toast } from 'react-toastify'
import browserHistory from './browser_history'

function makeErrorsMessage(response){
    let errors = response.data.errors || []
    if(errors.length > 0){
        errors.map(error => {
            toast.warn(error)
        })      
    }else{
        if(response.data.message){
            toast.warn(response.data.message)
        }else{
            toast.warn("Falha ao realizar operação, verifique os dados e tente novamente")
        }
    }
}

export function validateShowResponseToast(response, model=undefined, isShowSuccess=true, isShowFail=true,  successMsg=undefined){
    if(response){
        if(response.status === 200){
            if(isShowSuccess) toast.success(successMsg || "Operação realizada com sucesso!")
            return true
        }else if(response.status === 201){
            if(isShowSuccess) toast.success(`${model || ''} criado com sucesso!`)
            return true
        }else if(response.status === 400){
            if(isShowFail) makeErrorsMessage(response)
            return false
        }else if(response.status === 401){
            if(isShowFail) toast.warn("Você não tem autorização para realizar esse procedimento!")
            browserHistory.push('/')
            return false
        }else if(response.status === 403){
            if(isShowFail) toast.warn("Você não tem permissão para realizar essa tarefa ou acessar esses dados!")
            return false
        }else{
            if(isShowFail) toast.error("Erro interno! Se persistir contate o suporte técnico")
            return false
        }
    }else{
        if(isShowFail) toast.error("Falha ao acessar o Servidor! Verifique a conexão com a internet!")
        return false;
    }
}