import moment from "moment"
import { formatDateTime } from "./utils"

function buildLabel(currentTime, intervalName){
    const momentCurrentTime = moment.utc(currentTime)
    if(intervalName === 'hour'){
        return `${momentCurrentTime.format('DD/MM/YYYY HH:00')}`
    }else if(intervalName === 'day'){
        return `${momentCurrentTime.format('DD/MM/YYYY')}`
    }else if(intervalName === 'week'){
        return `${momentCurrentTime.format('W - YYYY')}`
    }else if(intervalName === 'month'){
        return `${momentCurrentTime.format('MMM/YYYY')}`
    }else{
        return momentCurrentTime.format('DD/MM/YYY HH:mm:ss')
    }
}

/**
 *
 * @param {Array<Object>} chartData]
 * @param {String} intervalName - hour, day, week, month
 * @param {Array<String>} attrMediaLabels -
 */
export function buildMediaByTime(chartData, intervalName, attrMediaLabels=[], attrSomatoria=[]){
    if(intervalName === 'real'){
        return chartData.map(cData => {
            const newData = {...cData}
            newData.data_hora = formatDateTime(cData.data_hora)
            return newData
        })
    }
    const groupChartData = {}
    chartData.forEach(cData => {
        const label = buildLabel(cData.data_hora, intervalName)
        if(groupChartData[label]){
            groupChartData[label].push(cData)
        }else{
            groupChartData[label] = []
        }
    })
    const resultRet = []
    Object.keys(groupChartData).forEach(gcData => {
        const result = { data_hora: gcData }
        attrMediaLabels.forEach(label => {
                let sum = 0
                groupChartData[gcData].forEach(gcdItem => {
                    sum += gcdItem[label]
                })
                if(attrSomatoria.indexOf(label) === -1) { // Conserva somatoria ao invés da média
                    if(groupChartData[gcData].length > 0){
                        result[label] = sum / groupChartData[gcData].length
                    }else{
                        result[label] = null
                    }
                }else{
                    result[label] = sum
                }
        })
        resultRet.push(result)
    })
    return resultRet
}
