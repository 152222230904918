
/**
 * Deleta o atributo caso não seja igual aos filtros 
 * filterAndDeleteValues(this.state, { senha: [""] }) 
*/
export function filterAndDeleteValues(objectFilter, values={}){
    const keys = Object.keys(objectFilter).filter(key => {
        if(values[key]){
            if(values[key].indexOf(undefined) !== -1) return false 
            return values[key].indexOf(objectFilter[key]) === -1
        }else{  
            return true
        }
    })
    let retList = {}
    keys.map(key => {
        retList[key] = objectFilter[key]
    })
    return retList
}

// filter = {key: "nome", type: 'like', exclude: ['a']}
export function filterQueryValues(stateValues, filter=[]){
    const retList = {}
    Object.keys(stateValues).map(key => {
        if(filter.indexOf(key) === -1){
            if(stateValues[key] !== "" && stateValues[key] !== undefined){
                retList[key] = stateValues[key]
            }
        }
    })
    return retList

}

export function makeLikeQuerys(values, likeValues=[]){
    let retValues = {...values}
    likeValues.map(lv => {
        if(retValues[lv])
            retValues[lv] = `%${retValues[lv]}%`
    })
    return retValues
}

