import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, NativeSelect, InputLabel, Grid } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { clearAllMask } from '../../util/utils'
import moment from 'moment'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import i18n from '../../../i18n'

class FormContrato extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            data_inicio: moment(),
            data_final: moment(),
            visitas_contrato: 0,
            visitas_realizadas: 0,
            status: 1
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = () => {
        this.state.celular = clearAllMask(this.state.celular)
        this.state.telefone = clearAllMask(this.state.telefone)
        const data = filterAndDeleteValues(this.state, {
            email: [''], celular: [''], telefone: ['']
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={ i18n.t('contrato.data_inicial', 'Data Inicial') }
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_inicio"
                                value={this.state.data_inicio}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={ i18n.t('contrato.data_final', 'Data Final') }
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_final"
                                value={this.state.data_final}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_visitas_contrato"
                        label={ i18n.t('contrato.visitas_contrato', 'Número de Visitas') }
                        name="visitas_contrato"
                        fullWidth
                        autoFill='off'
                        type="number"
                        value={this.state.visitas_contrato}
                        onChange={this.handleChange}
                        validators={[]}
                        errorMessages={[]}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_visitas_realizadas"
                        label={ i18n.t('contrato.visitas_realizadas', 'Vísitas Realizadas') }
                        name="visitas_realizadas"
                        fullWidth
                        autoFill='off'
                        type="number"
                        value={this.state.visitas_realizadas}
                        onChange={this.handleChange}
                        validators={[]}
                        errorMessages={[]}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >Status</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={1}>{i18n.t('status.ativo', 'Ativo') }</option>
                        <option value={0}>{i18n.t('status.inativo', 'Inativo') }</option>
                    </NativeSelect>
                </FormControl>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 300,
        maxWidth: 350,
        minWidth: 350
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormContrato)