import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { headRowsEquipamento } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit, FilterList , LocalPizza} from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import {  makeActionFormDialog , makeObjectListDialog2} from '../default/dialogs/DialogRefInterface'
import { equipamentoService } from '../../services/equipamentoService'
import FormEquipamento from './FormEquipamento'
import FilterEquipamento from './FilterEquipamento'
import { reloadEquipamentos } from '../../reducers/dashboard/actions'
import i18n from '../../../i18n'
import TableParcela from '../_parcela/TableParcela'

class TableEquipamento extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsEquipamento(),
            fazenda: {},
            lastFilter: {}
         }
         this.objectTableRef = React.createRef()
    }

    getEquipamentos(){
        if(this.state.fazenda.id){
            this.state.lastFilter.fazenda_id = this.state.fazenda.id
        }
        equipamentoService.get(this.state.lastFilter, response => {
            if(validateShowResponseToast(response, "", false)){
                this.setState({
                    rows: response.data
                })
            }
        })
    }

    getFazendaParams = () => {
        const fazendaParams = {}
        if(this.state.fazenda.id){
            fazendaParams.fazenda_id = this.state.fazenda.id
            fazendaParams.fazenda =  this.state.fazenda
        }
        return fazendaParams
    }

    onStart = () => {
        if(this.props.data){
            this.state.fazenda = this.props.data
            this.state.lastFilter.fazenda_id = this.props.data.id
            this.state.lastFilter.status = 1 // ATIVO
        }
        this.getEquipamentos()
    }
  
    componentDidMount(){
       this.onStart()
    }

    selectedToolActions = [] 

    toolActions = [
        {
            title: i18n.t('tableactions.filter', 'Filtrar'),
            color: appColors.PRIMARY,
            MaterialIcon: FilterList,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterEquipamento, i18n.t('tableequipamento.filterEqFilterEquipamentos', 'Filtrar Equipamentos'), 
                false, this.getFazendaParams(), i18n.t('tableactions.filter', 'Filtrar'), data => {
                    equipamentoService.get(data, response => {
                        if(validateShowResponseToast(response, "", false)){
                            this.setState({
                                rows: response.data
                            })
                        }
                    })
                }).current.open()
            },
        },
        {
            title: i18n.t('tableactions.add', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormEquipamento, i18n.t('tableequipamento.novaparcela', 'Novo Equipamento'), false, 
                    this.getFazendaParams(), i18n.t('tableactions.save', 'Salvar'), data => {
                    equipamentoService.create(data, response => {
                        if(validateShowResponseToast(response, i18n.t('tableequipamento.equipamento', 'Equipamento'),)){
                            this.getEquipamentos()
                            this.props.reloadEquipamentos()
                        }
                    })
                }).current.open()
            },
        },
    ]
    actions = [
        {
            title: i18n.t('tablefazenda.parcelas', 'Parcelas'),
            color: appColors.PRIMARY,
            MaterialIcon: LocalPizza,
            handleClick: (e, selected) => {
                e.stopPropagation()  
                makeObjectListDialog2(TableParcela, i18n.t('tablefazenda.parcelas', 'Parcelas'), {}, selected, null, null).current.open()
            },
        },
        {
            title: i18n.t('tableactions.edit', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormEquipamento, i18n.t('tableequipamento.editarequipamento', 'Editar Equipamento'), 
                false, selected, i18n.t('tableactions.save', 'Salvar'), data => {
                    equipamentoService.update(data, response => {
                        if(validateShowResponseToast(response, i18n.t('tableequipamento.equipamento', 'Equipamento'))){
                            this.getEquipamentos()
                            this.props.reloadEquipamentos()
                        }
                    })
            }).current.open()
            },
        },
    ]

    render(){
        return(
            <ObjectTable 
                    rowId='id'
                    tableName='' 
                    headRows={this.state.headRows} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={10}
                    rowsOptions={[10, 20, 40]}
                    dense={true}
                    ref={this.objectTableRef}
                    rows={this.state.rows || []}
                /> 
            )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ reloadEquipamentos}, dispatch)
}

export default connect(null, mapDispatchToProps)(TableEquipamento)