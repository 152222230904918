import React from 'react'
import { headRowsPessoa } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit, PermIdentity, PermPhoneMsg, LocationOn, Home, FilterList } from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { makeActionFormDialog, makeObjectListDialog2 } from '../default/dialogs/DialogRefInterface'
import { pessoaService } from '../../services/pessoaService'
import FormPessoa from './FormPessoa'
import FormUser from '../_user/ FormUser'
import { userService } from '../../services/user_service'
import FilterPessoa from './FilterPessoa'
import FormContato from '../_contato/FormContato'
import { contatoService } from '../../services/contatoService'
import FormEndereco from '../_endereco/FormEndereco'
import { enderecoService } from '../../services/enderecoService'
import TablePessoaFazenda from '../_pessoa_fazenda/TablePessoaFazenda'
import i18n from '../../../i18n'

class TablePessoa extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsPessoa(),
            lastFilter: {}
        }
        this.objectTableRef = React.createRef()
    }

    componentDidMount() {
        this.getPessoas()
    }

    getPessoas() {
        pessoaService.get(this.state.lastFilter, response => {
            if (validateShowResponseToast(response, "", false)) {
                this.setState({
                    rows: response.data
                })
            }
        })
    }

    createUsuario = (selected) => {
        makeActionFormDialog(FormUser, i18n.t('tablepessoa.criarusuario', "Criar Usuário"), 
            false, {}, i18n.t('tableactions.save', "Salvar"), data => {
            data.pessoa_id = selected.id
            userService.createUsuario(data, response => {
                if (validateShowResponseToast(response)) {
                    this.getPessoas()
                }
            })
        }).current.open()
    }

    updateUsuario = (selected) => {
        pessoaService.getPessoaUsuario({id: selected.id}, response => {
            const usuario = response.data
            makeActionFormDialog(FormUser, i18n.t('tablepessoa.atualizarusuario', "Atualizar Usuário"), false, usuario, "Salvar", data => {
                userService.updateUsuario(data, response => {
                    if (validateShowResponseToast(response)) {
                        this.getPessoas()
                    }
                })
            }).current.open()
        })
    }

    createContato = (selected) => {
        makeActionFormDialog(FormContato,i18n.t('tablepessoa.criarusuario', "Criar Usuário"), 
        false, {}, i18n.t('tableactions.save', "Salvar"), data => {
            data.pessoa_id = selected.id
            contatoService.create(data, response => {
                if (validateShowResponseToast(response)) {
                    this.getPessoas()
                }
            })
        }).current.open()
    }

    updateContato = (selected) => {
        pessoaService.getPessoaContato({id: selected.id}, response => {
            const contato = response.data
            makeActionFormDialog(FormContato, i18n.t('tablepessoa.atualizarcontato', "Atualizar Contato"), 
            false, contato, i18n.t('tableactions.save', "Salvar"), data => {
                contatoService.update(data, response => {
                    if (validateShowResponseToast(response)) {
                        this.getPessoas()
                    }
                })
            }).current.open()
        })
    }

    createEndereco = (selected) => {
        makeActionFormDialog(FormEndereco , i18n.t('tablepessoa.criarendereco', "Criar Endereço"), 
        false, {}, i18n.t('tableactions.save', "Salvar"), data => {
            data.id = selected.id
            pessoaService.createPessoaEndereco(data, response => {
                if(validateShowResponseToast(response, i18n.t('models.endereço', 'Endereço'))){
                    this.getPessoas()
                }
            })
          
        }).current.open()
    }

    updateEndereco = (selected) => {
        pessoaService.getPessoaEndereco({id: selected.id}, response => {
            const endereco = response.data
            makeActionFormDialog(FormEndereco, i18n.t('tablepessoa.atualizarendereco', "Atualizar Endereço"), 
            false, endereco, i18n.t('tableactions.save', "Salvar"), data => {
                enderecoService.update(data, response => {
                    if (validateShowResponseToast(response)) {
                        this.getPessoas()
                    }
                })
            }).current.open()
        })
    }

    selectedToolActions = []

    toolActions = [
        {
            title: "PRIMARY",
            color: appColors.PRIMARY,
            MaterialIcon: FilterList,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterPessoa, i18n.t('tableactions.filter', "Filtrar"), 
                false, {}, i18n.t('tableactions.filter', "Filtrar"), data => {
                    this.state.lastFilter = data
                    this.getPessoas()
                }).current.open()
            },
        },
        {
            title: "PRIMARY",
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormPessoa, i18n.t('tablepessoa.criarpessoa', "Nova Pessoa"), false, {}, "Salvar", data => {
                    pessoaService.create(data, response => {
                        if (validateShowResponseToast(response, i18n.t('models.pessoa', 'Pessoa'))) {
                            this.getPessoas()
                        }
                    })
                }).current.open()
            },
        },
    ]

    actions = [
        {
            title: i18n.t('models.usuario', 'Usuário'),
            color: appColors.PRIMARY,
            MaterialIcon: PermIdentity,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(selected.usuario_id > 0){
                    this.updateUsuario(selected)
                }else{
                    this.createUsuario(selected)
                }

            },
        },
        {
            title: i18n.t('models.contato', 'Contato'),
            color: appColors.PRIMARY,
            MaterialIcon: PermPhoneMsg,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(selected.contato_id){
                    this.updateContato(selected)
                }else{
                    this.createContato(selected)
                }
            },
        },
        {
            title: i18n.t('models.endereço', 'Endereço'),
            color: appColors.PRIMARY,
            MaterialIcon: LocationOn,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(selected.endereco_id > 0){
                    this.updateEndereco(selected)
                }else{
                    this.createEndereco(selected)
                }
            },
        },
        {
            title: i18n.t('models.fazendas', 'Fazendas'),
            color: appColors.PRIMARY,
            MaterialIcon: Home,
            handleClick: (e, selected) => {
                e.stopPropagation()
                const data = {...selected, pessoa_id: selected.id}
                makeObjectListDialog2(TablePessoaFazenda, 
                    `${ i18n.t('tablefazenda.fazendasvinculadas', 'Fazendas vínculadas a') } ${selected.nome}`, 
                { minWidth: 1000, minHeight: 500 }, data).current.open()
            },
        },
        {
            title: "Editar",
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormPessoa, i18n.t('tablefazenda.editarpessoa', 'Editar Dados Pessoais'), false, selected, "Salvar", data => {
                    pessoaService.update(data, response => {
                        if (validateShowResponseToast(response, i18n.t('models.pessoa', 'Pesssoa'))) {
                            this.getPessoas()
                        }
                    })
                }).current.open()
            },
        },
    ]

    render() {
        return (
            <ObjectTable
                rowId='id'
                tableName=''
                headRows={this.state.headRows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowSize={10}
                rowsOptions={[10, 20, 40]}
                dense={true}
                ref={this.objectTableRef}
                rows={this.state.rows || []}
            />
        )
    }
}

export default TablePessoa