import React from 'react'
import { headRowsPessoaFazenda } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit } from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { pessoaFazendaService } from '../../services/pessoaFazendaService'
import { makeActionFormDialog } from '../default/dialogs/DialogRefInterface'
import FormPessoaFazenda from './FormPessoaFazenda'
import i18n from '../../../i18n'

class TablePessoaFazenda extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsPessoaFazenda(),
            lastFilter: {},
            fazenda: {},
            pessoa: {},
        }
        this.objectTableRef = React.createRef()
    }

    componentDidMount() {
        const lastFilter = {}
        let pessoa = {}, fazenda = {}
        if(this.props.data){
            if(this.props.data.pessoa_id){
                lastFilter.pessoa_id = this.props.data.pessoa_id
                pessoa = this.props.data
            }
            if(this.props.data.fazenda_id){
                lastFilter.fazenda_id = this.props.data.fazenda_id
                fazenda = this.props.data
            }
        }
        this.setState({ 
            lastFilter, pessoa, fazenda
        }, () => {
            this.getPessoaFazendas()
        })
    }

    getPessoaFazendas() {
        pessoaFazendaService.get(this.state.lastFilter, response => {
            if (validateShowResponseToast(response, "", false)) {
                this.setState({
                    rows: response.data
                })
            }
        })
    }

    selectedToolActions = []

    toolActions = [
        {
            title: "PRIMARY",
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                const data = {
                    pessoa_id: this.state.pessoa.id, fazenda_id: this.state.fazenda.id,
                    pessoa: this.state.pessoa, fazenda: this.state.fazenda
                }
                makeActionFormDialog(FormPessoaFazenda, 
                    i18n.t('tablepessoafazenda.novovinculo', "Novo Vínculo Pessoa e Fazenda"), false, data, "Salvar", data => {
                    pessoaFazendaService.create(data, response => {
                        if(validateShowResponseToast(response)){
                            this.getPessoaFazendas()
                        }
                    })
                }).current.open()
            },
        },
    ]

    actions = [
        {
            title: i18n.t('tableactions.edit', "Editar"),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormPessoaFazenda, 
                    i18n.t('tablepessoafazenda.editarvinculo', "Editar Vínculo Pessoa e Fazenda"), false, selected, "Salvar", data => {
                    pessoaFazendaService.update(data, response => {
                        if(validateShowResponseToast(response)){
                            this.getPessoaFazendas()
                        }
                    })
                }).current.open()
            },
        },
    ]

    render() {
        return (
            <ObjectTable
                rowId='id'
                tableName=''
                headRows={this.state.headRows}
                actions={this.actions}
                selectedToolActions={this.selectedToolActions}
                toolActions={this.toolActions}
                rowSize={10}
                rowsOptions={[10, 20, 40]}
                dense={true}
                ref={this.objectTableRef}
                rows={this.state.rows || []}
            />
        )
    }
}

export default TablePessoaFazenda