import React from 'react'
import PropTypes from 'prop-types'
import { appColors } from '../../constants/app_constants'
import {
    Map, LayersControl, TileLayer,
    FeatureGroup, GridLayer, Polygon, Marker, CircleMarker, Circle
} from 'react-leaflet'
import {
    withStyles, Container, Typography,
    Slider, Grid, FormControl, TextField, Button
} from '@material-ui/core'
import { EditControl } from "react-leaflet-draw"
import {
    polygonToGeojson, convertCircleToPolygon, reverseGeocodingPoint,
    calculateAreaHa, reverseGeocodingPolygon, sectorTurf
} from '../../util/geo_util'
import { toast } from 'react-toastify'
import 'leaflet/dist/leaflet.css'
import { houseIcon, SprinklersIcon } from './icons'
import i18n from '../../../i18n'
import {
    AvTimer, Save
} from '@material-ui/icons';

const { BaseLayer, Overlay } = LayersControl

class DrawMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            polygon: { type: 'Polygon', coordinates: [[]] },
            zoom: 4,
            center: [-18, -45],
            area: 0,
            parcelas: [],
            fazenda: {},
            newValue: 0,
            markerData: [],
            localizacao_centro: { type: "Point", coordinates: [0, 0] },



        }
        this.handleChangeLocation = this.handleChangeLocation.bind(this);
        this.updateMarker = this.updateMarker.bind(this);
        this.action = this.action.bind(this);
    }

    componentDidMount() {
        try {
            let coordinates = []
            let center = [-18, -48]
            let parcelas = []
            let fazenda = {}
            let equipamento = {}
            let hideSelectArea = true
            if (this.props.data.center) {
                center = reverseGeocodingPoint(this.props.data.center)
            }
            if (this.props.data.polygon) {
                coordinates = Array.from(this.props.data.polygon.coordinates[0])
                coordinates = reverseGeocodingPolygon(coordinates)
                this.state.polygon.coordinates[0] = coordinates
                center = coordinates[0]
            }
            if (this.props.data.parcelas) {
                parcelas = this.props.data.parcelas
                hideSelectArea = false
            }
            if (this.props.data.fazenda) {
                fazenda = this.props.data.fazenda
            }
            if (this.props.data.equipamento) {
                
                equipamento = this.props.data.equipamento
                hideSelectArea = true
              
                            
            }else{ 

            }

            this.setState({
                center,
                parcelas,
                fazenda,
                equipamento,
                hideSelectArea,
                zoom: 13,
                area: calculateAreaHa(coordinates),
                lat: this.props.data.fazenda.localizacao.coordinates[1],
                lng: this.props.data.fazenda.localizacao.coordinates[0],
                localizacao_centro: this.props.data.localizacao_centro.coordinates[0]==0 ? this.props.data.fazenda.localizacao :this.props.data.localizacao_centro,
                raio :  this.props.data.raio > 0 ? this.props.data.raio : undefined
            })
        } catch (error) {
            console.log(error)
        }
    }


    /** Retorna o poligono selecionado*/
    action= () => {
        let data
        if (!this.state.polygon || this.state.polygon.coordinates.length === 0) {
            data = { polygon: undefined }
            toast.warn(i18n.t('drawmap.msg_nenhumageometria', 'Nenhuma geometria selecionada'))
        } else {
           
            data = {
                polygon: this.state.polygon,
                raio: this.state.raio,
                grau_inicial: this.state.grau_inicial,
                grau_fim: this.state.grau_fim,
                localizacao_centro: this.state.localizacao_centro,
                area: this.state.area
            }

         
        }

        this.props.handleFormState(data)
    }

    getExclude = () => {
        let exclude = { polyline: false, marker: false, circlemarker: false }
        if (this.props.drawExclude) {
            this.props.drawExclude.forEach(exc => exclude[exc] = false)
        }
        return exclude
    }

    onEdited = (e) => {
        try {
            const layers = e.layers
            layers.eachLayer(layer => {
                const values = polygonToGeojson(layer._latlngs[0])
                this.state.polygon.coordinates[0] = values
            });
            this.setState({
                area: calculateAreaHa(this.state.polygon.coordinates[0]),
                raio: this.state.raio
            }, () => {
                this.action()
            })
        } catch (error) {
            console.log(error)
        }
    }

    onCreated = (e) => {
        try {
            const layer = e.layer
            const type = e.layerType
            let polygon = { type: "Polygon", coordinates: [[]] }
            if (type === 'polygon' || type === 'rectangle') {
                polygon.coordinates[0] = polygonToGeojson(layer.getLatLngs()[0])
            } else if (type === 'circle') {
                const coord = [layer._latlng.lat, layer._latlng.lng]
                polygon = convertCircleToPolygon(coord, layer._mRadius, 24)
            }
            this.setState({
                polygon: polygon,
                area: calculateAreaHa(polygon.coordinates[0]),

            }, () => {
                this.action()
            })
        } catch (error) {
            console.log(error)
        }
    }

    onDeleted = (e) => {
        this.setState({
            polygon: { type: 'Polygon', coordinates: [[]] },
            area: 0,

        })
    }

    showFazenda = () => {
        if (this.state.fazenda.id) {
            return <Marker
                icon={houseIcon}
                draggable={false}
                position={reverseGeocodingPoint(this.state.fazenda.localizacao.coordinates)}>
            </Marker>
        }
    }

    showEquipamento = () => {
        if (this.state.fazenda.id) {
            return <Marker
                icon={SprinklersIcon}
                draggable={true}
                onDragend={this.updateMarker}
                position={[this.state.localizacao_centro.coordinates[1], this.state.localizacao_centro.coordinates[0]]}>
            </Marker>
        }
    }


    showDelimitation = () => {
        if (this.state.fazenda.id) {
            return <Circle
                center={[this.state.localizacao_centro.coordinates[1], this.state.localizacao_centro.coordinates[0]]}
                color="green"
                radius={this.state.raio}>
            </Circle>

        }
    }


    showParcelas = () => {
        try {
            return this.state.parcelas.map(pcl => {
                return <Polygon
                    positions={reverseGeocodingPolygon(pcl.delimitacao.coordinates[0])}
                    color='blue'
                ></Polygon>
            })
        } catch (error) {

        }
    }
     

    drawSector= () =>{
        try {
            return (
                <Polygon
                    positions={reverseGeocodingPolygon(this.state.polygon.coordinates[0])}
                    color='blue'
                >
                </Polygon>
            )
        } catch (error) {
            console.log(error)
        }
    }


    // drawPolygon = (coordinates = undefined) => {
    //     try {
    //         return (
    //             <Polygon
    //                 positions={this.state.polygon.coordinates[0]}
    //                 color='green'
    //             >
    //             </Polygon>
    //         )
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

 

    handleChangeRaio = (e) => {
        const { name, value } = e.target;
        this.setState({
            raio: value, 
            area: Math.round(3.1415 * Math.pow(value, 2) * 0.0001, 2) ,
            polygon: convertCircleToPolygon([this.state.localizacao_centro.coordinates[1], this.state.localizacao_centro.coordinates[0]], this.state.raio, 24)
        })
    }

    handleChangeLocation = (e) => {
        const { name, value } = e.target;
        if (name === 'lng') {

            this.state.localizacao_centro.coordinates[0] = value;
            this.setState({ lng: value })
        }
        else if (name === "lat") {
            this.state.localizacao_centro.coordinates[1] = value;
            this.setState({ lat: value })
        }
      

        }


    updateMarker = event => {

        this.state.localizacao_centro.coordinates[0] = event.target.getLatLng().lng
        this.state.localizacao_centro.coordinates[1] = event.target.getLatLng().lat
        this.state.lng = event.target.getLatLng().lng
        this.state.lat = event.target.getLatLng().lat
       
        //  this.setState({ polygon: convertCircleToPolygon([this.state.lat, this.state.lng], this.state.raio, 24) })

    };

    handleChange = (event, value) => {
         this.state.grau_inicial = value[0];
         this.state.grau_fim = value[1]; 
         if(this.state.raio > 0 ) {
         this.setState({ polygon:sectorTurf([  this.state.localizacao_centro.coordinates[0],   this.state.localizacao_centro.coordinates[1]],this.state.raio,value[0],value[1]).geometry })
         }
        };

    render() {
        const { classes } = this.props
        return (
            <Container>
                <Grid container item className={classes.container} spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>{`${i18n.t('drawmap.totalhectares', 'Total em hectares')}: ${this.state.area}`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography >{`${i18n.t('drawmap.centropivo', 'Centro do Pivô')}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>

                        <FormControl fullWidth>
                            <TextField
                                id="latitude"
                                label={i18n.t('dispositivo.latitude', 'Latitude')}
                                name="lat"
                                fullWidth
                                required
                                type="number"
                                value={this.state.lat}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="longitude"
                                label={i18n.t('dispositivo.longitude', 'Longitude')}
                                name="lng"
                                fullWidth
                                required
                                type="number"
                                value={this.state.lng}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[i18n.t('validator.required', 'Este campo é obrigatório')]}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>


                </Grid>

                <Grid container item className={classes.container} spacing={4}>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <TextField
                                id="raio"
                                label={i18n.t('parcela.raiodopivo', "Raío do Pivô")}
                                value={this.state.raio}
                                type="number"
                                onChange={this.handleChangeRaio}
                                validators={['minFloat:0.01']}
                                errorMessages={[]} />

                        </FormControl>
                    </Grid>
                  {!this.state.hideSelectArea ? 
                    <Grid item xs={6}>
                        <Typography id="range-slider" gutterBottom>
                            {i18n.t('parcela.selecioneaarea', " Selecione a Área")}
                        </Typography>
                        <Slider
                            value={this.grau_inicial, this.grau_fim}
                            onChange={this.handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={this.valuetext}
                            max={360}
                            min={0}
                            defaultValue={[0, 360]}
                        />
                    </Grid>
                    : <Grid item xs={6}>{"   "}</Grid>}  
                </Grid>

                <Map center={this.state.center}
                    zoom={this.state.zoom}
                    className={classes.mapMain}
                    maxZoom={17}
                    style={this.props.style}
                >
                    <LayersControl>
                        <GridLayer>

                        </GridLayer>
                        <BaseLayer checked name="Satélite">
                            <TileLayer
                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </BaseLayer>

                        <Overlay checked name="Open Street">
                            <TileLayer
                                maxZoom={10}
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </Overlay>
                        <FeatureGroup>
                            <EditControl
                                onEdited={this.onEdited}
                                onCreated={this.onCreated}
                                onDeleted={this.onDeleted}
                                position='topright'
                                draw={this.getExclude()}
                                edit={true}
                            />
                         
                            {/* {this.drawPolygon()} */}
                        </FeatureGroup>
                        {this.drawSector()}
                        {this.showFazenda()}
                        {this.showEquipamento()}
                        {this.showParcelas()}
                        {this.showDelimitation()}
                       

                    </LayersControl>
                </Map>
                <Grid item xs={3}>
                    <Button
                        fullWidth
                        className={classes.buttom}
                        onClick={this.action}
                    > <Save />{i18n.t('formfazenda.salvar', 'Salvar')}</Button>
                </Grid>
            </Container>
        )
    }
}

/** props */
DrawMap.propTypes = {
    drawExclude: PropTypes.array,
    parcelas: PropTypes.array
}

const styles = (theme) => ({
    mapMain: {
        width: 800,
        height: 500,
    },
    buttom: {
        backgroundColor: appColors.ICONS_CONFIRM,
        color: 'white'
    }
})


export default withStyles(styles)(DrawMap)