export function makeSum(rows, sumCols=[], headRows=[], rowId){
	let ret = {}
	headRows.map(hr => {
		if(sumCols.indexOf(hr.id) > -1){
			ret[hr.id] = 0
		}else{
			ret[hr.id] = '----'
		}
	})
	ret[rowId] = "Totais"
	rows.map(row => {
		sumCols.map(sc => {
			ret[sc] += row[sc] 
		})
	})
	return ret
}