import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';
import {  generateGetWithAuth } from './service'




function getFotoFile(id){             
    const url =  generateGetWithAuth(`/qualiagro/file/${id}`) 
    return url
 }

 export default   getFotoFile