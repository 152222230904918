

/*  A chave de um sub elemento deve ser algo parecido com "foo.bar"
*   Isso faz um busca no objeto json splitando a string por ponto -> [foo, bar]
*/
export function findValue(row, key){
    if(typeof key === 'function'){
        try{
            return key(row)
        }catch(error){
            return ""
        }
    }
    let rootValue = row
    if(key && key.indexOf("\.") > -1){
        const keys = key.split("\.")
        keys.map(k => {
            if(rootValue && k in rootValue)
                rootValue = rootValue[k]
        })
    }else{
        rootValue = row[key]
    }
    return rootValue
}