const languages = [
  { 
    id: 0,
    sigla: 'pt-BR', 
    nome: 'Português' 
  },
  { 
    id: 1,
    sigla: 'es', 
    nome: 'Espanol' 
  },
  { 
    id: 2,
    sigla: 'en', 
    nome: 'English' 
  },
];

export default languages;