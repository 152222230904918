import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Select } from '@material-ui/core';
import { toast } from 'react-toastify'
import { userService } from '../../services/user_service';
import { appColors } from '../../constants/app_constants';
import languages from '../../constants/languagesSuported';
import i18n from '../../../i18n';

class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.error !== this.state.error) {
      this.setState({ error: '' })
    }
  }

  switch_route = (route) => {
    if (this.props.browserHistory) {
      this.props.browserHistory.push(route)
    }
  }

  login(e) {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    // stop here if form is invalid
    if (!(username && password)) { return; }
    this.setState({ loading: true });
    userService.login(username, password).then(
      user => {
        const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
        this.props.history.push(from);
      },
      error => {
        this.setState({ error, loading: false })
        toast.error(i18n.t('formlogin.falhalogin', "Falha ao realizar login! Verifique Usuário e Senha"))
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.login(e)
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.login(e)
  }

  handleLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
    this.setState({ ...this.state })
    localStorage.setItem('language', event.target.value)
  }

  render() {
    const { username, password } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Container  className={classes.headContainer}  maxWidth="xl">
          <Select
              native
              value={i18n.language}
              onChange={this.handleLanguage}
              style={{ fontWeight:'bold', background: "white", width: 200, alignItems: 'center' }}
            >
              {
                languages.map((language) => 
                  <option key={language.id} value={language.sigla}>{language.nome}</option>)
              }
          </Select>
        </Container>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <img style={{ margin: 10 }} width={'40%'} src="/static/images/logo.png"></img>
            <Typography color="secondary" component="h1" variant="h5">
              { i18n.t('formlogin.entrar', 'Entrar')}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                className={classes.textStyle}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"
                name="username"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={username}
                autoComplete=""
                autoFocus

              />
              <TextField
                className={classes.textStyle}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={password}
                autoComplete="current-password"

              />
              <Button
                fullWidth
                onClick={this.handleClick}
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                { i18n.t('formlogin.entrar') }
              </Button>
              <Grid container justify="center" >
                <Grid item xs={6} >
                  <Link onClick={e => this.switch_route("/recuperar")} variant="body2" style={{ color: appColors.SECONDARY }}>
                    { i18n.t("formlogin.msg_recuperar", "Esqueceu a senha?")}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textStyle: {
    backgroundColor: appColors.LIGHT,
  },
});

export default withStyles(styles)(FormLogin);
