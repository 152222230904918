import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {  DateRangeRounded } from '@material-ui/icons'
import moment from 'moment'
import i18n from '../../../../i18n'


export default class CustomDatePicker extends React.Component{
    constructor(props){
        super(props)
    }

    handleChangeDate = (value, name) => {
       return {
           target: {
               value: moment(value),
               name
           }
       }
    }

    render(){
        const color = this.props.iconColor || 'primary' 
        return <KeyboardDatePicker
            {...this.props}
            onChange={(e) => this.props.onChange(this.handleChangeDate(e, this.props.name))}
            autoOk={true}
            minDateMessage={ i18n.t("customdatepicker.mindatemessage", "Data está fora do valor mínimo permitido")}
            maxDateMessage={ i18n.t("customdatepicker.maxdatemessage", "Data está fora do valor máximo permitido")}
            invalidLabel={ i18n.t("customdatepicker.invalidatelabel", "Inválido")}
            invalidDateMessage={ i18n.t('customdatepicker.invaliddatemessage', "Data é inválida")}
            keyboardIcon={<DateRangeRounded color={color} style={{color: color}}/>}
        >
        </KeyboardDatePicker>
    }
}