import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { appColors } from '../../constants/app_constants'
import i18n from '../../../i18n'
import getFotoFile from '../../services/qualiagroService'
import FilterRelatorio from '../../components/_parcela/FilterRelatorio'
import {
    SkipNext, SkipPrevious, PictureAsPdf,Description
} from '@material-ui/icons';
import {
    makeObjectListDialog, makeAlertDialog, makeActionFormDialog,
} from "../default/dialogs/DialogRefInterface"

import RelatorioQualiagro from '../../components/_parcela/RelatorioQualiagro'


class ListRight extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            qualiagro: {},
            date: new Date()
        }
    }
    dateConvert(date) {
        var dateParse = new Date(date)
        return dateParse.toLocaleDateString()

    }
    componentDidMount() {
        const data = this.props.data
        this.setState({ qualiagro: this.props.data })
        if (data.qualiagros) {
            this.setState({
                galleryItems:
                    data.qualiagros.map((i) =>
                        <Card key={i} style={{ width: '410px', height: '460px', marginLeft: "auto", marginRight: "auto", display: "block", }} >
                            <CardContent>
                                <img src={getFotoFile(i.id)} style={{ width: '400px', height: '400px', borderRadius: '5%' }}  ></img>
                            </CardContent>
                            <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM }}>
                                {this.dateConvert(i.createdAt)}
                            </Typography>
                        </Card>)

            })
        }
    }

    handleFilter = () => {
        makeActionFormDialog(FilterRelatorio, "Filtrar Relatório Qualiagro", false, undefined, "Filtrar", (data) => {
            console.log(data)
            this.state.qualiagro.dateFilter = data
            this.setState({  })

            makeActionFormDialog(RelatorioQualiagro, '', false,
                this.state.qualiagro , false, data => {
                }).current.open()

        }).current.open()
    }



    render() {
        const { classes } = this.props
        return (
            <>
                <Grid container item className={classes.form} spacing={2}>
                    <AliceCarousel
                        dotsDisabled={true}
                        buttonsDisabled={true}
                        items={this.state.galleryItems}
                        ref={(el) => (this.Carousel = el)}
                        infinite={false}
                    />
                    {/* <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM }}>

                        A data do dia  o.passado   se refere a melhor imagem da quinzena capturada pelo satélite.
                        Data do envio:
                        Próxima imagem prevista para:
                           </Typography> */}

                    <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={() => this.Carousel.slideNext()}>Próximo <SkipNext /> </Button>
                    <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={() => this.Carousel.slidePrev()}><SkipPrevious /> Anterior </Button>
                </Grid>
              <Button fullWidth style={{ color: appColors.ICONS_CONFIRM }} onClick={this.handleFilter}> <Description/>  Relatório</Button>
            </>
        )
    }
}


const styles = (theme) => ({
    root: {
        color: '#ffffff',
        // backgroundColor: '#201F29',
    },
    nested: {
        paddingRight: theme.spacing(4),
    },
    labelColor: {
        color: 'white'
    },
    image: {
        width: '280px',
        height: '280px',
        borderRadius: '50%'
    },
    form: {
        minHeigth: 800,
        maxWidth: 420,
        minWidth: 420,
        color: appColors.ICONS_CONFIRM,
        padding:'2vh',
        marginLeft: "auto", marginRight: "auto",

    },
})

export default withStyles(styles)(ListRight)