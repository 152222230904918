import React from 'react'
import PropTypes from 'prop-types'
import { buildChart } from '../_graficos/chartBuild'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { Container, IconButton, Menu, Select, Grid, FormControl, InputLabel, MenuItem, Button } from '@material-ui/core'
import Charts from '../_graficos/ApexOverride'
import { FilterList, Search } from '@material-ui/icons'
import { chartTypes, chartIntervals } from '../../constants/model_constants'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import moment from 'moment'
import { makeProgressDialog, closeProgressDialog } from '../default/dialogs/DialogRefInterface'
import i18n from '../../../i18n'
import { toast } from 'react-toastify'
import { buildMediaByTime } from '../../util/chart_data_util'

class DispositivoChart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            options: {},
            series: [],
            rows: [],
            attributes: [],

            startDate: moment(),
            endDate: moment(),
            openSettings: null,
            dataInstalacao: null,

            intervalLabel: 'real'
        }
    }

    componentWillMount() {
        if (this.props.dispositivo.data_instalacao !== null) {
            this.setState({
                attributes: this.props.allAttributes.filter(attr => attr.type !== "null"),
                startDate: this.props.dispositivo.data_instalacao,
                endDate: moment(this.props.dispositivo.data_instalacao).add('30', 'days'),
                dataInstalacao: this.props.dispositivo.data_instalacao
            }, () => {
                this.searchData()
            })
        }

    }

    searchData = () => {
        makeProgressDialog(i18n.t('chart.carregando', "Carregando dados...")).current.open()
        const dispositivo_iot_id = this.props.dispositivo.id
        this.props.service.get({
            dispositivo_iot_id,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }, response => {
            if (validateShowResponseToast(response, '', false)) {
                const data = response.data
                this.updateChart(data)
            }
            closeProgressDialog()
        })
    }

    updateChart = (data, label = this.state.intervalLabel) => {
        const formatedData = buildMediaByTime(data, label,
            this.props.allAttributes.map(attr => attr.id), ['pluviometro_0', 'pluviometro_1'])
        const chart = buildChart('', formatedData, 'data_hora', this.state.attributes)
        const series = chart.series
        let options = chart.options
        try {
            this.setState({
                options,
                series,
                rawRows: data,
                rows: formatedData,
                intervalLabel: label,
                dispositivo_iot_id: this.props.dispositivo.id,
            })
        } catch (error) { console.log(error) }
    }

    handleChangeType = (e, serie) => {
        const type = e.target.value
        if (type !== serie.type) {
            serie.type = type
            this.state.attributes = this.state.attributes.filter(v => v.label !== serie.label)
            if (serie.type !== "null") {
                this.state.attributes.push(serie)
            }
            this.updateChart(this.state.rawRows)
        }
    }

    componentDidMount() {
        this.searchData()
    }

    handleChangeDate = (e) => {
        const { name, value } = e.target;
        if (moment(this.state.dataInstalacao).isAfter(moment(value))) {
            toast.warn(i18n.t('estacaochart.msg_datamenor', "Data deve ser maior que a data de instalação"))
        } else {
            this.setState({ [name]: value });
        }
    }

    handleChangeInterval = (e) => {
        const { value } = e.target
        this.updateChart(this.state.rawRows, value)
    }

    handleOpenSettings = (value) => {
        this.setState({
            openSettings: value
        })
    }

    renderMenu = () => {
        try {
            return (
                <Grid container spacing={3} style={{ width: 600, padding: 20 }}>
                    {this.props.allAttributes.map(serie => {
                        return <Grid container item xs={4} spacing={1}>
                            <Grid container xs={12}>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel style={{ color: serie.color }}>{serie.label}</InputLabel>
                                        <Select
                                            fullWidth
                                            value={serie.type}
                                            onChange={e => this.handleChangeType(e, serie)}
                                        >
                                            {chartTypes.map(ct => {
                                                return <MenuItem value={ct.id}>{ct.desc}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel style={{ color: serie.color }}>{''}</InputLabel>
                                        <Select
                                            value={serie.stroke}
                                            onChange={(e) => {
                                                const stroke = e.target.value
                                                serie.stroke = stroke
                                                this.updateChart(this.state.rows)
                                            }}
                                        >
                                            {[1, 3, 5, 7, 9, 11, 15].map(v => {
                                                return <MenuItem value={v}>{v}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            )
        } catch (error) {
            return <div></div>
        }

    }

    render() {
        return <Container>
            <IconButton onClick={e => this.handleOpenSettings(e.target)}>
                <FilterList />
            </IconButton>

            <CustomDatePicker
                style={{ maxWidth: 150, marginLeft: 20 }}
                disableToolbar
                label={i18n.t('chart.de', "Dê")}
                variant="inline"
                format="DD/MM/YYYY"
                name="startDate"
                value={this.state.startDate}
                onChange={this.handleChangeDate}
            />
            <CustomDatePicker
                style={{ maxWidth: 150, marginLeft: 20 }}
                disableToolbar
                label={i18n.t('chart.ate', "Até")}
                variant="inline"
                format="DD/MM/YYYY"
                name="endDate"
                value={this.state.endDate}
                onChange={this.handleChangeDate}
            />

            <Button onClick={e => { this.searchData() }} style={{ marginTop: 15 }}>
                <Search /> {'Buscar'}
            </Button>
            <FormControl style={{marginLeft: 75}}>
                <InputLabel >Média no Intervalo</InputLabel>
                <Select
                    style={{ minWidth: 150}}
                    name='sl_interval'
                    onChange={this.handleChangeInterval}
                    value={this.state.intervalLabel}
                >
                    {chartIntervals.map(cInterval =>
                        <MenuItem value={cInterval.id}>{cInterval.desc}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <Menu
                id="menu_settings"
                anchorEl={this.state.openSettings}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(this.state.openSettings)}
                onClose={() => { this.handleOpenSettings(null) }}
                MenuListProps={{ onMouseLeave: () => this.handleOpenSettings(null) }}
            >
                {this.renderMenu()}
            </Menu>
            <Charts
                options={this.state.options}
                series={this.state.series}
                type="line"
                width={1200}
                height={450}
            >
            </Charts>
        </Container>
    }
}

DispositivoChart.propTypes = {
    dispositivo: PropTypes.object,
    service: PropTypes.object,
    allAttributes: PropTypes.array
}

export default DispositivoChart