import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, NativeSelect,  InputLabel } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { clearAllMask } from '../../util/utils'

import i18n from '../../../i18n';

class FormContato extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pessoa_id: 0,
            telefone: "",
            celular: "",
            email: "",
            status: 1
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = () => {
        this.state.celular = clearAllMask(this.state.celular)
        this.state.telefone = clearAllMask(this.state.telefone)
        const data = filterAndDeleteValues(this.state, {
            email: [''], celular: [''], telefone: ['']
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_email"
                        label="Email"
                        name="email"
                        fullWidth
                        autoFill='off'
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        validators={['isEmail']}
                        errorMessages={[i18n.t('validator.isemail', 'Não é um email válido')]}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <NumberFormat
                        customInput={TextValidator}
                        format="(##)#####-####"
                        id="telefone"
                        label={i18n.t('contato.telefonefixo', 'Telefone Fixo')}
                        name="telefone"
                        fullWidth
                        type="text"
                        value={this.state.telefone}
                        onChange={this.handleChange}
                    ></NumberFormat>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <NumberFormat
                        customInput={TextValidator}
                        format="(##)#####-####"
                        id="celular"
                        label={i18n.t('contato.telefonecelular', 'Telefone Celular')}
                        name="celular"
                        fullWidth
                        type="text"
                        value={this.state.celular}
                        onChange={this.handleChange}
                    >
                    </NumberFormat>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >Status</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={1}>{ i18n.t('status.ativo', 'Ativo') }</option>
                        <option value={0}>{ i18n.t('status.inativo', 'Inativo') }</option>
                    </NativeSelect>
                </FormControl>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormContato)