import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';

export const estacaoSimplificadaDataService = {
   get,
};

async function get(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const query = make_query(data)
    response = await getAxiosInstance(`/estacaoSimplificadaData${query}`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
    return response || errorResponse
}
