export const estadosCivis = [
  { id: 1, desc: "Solteiro" },
  { id: 2, desc: "Casado" },
  { id: 3, desc: "Divorciado" },
  { id: 4, desc: "Viúvo" },
  { id: 5, desc: "Separado Judicialmente" },
];

export const userNiveisPermissoes = [
  { id: 1, desc: "Baixo" },
  { id: 2, desc: "Médio" },
  { id: 3, desc: "Alto" },
  { id: 4, desc: "Administrador Médio"},
  { id: 5, desc: "Administrador Alto"}
];

export const tipoDispositivo = [
  { id: 1, desc: "Estação Icrop" },
  { id: 2, desc: "Estação Cebola" },
  { id: 3, desc: "Estação Simplificada" },
  { id: 4, desc: "Auditor" },
];

export const chartIntervals = [
  { id: 'real', desc: 'Leitura Real'},
  { id: 'hour', desc: 'Hora'}, 
  { id: 'day', desc: 'Dia'},
  { id: 'week', desc: 'Semana'},
  { id: 'month', desc: 'Mês'}
]

export const tipoDispositivoConst = {
  ESTACAO_ICROP: 1,
  ESTACAO_ICROP_SATELTE: 4,
  ESTACAO_CEBOLA: 2,
  ESTACAO_SIMPLIFICADA: 3,
  AUDITOR: 4
}

export const coresParcelas = [
  { id: "red", desc: "Vermelho" },
  { id: "blue", desc: "Azul" },
  { id: "green", desc: "Verde" },
  { id: "white", desc: "Branco" },
  { id: "black", desc: "Preto" },
  { id: "purple", desc: "Roxo" },
  { id: "pink", desc: "Rosa" },
  { id: "yellow", desc: "Amarelo" },
];

export const chartTypes = [
  { id: "null", desc: "---"},
  { id: "line", desc: "Linhas" },
  { id: "bar", desc: "Barras" },
  { id: "area", desc: "Área" },
];

export const chartColors = {
  BLUE1: "#3366CC",
  RED1: "#DC3912",
  YELLOW1: "#FF9900",
  GREEN1: "#109618",
  PINK1: "#990099",
  BLUE2: "#3B3EAC",
  BLUE3: "#0099C6",
  PINK2: "#DD4477",
  GREEN2: "#66AA00",
  RED2: "#B82E2E",
  BLUE4: "#316395",
  PINK3: "#994499",
  GREEN3: "#22AA99",
  YELLOW2: "#AAAA11",
  RED3: "#6633CC",
  YELLOW3: "#E67300",
  RED4: "#8B0707",
  GREEN4: "#329262",
  BLUE5: "#5574A6",
  BLUE6: "#3B3EAC",
};
