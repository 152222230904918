import L from 'leaflet'
import iconFarm from '../../icons/farm.png'
import iconMetereologiaBlack from '../../icons/metereologia_black.png'
import iconSprinklers from '../../icons/sprinklers.png'
export const houseIcon = new L.Icon({
    iconUrl: iconFarm,
    iconRetinaUrl: iconFarm,
    iconAnchor: [20, 50],
    popupAnchor: [0, -44],
    iconSize: [51, 61],
    //shadowUrl: '../assets/marker-shadow.png',
    //shadowSize: [68, 95],
    //shadowAnchor: [20, 92],
  })

export const windSensorIcon = new L.Icon({
    iconUrl: iconMetereologiaBlack,
    iconRetinaUrl: iconMetereologiaBlack,
    iconAnchor: [20, 50],
    popupAnchor: [0, -44],
    iconSize: [48, 48],
  })

  export const SprinklersIcon = new L.Icon({
    iconUrl: iconSprinklers,
    iconAnchor: [20, 50],
    popupAnchor: [0, -44],
    iconSize: [48, 48],
  })

 