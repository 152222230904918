import { chartColors } from '../../constants/model_constants'
import i18n from '../../../i18n'

const STROKE = 3

/**
 * @param {String} id 
 * @param {String} label 
 * @param {String} valuesLabel 
 * @param {String} type 
 * @param {Float} stroke 
 * @param {String} color 
 */
function buildChartField(id, label, valuesLabel, type, stroke, color){
    return { id, label ,valuesLabel, type, stroke, color }
}

export function buildEstacaoCebolaAttributes() {
    return [
        buildChartField(
            'umidade', i18n.t('chartattributes.umidade', 'Umidade'), 
            `${i18n.t('chartattributes.umidade', 'Umidade')} %`, 'null', STROKE, chartColors.BLUE1
        ),
        buildChartField(
            'temperatura', i18n.t('chartattributes.temperatura', 'Temperatura'), 
            `${i18n.t('chartattributes.temperatura', 'Temperatura')} ºC`, 'null', STROKE, chartColors.RED1
        ),
        buildChartField(
            'luminosidade', i18n.t('chartattributes.luminosidade', 'luminosidade'), 
            `${i18n.t('chartattributes.luminosidade', 'Luminosidade')} w/m²`, 'null', STROKE, chartColors.YELLOW1
        ),
        buildChartField(
            'pluviometro_0', i18n.t('chartattributes.pluviometro', 'Pluviômetro'), 
            `${i18n.t('chartattributes.pluviometro', 'Pluviômetro')} mm`, 'bar', STROKE, chartColors.BLUE3
        ),
        buildChartField(
            'pluviometro_0_acumulado', i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado'), 
            `${i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado')} mm`, 'null', STROKE, chartColors.YELLOW3
        ),
        buildChartField(
            'velocidade_chuva', i18n.t('chartattributes.velocidade_chuva', 'Velocidade Chuva'), 
            `${i18n.t('chartattributes.velocidade_chuva', 'Velocidade Chuva')} m/s`, 'null', STROKE, chartColors.BLUE4
        ),
        buildChartField(
            'umidade_solo_0', i18n.t('chartattributes.umidade_solo', 'Umidade Solo'), 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} %`, 'null', STROKE, chartColors.PINK1
        ),
        buildChartField(
            'umidade_solo_1', `${ i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 2`, 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 2 %`, 'null', STROKE, chartColors.GREEN2
        ),
        buildChartField(
            'umidade_solo_2', `${ i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 3`, 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 3 %`, 'null', STROKE, chartColors.RED4
        ),
        buildChartField(
            'temperatura_solo_1', i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo'),  // INVERTIDO COM TEMP. SOLO 1
            `${i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo')} ºC`, 'null', STROKE, chartColors.RED2 
        ),
        buildChartField(
            'temperatura_solo_0', `${i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo')} 2`,  // INVERTIDO COM TEMP. SOLO 0
            `${i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo')} 2 ºC`, 'null', STROKE, chartColors.YELLOW3
        ),
        buildChartField(
            'temperatura_solo_2', `${i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo')} 3`, 
            `${i18n.t('chartattributes.temperatura_solo', 'Temperatura Solo')} 3 ºC`, 'null', STROKE, chartColors.GREEN4
        ),
        buildChartField(
            'temperatura_superficial', i18n.t('chartattributes.temperatura_superficial', 'Temperatura Superficial'), 
            `${i18n.t('chartattributes.temperatura_superficial', 'Temperatura Superficial')} ºC`, 'null', STROKE, chartColors.YELLOW2
        ),
        buildChartField(
            'uv', i18n.t('chartattributes.uv', 'UV'), 
            `${i18n.t('chartattributes.uv', 'UV')} uW/cm²`, 'null', STROKE, chartColors.BLUE2
        ),
        buildChartField(
            'umidade_superficial', i18n.t('chartattributes.umidade_superficial', 'Umidade Superfical'), 
            `${i18n.t('chartattributes.umidade_superficial', 'Umidade Superfical')} %`, 'null', STROKE, chartColors.BLUE5
        ),
        buildChartField(
            'velocidade_vento', i18n.t('chartattributes.velocidade_vento', 'Velocidade Vento'), 
            `${i18n.t('chartattributes.velocidade_vento', 'Velocidade Vento')} m/s`, 'null', STROKE, chartColors.GREEN1
            ),
        buildChartField(
            'molhamento_foliar', i18n.t('chartattributes.molhamento_foliar', 'Molhamento Foliar'), 
            `${i18n.t('chartattributes.molhamento_foliar', 'Molhamento Foliar')} %`, 'null', STROKE, chartColors.BLUE6
        )
    ]
}

export function buildEstacaoSimplificadaAttributes() {
    return [
        buildChartField(
            'umidade', i18n.t('chartattributes.umidade', 'Umidade'), 
            `${i18n.t('chartattributes.umidade', 'Umidade')} %`, 'null', STROKE, chartColors.BLUE1
        ),
        buildChartField(
            'temperatura', i18n.t('chartattributes.temperatura', 'Temperatura'), 
            `${i18n.t('chartattributes.temperatura', 'Temperatura')} ºC`, 'null', STROKE, chartColors.RED1
        ),
        buildChartField(
            'pluviometro_0', i18n.t('chartattributes.pluviometro', 'Pluviômetro'), 
            `${i18n.t('chartattributes.pluviometro', 'Pluviômetro')} mm`, 'bar', STROKE, chartColors.BLUE3
        ),
        buildChartField(
            'pluviometro_0_acumulado', i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado'), 
            `${i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado')} mm`, 'null', STROKE, chartColors.YELLOW3
        ),
        buildChartField(
            'pluviometro_1', `${i18n.t('chartattributes.pluviometro', 'Pluviômetro')} 2`, 
            `${i18n.t('chartattributes.pluviometro', 'Pluviômetro')} 2 mm`, 'null', STROKE, chartColors.BLUE2
        ),
        buildChartField(
            'pluviometro_1_acumulado', `${i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado')} 2`, 
            `${i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado')} 2 mm`, 'null', STROKE, chartColors.YELLOW2
        ),
        buildChartField(
            'umidade_solo_0', i18n.t('chartattributes.umidade_solo', 'Umidade Solo'), 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} %`, 'null', STROKE, chartColors.PINK1
        ),
        buildChartField(
            'umidade_solo_1', `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 2`, 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 2 %`, 'null', STROKE, chartColors.GREEN3
        ),
        buildChartField(
            'umidade_solo_2', `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 3`, 
            `${i18n.t('chartattributes.umidade_solo', 'Umidade Solo')} 3 %`, 'null', STROKE, chartColors.BLUE5
        ),
    ]
} 

export function buildEstacaoSateliteAttributes() {
    return [
        buildChartField(
            'outhumi', i18n.t('chartattributes.umidade', 'Umidade'), 
            `${i18n.t('chartattributes.umidade', 'Umidade')} %`, 'null', STROKE, chartColors.BLUE1
        ),
        buildChartField(
            'outemp', i18n.t('chartattributes.temperatura', 'Temperatura'), 
            `${i18n.t('chartattributes.temperatura', 'Temperatura')} ºC`, 'null', STROKE, chartColors.RED1
        ),
        buildChartField(
            'light', i18n.t('chartattributes.luminosidade', 'luminosidade'), 
            `${i18n.t('chartattributes.luminosidade', 'Luminosidade')} w/m²`, 'null', STROKE, chartColors.YELLOW1
        ),
        buildChartField(
            'dailyrain', i18n.t('chartattributes.pluviometro', 'Pluviômetro'), 
            `${i18n.t('chartattributes.pluviometro', 'Pluviômetro')} mm`, 'bar', STROKE, chartColors.BLUE3
        ),
        // buildChartField(
        //     'pluviometro_0_acumulado', i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado'), 
        //     `${i18n.t('chartattributes.pluviometro_acumulado', 'Pluviômetro Acumulado')} mm`, 'null', STROKE, chartColors.YELLOW3
        // ),
       
        buildChartField(
            'uv', i18n.t('chartattributes.uv', 'UV'), 
            `${i18n.t('chartattributes.uv', 'UV')} uW/cm²`, 'null', STROKE, chartColors.BLUE2
        ),
       
        buildChartField(
            'windspeed', i18n.t('chartattributes.velocidade_vento', 'Velocidade Vento'), 
            `${i18n.t('chartattributes.velocidade_vento', 'Velocidade Vento')} m/s`, 'null', STROKE, chartColors.GREEN1
            ),
      
    ]
}