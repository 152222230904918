import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, Checkbox, InputLabel, NativeSelect, FormControlLabel } from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { fazendaService } from '../../services/fazendaService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { pessoaService } from '../../services/pessoaService'
import i18n from '../../../i18n'

class FormPessoaFazenda extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                id: 0,
                pessoa_id: 0,
                fazenda_id: 0,
                consultor: false,
                proprietario: false,
                responsavel_financeiro: false,
                responsavel: false,
                chefe_regional: false,
                status: 1,
            },
            editableFazenda: false,
            editablePessoa: false,

            fazendas: [],
            pessoas: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        Object.keys(this.state.data).map(kdata => 
            this.state.data[kdata] = this.props.data[kdata])
        const fazendas = [], pessoas = []
        if(this.props.data.fazenda) fazendas.push(this.props.data.fazenda)
        if(this.props.data.pessoa) pessoas.push(this.props.data.pessoa)
        this.setState({
            fazendas,
            pessoas,
            data: this.state.data
        })
        if( ! this.state.data.fazenda_id){
            this.getFazendas()
        }
        if( ! this.state.data.pessoa_id ){
            this.getPessoas()
        }
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    getFazendas = () => {
        const query = { status: 1 }
        query.fields = ['id', 'nome']
        fazendaService.get(query, response => {
            if (validateShowResponseToast(response, "", false)) {
                this.setState({
                    fazendas: response.data,
                })
            }
        })
    }

    getPessoas = () => {
        const query = { status: 1 }
        query.fields = ['id', 'nome']
        pessoaService.get(query, response => {
            if (validateShowResponseToast(response, "", false)) {
                this.setState({
                    pessoas: response.data,
                })
            }
        })
    }

    handleSubmit = () => {
        this.props.handleFormState(this.state.data)
    }

    handleChange(e) {
        let { name, value } = e.target;
        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }
        this.setState({
            data: { ...this.state.data, [name]: value }
        })
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{ i18n.t('pessoafazenda.pessoa', 'Pessoa') }</InputLabel>
                    <NativeSelect
                        id='ns_pessoa_id'
                        fullWidth
                        value={this.state.data.pessoa_id}
                        name='pessoa_id'
                        onChange={this.handleChange}
                    >
                        <option value=""></option>
                        {this.state.pessoas.map(ps => {
                            return <option value={ps.id}>{ps.nome}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{ i18n.t('pessoafazenda.fazenda', 'Fazenda') }</InputLabel>
                    <NativeSelect
                        id='ns_fazenda_id'
                        fullWidth
                        value={this.state.data.fazenda_id}
                        name='fazenda_id'
                        onChange={this.handleChange}
                    >
                        <option value=""></option>
                        {this.state.fazendas.map(fz => {
                            return <option value={fz.id}>{fz.nome}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                <FormControlLabel
                    fullWidth item className={classes.container}
                    control={
                        <Checkbox
                            checked={this.state.data.consultor}
                            onChange={this.handleChange}
                            value="primary"
                            name="consultor"
                        />
                    }
                    label={ i18n.t('pessoafazenda.consultor', 'Consultor') }
                />
                <FormControlLabel
                    fullWidth item className={classes.container}
                    control={
                        <Checkbox
                            checked={this.state.data.proprietario}
                            onChange={this.handleChange}
                            value="primary"
                            name="proprietario"
                        />
                    }
                    label={ i18n.t('pessoafazenda.proprietario', 'Proprietário')}
                />
                <FormControlLabel
                    fullWidth item className={classes.container}
                    control={
                        <Checkbox
                            checked={this.state.data.responsavel}
                            onChange={this.handleChange}
                            value="primary"
                            name="responsavel"
                        />
                    }
                    label={ i18n.t('pessoafazenda.responsavel', 'Responsável')}
                />
                <FormControlLabel
                    fullWidth item className={classes.container}
                    control={
                        <Checkbox
                            checked={this.state.data.chefe_regional}
                            onChange={this.handleChange}
                            value="primary"
                            name="chefe_regional"
                        />
                    }
                    label={ i18n.t('pessoafazenda.chefe_regional', 'Chefe Regional')}
                />
                <FormControlLabel
                    fullWidth item className={classes.container}
                    control={
                        <Checkbox
                            checked={this.state.data.responsavel_financeiro}
                            onChange={this.handleChange}
                            value="primary"
                            name="responsavel_financeiro"
                        />
                    }
                    label={ i18n.t('pessoafazenda.responsavel_financeiro', 'Responsável Financeiro')}
                />
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{ i18n.t('pessoafazenda.status', 'Status') }</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.data.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={1}>{ i18n.t('status.ativo', 'Ativo') }</option>
                        <option value={0}>{ i18n.t('status.inativo', 'Inativo') }</option>
                    </NativeSelect>
                </FormControl>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 350,
        minWidth: 350
    },

    container: {
        marginTop: "15px",
        minWidth: "150px"
    }
})

export default withStyles(styles)(FormPessoaFazenda)