import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { makeProgressDialog, closeProgressDialog } from '../default/dialogs/DialogRefInterface'
import { csvUploadService } from '../../services/csvUploadService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'

import i18n from '../../../i18n';

class CSVUpload extends React.Component {

    constructor(props) {
        super(props) 

        this.state = {
            file: null,
            message: ""
        }
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.state.file) {
            makeProgressDialog(undefined, i18n.t('cvsupload.aguarde', 'Aguarde...')).current.open()
            const data = this.state
            csvUploadService.upload(data, response => {
                if(validateShowResponseToast(response)){
                    const data = response.data
                    this.setState({
                        message: `${i18n.t('cvsupload.salvos', 'Salvos')} ${data.salvos} ${i18n.t('cvsupload.linhasde', 'linhas de')} ${data.total}`
                    })
                }
                closeProgressDialog()
            })
        }
    }

    handleChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    render() {
        return <Container>
            <form >
                <input type="file" accept="text/csv" onChange={this.handleChange} />
                <Typography>{this.state.message}</Typography>
            </form>
        </Container>
    }
}

export default CSVUpload