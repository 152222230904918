import { windyMapActions } from "../consts";

const INITIAL_STATE = {
  opacity: 0.3,
  zoom: 3,
  isShowFazendas: true,
  isShowDispositivos: true,
  isShowParcelas: true,
  isShowEquipamentos : true,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case windyMapActions.CHANGE_WINDY_MAP_OPACITY:
      return { ...state, opacity: action.payload.opacity };
    case windyMapActions.CHANGE_WINDY_MAP_ZOOM:
      return { ...state, zoom: action.payload.zoom };
    case windyMapActions.SHOW_DISPOSITIVOS:
      return { ...state, isShowDispositivos: action.payload.isShow };
    case windyMapActions.SHOW_FAZENDAS:
      return { ...state, isShowFazendas: action.payload.isShow };
    case windyMapActions.SHOW_PARCELAS:
      return { ...state, isShowParcelas: action.payload.isShow };
    case windyMapActions.SHOW_EQUIPAMENTOS:
      return { ...state, isShowEquipamentos: action.payload.isShow };
    default:
      return state;
  }
}
