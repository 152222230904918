import {  windyMapActions } from "../consts"

export function handleChangeWindyOpacity(e, opacity){
    return {
        type: windyMapActions.CHANGE_WINDY_MAP_OPACITY,
        payload: {opacity}
    }
}

export function handleChangeZoom(e, zoom){
    return {
        type: windyMapActions.CHANGE_WINDY_MAP_ZOOM,
        payload: {zoom}
    }
}

export function showFazendas(isShow=true){
    return {
        type: windyMapActions.SHOW_FAZENDAS,
        payload: {isShow}
    }
}

export function showDispositivos(isShow=true){
    return {
        type: windyMapActions.SHOW_DISPOSITIVOS,
        payload: {isShow}
    }
}

export function showEquipamentos(isShow=true){
    return {
        type: windyMapActions.SHOW_EQUIPAMENTOS,
        payload: {isShow}
    }
}


export function showParcelas(isShow=true){
    return {
        type: windyMapActions.SHOW_PARCELAS,
        payload: {isShow}
    }
}