import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { appColors } from '../../../constants/app_constants';

(function(API){
    API.writeText = function(txt, options, x, y) {
		options = options ||{};
		var fontSize = this.internal.getFontSize();
		var pageWidth = this.internal.pageSize.width;
		let txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

        if( options.align === "center" ){
            x = ( pageWidth - txtWidth ) / 2;
        }else if(options.align === 'left'){
			x = 2
		}else if(options.align === 'right'){
			x = (pageWidth - txtWidth - 2)
		}
		this.text(txt,x,y);
		return x + txtWidth
    }
})(jsPDF.API);

(function(API){
    const newLocal = API.makeHorizontalLine = function (initVerticalLine) {
		this.line(0, initVerticalLine, this.internal.pageSize.width, initVerticalLine);
	};
})(jsPDF.API);

(function(API){
    const newLocal = API.makeHorizontalLine = function (initVerticalLine) {
		this.line(0, initVerticalLine, this.internal.pageSize.width, initVerticalLine);
	};
})(jsPDF.API);

export function makeTable(doc, cols=[], rows=[], initVerticalLine, fontSize=8, custom={}){
	let tableSize = 0
	doc.autoTable({
		head: cols,
		body: rows,
		theme: 'grid',
		headStyles: {
			fillColor: appColors.PRIMARY,
		},
		styles: {halign: 'center', fontSize: fontSize}, 
		margin: 2,
		startY: initVerticalLine,
		didDrawPage: (HookData) => {
			tableSize = HookData.table.height
		},
		willDrawCell: (hook) => {
			if(hook.cell.section === 'body'){
					let value = custom[hook.column.dataKey]
					if(hook.cell.raw === 'NaN'){
						hook.cell.text[0] = '----'
					}
			}
		}
	})
	return tableSize
}

export default jsPDF