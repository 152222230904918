import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';

export const pessoaService = {
   get,
   create,
   update,
   getPessoaUsuario,
   getPessoaContato,
   getPessoaEndereco,
   createPessoaEndereco
}

async function get(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const query = make_query(data)
    response = await getAxiosInstance(`/pessoas${query}`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function create(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/pessoas').post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function update(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/pessoas/${data.id}`).put(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getPessoaUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/pessoas/${data.id}/usuario`).get(undefined).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getPessoaContato(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/pessoas/${data.id}/contato`).get(undefined).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getPessoaEndereco(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/pessoas/${data.id}/endereco`).get(undefined).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createPessoaEndereco(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/pessoas/${data.id}/endereco`).post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
