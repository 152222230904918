import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, InputLabel} from '@material-ui/core'
import { filterAndDeleteValues, makeLikeQuerys } from '../../helpers/form_helper'
import i18n from '../../../i18n'

class FilterParcela extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: "",
            status: "",
            cultura: "",
            solo: "",
            fazenda_id: 0,
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        this.handleSubmit()
    }

    handleSubmit = () => {
        let data = filterAndDeleteValues(this.state, {
            nome: [""], status: [""], fazenda_id: [0], solo: [''], cultura: ['']
        })
        data = makeLikeQuerys(data, ['nome', 'solo', 'cultura'])
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterPress = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
          e.preventDefault();
          this.handleSubmit()
        }
      }
  
    render() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => {
                    e.preventDefault()
                    this.handleSubmit()
                }
                }>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_nome"
                        label={ i18n.t('parcela.nome', 'Descrição da Parcela') } 
                        name="nome"
                        fullWidth
                        type="text"
                        value={this.state.nome}
                        onChange={this.handleChange}
                        autoFocus={true}
                        onKeyDown={this.onEnterPress}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_cultura"
                        label={ i18n.t('parcela.cultura', 'Cultura') }
                        name="cultura"
                        fullWidth
                        type="text"
                        value={this.state.cultura}
                        onChange={this.handleChange}
                        onKeyDown={this.onEnterPress}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_solo"
                        label={ i18n.t('parcela.solo', 'Tipo de Solo') }
                        name="solo"
                        fullWidth
                        type="text"
                        value={this.state.solo}
                        onChange={this.handleChange}
                        onKeyDown={this.onEnterPress}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{ i18n.t('parcela.status', 'Status') }</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={""}></option>
                        <option value={1}>{ i18n.t('status.ativo', 'Ativo') }</option>
                        <option value={0}>{ i18n.t('status.inativo', 'Inativo') }</option>
                    </NativeSelect>
                </FormControl>
            </form>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 300,
        maxWidth: 300,
        minWidth: 300
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FilterParcela)