import { getAxiosInstance } from '../services/service'

export const csvUploadService = {
   upload,
};

async function upload(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const formData = new FormData()
    formData.append('file', data.file)
    const config = {
        headers: { 'content-type': 'multipart/form-data'}
    }
    response = await getAxiosInstance('/dispositivoData/csv').post(undefined, formData, config).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
