import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, NativeSelect, Grid, InputLabel } from '@material-ui/core'
import { filterAndDeleteValues } from '../../helpers/form_helper'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import moment from 'moment'
import { tipoDispositivo } from '../../constants/model_constants'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import { pessoaService } from '../../services/pessoaService'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import { fazendaService } from '../../services/fazendaService'
import i18n from '../../../i18n'

class FormDispositivo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            idx_dispositivo: "",
            tipo: 1,
            nome: "",
            senha: "",
            raio_leitura_mts: 0,
            data_instalacao: moment(),
            ocorrencias: "",
            numero_chip: "",
            numero_imei: "",
            valor_mensal: 0,
            numero_satelite: "",
            versao_firmware: "",
            data_expira_contrato_sat_chip: moment(),
            ttn_appeui: "",
            ttn_appkey: "",
            ttn_application_id: "",
            ttn_device_id: "",
            pasta_ftp: "",
            china_fail: 0,
            instalador_id: 0,
            fazenda_id: 0,
            localizacao: { type: "Point", coordinates: [0, 0] },
            status: 1,

            pessoas: [],
            fazendas: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.getPessoas()
        this.getFazendas()
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    getFazendas = async () => {
        fazendaService.get({status: 1, fields: ['id', 'nome']}, response => {
            if(validateShowResponseToast(response, '', false)){
                this.setState({
                    fazendas: response.data
                })
            }
        })
    }

    getPessoas = async () => {
        pessoaService.get({ fields: ['id', 'nome'] }, response => {
            if (validateShowResponseToast(response, '', false, false)) {
                this.setState({
                    pessoas: response.data
                })
            }
        })
    }

    handleSubmit = () => {
        const data = filterAndDeleteValues(this.state, {
            id: [0], nome: [''], idx_dispositivo: [''], tipo: [0], senha: [''], instalador_id: [0], pessoas: [undefined]
        })
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeLocation = (e) => {
        const { name, value } = e.target;
        if (name === 'lng') this.state.localizacao.coordinates[0] = value
        else if (name === "lat") this.state.localizacao.coordinates[1] = value
        this.setState({})
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>

                </FormControl>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_nome"
                                label={ i18n.t('dispositivo.nome', 'Descrição do Dispositivo') }
                                name="nome"
                                fullWidth
                                type="text"
                                value={this.state.nome}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('dispositivo.tipo', 'Tipo de Dispositivo') }</InputLabel>
                            <NativeSelect
                                id='ns_tipo'
                                fullWidth
                                value={this.state.tipo}
                                name='tipo'
                                onChange={this.handleChange}
                            >
                                {tipoDispositivo.map(td => {
                                    return <option value={td.id}>{td.desc}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_idx_dispositivo"
                                label={ i18n.t('dispositivo.idx_dispositivo', 'Id do Dispositivo') }
                                name="idx_dispositivo"
                                fullWidth
                                type="text"
                                value={this.state.idx_dispositivo}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_senha"
                                label="Senha do Dispositivo"
                                name="senha"
                                fullWidth
                                type="text"
                                value={this.state.senha}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid container item className={classes.container} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel>Falha China</InputLabel>
                        <NativeSelect
                            id='china_fail'
                            fullWidth
                            value={this.state.china_fail}
                            name='china_fail'
                            onChange={this.handleChange}
                        >
                            <option value={1}> Com Falha</option>
                            <option value={0}>Sem Falha</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>

                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="latitude"
                                label={ i18n.t('dispositivo.latitude', 'Latitude') }
                                name="lat"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao.coordinates[1]}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="longitude"
                                label={ i18n.t('dispositivo.longitude', 'Longitude') }
                                name="lng"
                                fullWidth
                                required
                                type="number"
                                value={this.state.localizacao.coordinates[0]}
                                onChange={this.handleChangeLocation}
                                validators={['required']}
                                errorMessages={[ i18n.t('validator.required', 'Este campo é obrigatório') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_raio_leitura_mts"
                                label={ i18n.t('dispositivo.raio_leitura_mts', 'Raio de Leitura (Mts)') }
                                name="raio_leitura_mts"
                                fullWidth
                                type="number"
                                value={this.state.raio_leitura_mts}
                                onChange={this.handleChange}
                                validators={['minFloat:.001']}
                                errorMessages={[ i18n.t('validator.maiorzero', 'Deve ser maior que zero') ]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_numero_chip"
                                label={ i18n.t('dispositivo.numero_chip', 'Número do Chip') }
                                name="numero_chip"
                                fullWidth
                                type="text"
                                value={this.state.numero_chip}
                                onChange={this.handleChange}
                                validators={[]}
                                errorMessages={[]}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_numero_imei"
                                label={ i18n.t('dispositivo.numero_imei', 'Número IMEI') }
                                name="numero_imei"
                                fullWidth
                                type="text"
                                value={this.state.numero_imei}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_versao_firmware"
                                label={ i18n.t('dispositivo.versao_firmware', 'Versão do Firmware') }
                                name="versao_firmware"
                                fullWidth
                                type="text"
                                value={this.state.versao_firmware}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_pasta_ftp"
                                label={ i18n.t('dispositivo.pasta_ftp', 'Pasta FTP') }
                                name="pasta_ftp"
                                fullWidth
                                type="text"
                                value={this.state.pasta_ftp}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_numero_satelite"
                                label={ i18n.t('dispositivo.numero_chip', 'Número Satélite') }
                                name="numero_chip"
                                fullWidth
                                type="text"
                                value={this.state.numero_chip}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={ i18n.t('dispositivo.data_expira_contrato_sat_chip', 'Expiração Contrado Satélite') }
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_expira_contrato_sat_chip"
                                value={this.state.data_expira_contrato_sat_chip}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_ttn_appeui"
                                label={ i18n.t('dispositivo.ttn_appeui', 'ttn appeui') }
                                name="ttn_appeui"
                                fullWidth
                                type="text"
                                value={this.state.ttn_appeui}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_ttn_appkey"
                                label={ i18n.t('dispositivo.ttn_appkey', 'ttn appkey') }
                                name="ttn_appkey"
                                fullWidth
                                type="text"
                                value={this.state.ttn_appkey}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_ttn_application_id"
                                label={ i18n.t('dispositivo.ttn_application_id', 'ttn application id') }
                                name="ttn_application_id"
                                fullWidth
                                type="text"
                                value={this.state.ttn_application_id}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="ttn_device_id"
                                label={ i18n.t('dispositivo.ttn_device_id', 'ttn device id') }
                                name="ttn_device_id"
                                fullWidth
                                type="text"
                                value={this.state.ttn_device_id}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('dispositivo.instalador', 'Instalador') }</InputLabel>
                            <NativeSelect
                                id='ns_instalador_id'
                                fullWidth
                                value={this.state.instalador_id}
                                name='instalador_id'
                                onChange={this.handleChange}
                            >
                                <option value={0}></option>
                                {this.state.pessoas.map(ps => {
                                    return <option value={ps.id}>{ps.nome}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>

                        <FormControl fullWidth>
                            <InputLabel >{ i18n.t('dispositivo.fazenda', 'Fazenda') }</InputLabel>
                            <NativeSelect
                                id='ns_fazend_id'
                                fullWidth
                                value={this.state.fazenda_id}
                                name='fazenda_id'
                                onChange={this.handleChange}
                            >
                                <option value={0}></option>
                                {this.state.fazendas.map(fz => {
                                    return <option value={fz.id}>{fz.nome}</option>
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="tf_valor_mensal"
                                label={ i18n.t('dispositivo.valor_mensal', 'Valor Mensal') }
                                name="valor_mensal"
                                fullWidth
                                type="number"
                                value={this.state.valor_mensal}
                                onChange={this.handleChange}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <CustomDatePicker
                                disabled={this.state.prevent}
                                onClick={this.handlePrevent}
                                disableToolbar
                                label={ i18n.t('dispositivo.data_instalacao', 'Data da Instalação') }
                                variant="inline"
                                format="DD/MM/YYYY"
                                name="data_instalacao"
                                value={this.state.data_instalacao}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item className={classes.container} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel >{ i18n.t('dispositivo.status', 'Status') }</InputLabel>
                        <NativeSelect
                            id='ns_status'
                            fullWidth
                            value={this.state.status}
                            name='status'
                            onChange={this.handleChange}
                        >
                            <option value={1}>{ i18n.t('status.ativo', 'Ativo') }</option>
                            <option value={0}>{ i18n.t('status.inativo', 'Inativo') }</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
            </ValidatorForm >
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormDispositivo)