import { dashboardActions } from "../consts"
import { dispositivoService } from "../../services/dispositivoService"
import { fazendaService } from "../../services/fazendaService"
import { parcelaService } from "../../services/parcelaService"
import { equipamentoService } from "../../services/equipamentoService"

export function handleOpenMenu(value){
    return {
        type: dashboardActions.DASHBOARD_OPEN_MENU,
        payload: {open: value}
    }
}

export function handleOpenSettings(value){
    return {
        type: dashboardActions.DASHBOARD_OPEN_SETTINGS,
        payload: {openSettings: value}
    }
}

export const reloadDispositivos = async (query={status: 1}) => {
    query.fields = ['id', 'nome', 'idx_dispositivo', 'localizacao', 'tipo', 'data_instalacao']
    const response = await dispositivoService.get(query)
    return {
        type: dashboardActions.RELOAD_DISPOSITIVOS,
        payload: {response}
    }
}

export const reloadFazendas = async (query={status: 1}) => {
    //query.exibir = 1
    query.fields = ['id', 'nome', 'area', 'delimitacao', 'localizacao']
    const response = await fazendaService.get(query) 
     return {
        type: dashboardActions.RELOAD_FAZENDAS,
        payload: {response}
    }
   
}

export const reloadParcelas = async (query={status: 1}) => {
    query.fields = ['id', 'nome', 'area', 'delimitacao', 'cor']
    const response = await parcelaService.get(query)
    return {
        type: dashboardActions.RELOAD_PARCELAS,
        payload: {response}
    }
}
             
export const reloadEquipamentos = async (query={status: 1}) => {
    query.fields = ['id', 'nome', 'area', 'delimitacao']
    const response = await equipamentoService.get(query)
    return {
        type: dashboardActions.RELOAD_EQUIPAMENTOS,
        payload: {response}
    }
}