import { dashboardActions } from "../consts"

const INITIAL_STATE = {
    open: false,
    openSettings: null,
    openFilters: null,

    dispositivos: [],
    parcelas: [],
    fazendas: [],
    equipamentos: []
}

function validateResponse(response) {
    if (response && response.status === 200) {
        return response.data
    }
    else return []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case dashboardActions.DASHBOARD_OPEN_MENU:
            return { ...state, open: action.payload.open }
        case dashboardActions.DASHBOARD_OPEN_SETTINGS:
            return { ...state, openSettings: action.payload.openSettings }
        case dashboardActions.RELOAD_DISPOSITIVOS:
            return { ...state, dispositivos: validateResponse(action.payload.response) }
        case dashboardActions.RELOAD_FAZENDAS:
            return { ...state, fazendas: validateResponse(action.payload.response) }
        case dashboardActions.RELOAD_PARCELAS:
            return { ...state, parcelas: validateResponse(action.payload.response) }
        case dashboardActions.RELOAD_EQUIPAMENTOS:
            return { ...state, equipamentos: validateResponse(action.payload.response) }
        default:
            return state
    }
}