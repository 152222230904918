import React from 'react';
import { buildEstacaoCebolaAttributes } from './chart_attributes.js';
import { estacaoCebolaDataService } from '../../services/estacaoCebolaDataService.js';
import DispositivoChart from './DispositivoChart.jsx';

export default (props) => {
    return (
        <DispositivoChart
            dispositivo={props.data}
            service={ estacaoCebolaDataService }
            allAttributes={buildEstacaoCebolaAttributes()}
        ></DispositivoChart>
    )
}