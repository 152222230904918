import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, Grid } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { clearAllMask } from '../../util/utils'
import i18n from '../../../i18n'

class FormEndereco extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pais: "",
            estado: "",
            cidade: "",
            logradouro: "",
            coordenada: {type: 'Point', coordinates: [0, 0]}
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        if( ! data.coordenada ) data.coordenada = {type: 'Point', coordinates: [0, 0] }
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        if (this.formRef) {
            this.formRef.current.submit()
        }
    }

    handleSubmit = () => {
        const data = {...this.state}
        data.rg = clearAllMask(data.rg)
        data.cpf = clearAllMask(data.cpf)
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeLocation = (e) => {
        const { name, value } = e.target;
        if (name === 'lng') this.state.coordenada.coordinates[0] = value
        else if (name === "lat") this.state.coordenada.coordinates[1] = value
        this.setState({})
    }

    render() {
        const { classes } = this.props
        return (
            <ValidatorForm
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => { this.handleSubmit() }}>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_pais"
                        label={i18n.t('endereco.pais', 'País')}
                        name="pais"
                        fullWidth
                        autoFill='off'
                        type="text"
                        value={this.state.pais}
                        onChange={this.handleChange}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_estado"
                        label={i18n.t('endereco.estado', 'Estado')}
                        name="estado"
                        fullWidth
                        autoFill='off'
                        type="text"
                        value={this.state.estado}
                        onChange={this.handleChange}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_cidade"
                        label={i18n.t('endereco.cidade', 'Cidade')}
                        name="cidade"
                        fullWidth
                        autoFill='off'
                        type="text"
                        value={this.state.cidade}
                        onChange={this.handleChange}
                    >
                    </TextValidator>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextValidator
                        id="tf_logradouro"
                        label={i18n.t('endereco.logradouro', 'Logradouro (Completo)')}
                        name="logradouro"
                        fullWidth
                        autoFill='off'
                        type="text"
                        value={this.state.logradouro}
                        onChange={this.handleChange}
                    >
                    </TextValidator>
                </FormControl>
                <Grid container item className={classes.container} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="latitude"
                                label={i18n.t('endereco.latitude', 'Latitude')}
                                name="lat"
                                fullWidth
                                required
                                type="number"
                                value={this.state.coordenada.coordinates[1]}
                                onChange={this.handleChangeLocation}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextValidator
                                id="longitude"
                                label={i18n.t('endereco.longitude', 'Longitude')}
                                name="lng"
                                fullWidth
                                required
                                type="number"
                                value={this.state.coordenada.coordinates[0]}
                                onChange={this.handleChangeLocation}
                            >
                            </TextValidator>
                        </FormControl>
                    </Grid>
                </Grid>
            </ValidatorForm>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 600,
        maxWidth: 700,
        minWidth: 700
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FormEndereco)