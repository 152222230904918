import React from 'react'
import PropTypes from 'prop-types'
import { Map, LayersControl, TileLayer, Marker, Popup, Polygon, Tooltip } from 'react-windy-leaflet'
import { withStyles, IconButton, Container, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Edit, BarChart, LocalPizza ,Tonality , AvTimer} from '@material-ui/icons'

import { appCredentials, appColors } from '../../constants/app_constants'
import { houseIcon, windSensorIcon , SprinklersIcon } from './icons'
import { reverseGeocodingPolygon, reverseGeocodingPoint } from '../../util/geo_util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSatellite,faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
 
const { BaseLayer } = LayersControl

class WindyMap extends React.Component {
    constructor(props) {
        super(props)
    }
    
    showFazendas() {
             if (this.props.isShowFazendas && this.props.fazendas) {
            try {
                return this.props.fazendas.map(fazenda => {
                    return <Marker
                        key={fazenda.id}
                        icon={houseIcon}
                        position={reverseGeocodingPoint(fazenda.localizacao.coordinates)}>
                        <Popup>
                            <Container>
                                <div><text style={{ color: appColors.ICONS_CONFIRM, fontSize: 16 }}>{`
                                    ${fazenda.nome} - ${fazenda.area} ha`}</text></div>
                                <IconButton
                                    onClick={e => this.props.handleEditFazenda(e, fazenda)}
                                >
                                    <Edit style={{ color: appColors.PRIMARY }} />
                                </IconButton>
                                {/* <IconButton
                                    onClick={e => this.props.handleCreateParcela(e, fazenda)}
                                >
                                    <LocalPizza style={{ color: appColors.PRIMARY }} />
                                </IconButton> */}
                            </Container>
                        </Popup>
                        <Tooltip direction="bottom" offset={[0, 20]}>
                            <Typography>{fazenda.nome}</Typography>
                        </Tooltip>
                    </Marker>
                })
            } catch (error) {
            }

        }
    }

    showDispositivos() {
        if (this.props.isShowDispositivos) {
            return this.props.dispositivos.map(dispositivo => {
                return <Marker
                    icon={windSensorIcon}
                    position={reverseGeocodingPoint(dispositivo.localizacao.coordinates)}>
                    <Popup>
                        <Container>
                            <div><text style={{ color: appColors.ICONS_CONFIRM, fontSize: 16 }}>{
                                `${dispositivo.nome} - ${dispositivo.idx_dispositivo}`}</text></div>
                            <IconButton
                                onClick={e => { this.props.handleEditDispositivo(e, dispositivo) }}
                            >
                                <Edit style={{ color: appColors.PRIMARY }} />
                            </IconButton>
                            <IconButton
                                onClick={e => this.props.handleShowChart(e, dispositivo)}
                            ><BarChart style={{ color: appColors.PRIMARY }} /></IconButton>
                        </Container>
                    </Popup>
                    <Tooltip direction="bottom" offset={[0, 20]} >
                        <Typography>{dispositivo.nome}</Typography>
                    </Tooltip>
                </Marker>
            })
        }
    }



    showEquipamentos() {
        if (this.props.isShowEquipamentos) {
            return this.props.equipamentos.map(dispositivo => {
                return <Marker
                    key={dispositivo.id}
                    icon={SprinklersIcon}
                    position={reverseGeocodingPoint(dispositivo.localizacao_centro.coordinates)}>
                    <Popup>
                        <Container>
                            <div><text style={{ color: appColors.ICONS_CONFIRM, fontSize: 16 }}>{
                                `${dispositivo.nome} - ${dispositivo.area} ha`}</text></div>
                            <IconButton
                                onClick={e => { this.props.handleEditEquipamentos(e, dispositivo) }}
                            >
                                <Edit style={{ color: appColors.PRIMARY }} />
                            </IconButton>
                           
                        </Container>
                    </Popup>
                    <Tooltip direction="bottom" offset={[0, 20]} >
                        <Typography>{dispositivo.nome}</Typography>
                    </Tooltip>
                </Marker>
            })
        }
    }

    showParcelas() {
      
        if (this.props.isShowParcelas) {
            return this.props.parcelas.map(parcela => {
                return <Polygon
                    key = {parcela.id}
                    positions={parcela.delimitacao.coordinates[0]}
                    color={parcela.cor}
                >
                    <Popup>
                        <Container>
                            <div><text style={{ color: appColors.ICONS_CONFIRM, fontSize: 16 }}>{
                                `${parcela.nome} - ${parcela.area} ha`}</text></div>
                            
                            <IconButton
                                onClick={e => this.props.handleEditParcela(e, parcela)}
                            >
                                <Edit style={{ color: appColors.PRIMARY }} />
                               <p style={{fontSize:10 }} > Editar</p>
                            </IconButton>
 
                            <IconButton
                                onClick={e => this.props.handleQualiagroParcela(e, parcela)}
                            >
                                 <FontAwesomeIcon style={{ color: appColors.PRIMARY }} icon={faSatelliteDish} />

                                <p style={{fontSize:10 }} > Qualiagro </p>
                            </IconButton>
                           
                            <IconButton
                                onClick={e => this.props.handleOrbitalParcela(e, parcela)}
                            >
                               <FontAwesomeIcon style={{ color: appColors.PRIMARY }} icon={faSatellite} />

                                <p style={{fontSize:10 }} > Orbital </p>
                            </IconButton>

                       </Container>
                    </Popup>
                    <Tooltip direction="bottom" offset={[0, 20]} >
                        <Typography>{parcela.nome}</Typography>
                    </Tooltip>
                </Polygon>
            })
        }
    }

    render() {
        const { classes } = this.props
        return <Map
            windyKey={appCredentials.WIND_KEY}
            className={classes.mapMain}
            windyLabels={true}
            windyControls={true}
            overlayOpacity={this.props.opacity}
            particlesAnim={false}
            zoom={this.props.zoom}
            maxZoom={17}
            center={[0, 0]}
            onWindyMapReady={() => {}}
            mapElements={

                
                <LayersControl position="bottomright">
                    <BaseLayer checked name="World Imagery" >
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                      
                    </BaseLayer>

                    {this.showEquipamentos()}

                    {this.showFazendas()}

                    {this.showDispositivos()}

                    {this.showParcelas()}

                </LayersControl>
            }>
        </Map>
    }
}

const styles = (theme) => ({
    mapMain: {
        width: "100%",
        height: "90vh"
    },
    p:{
        fontSize:5
    }
})

WindyMap.propTypes = {
    opacity: PropTypes.number,
    zoom: PropTypes.number,
    isShowFazendas: PropTypes.bool,
    isShowDispositivos: PropTypes.bool,
    isShowParcelas: PropTypes.bool,
    isShowEquipamentos: PropTypes.bool,
    fazendas: PropTypes.array.isRequired,
    dispositivos: PropTypes.array.isRequired,
    parcelas: PropTypes.array.isRequired,

    handleEditFazenda: PropTypes.func,
    handleEditParcela: PropTypes.func,
    handleOribitalParcela: PropTypes.func,
    handleQualiagroParcela: PropTypes.func,
    handleEditEquipamentos :PropTypes.func,
   
    handleCreateParcela: PropTypes.func,
    handleEditDispositivo: PropTypes.func,
    handleShowChart: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        opacity: state.windyMap.opacity,
        zoom: state.windyMap.zoom,
        isShowFazendas: state.windyMap.isShowFazendas,
        isShowDispositivos: state.windyMap.isShowDispositivos,
        isShowParcelas: state.windyMap.isShowParcelas,
        isShowEquipamentos: state.windyMap.isShowEquipamentos,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(WindyMap)
)
