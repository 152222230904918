import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, InputLabel} from '@material-ui/core'
import { filterAndDeleteValues, makeLikeQuerys } from '../../helpers/form_helper'

import i18n from '../../../i18n';

class FilterFazenda extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: "",
            status: "",
            exibir: "",
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        this.handleSubmit()
    }

    handleSubmit = () => {
        let data = filterAndDeleteValues(this.state, {nome: [''], status: [''], exibir: ['']})
        data = makeLikeQuerys(data, ['nome'])
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => {
                    e.preventDefault()
                    this.handleSubmit()
                }
                }>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_nome"
                        label={i18n.t('filterfazenda.nomefazenda', 'Nome da Fazenda')}
                        name="nome"
                        fullWidth
                        type="text"
                        value={this.state.nome}
                        onChange={this.handleChange}
                        autoFocus={true}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{i18n.t('filterfazenda.status', 'Status')}</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={""}></option>
                        <option value={1}>{i18n.t('filterfazenda.ativo', 'Ativo')}</option>
                        <option value={0}>{i18n.t('filterfazenda.inativo', 'Inativo')}</option>
                    </NativeSelect>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
            <InputLabel >{ i18n.t('filterfazenda.exibirnomapa', 'Exibir no Mapa') }</InputLabel>
                    <NativeSelect
                        id='exibir'
                        fullWidth
                        value={this.state.exibir}
                        name='exibir'
                        onChange={this.handleChange}
                    >
                        <option value={""}></option>
                        <option value={1}>{i18n.t('filterfazenda.sim', 'Sim')}</option>
                        <option value={0}>{i18n.t('filterfazenda.nao', 'Não')}</option>
                    </NativeSelect>
                </FormControl>
            </form>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 300,
        maxWidth: 300,
        minWidth: 300
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FilterFazenda)