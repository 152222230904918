import React from 'react'
import { CardContent, Grid, Button, Typography, Card, TextField, FormControl } from '@material-ui/core'
import { appColors } from '../../constants/app_constants'
import getFotoFile from '../../services/qualiagroService'
import { withStyles } from '@material-ui/core/styles'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import {
    PictureAsPdf
} from '@material-ui/icons';
import { IMG_BACK } from '../../constants/base64images/BACKGROUND'
import { LOGO } from '../../constants/base64images/LOGO'
class RelatorioQualiagro extends React.Component {

    constructor(props) {
        super(props);

        this.state = { canvasArray: [] };
        this.captureRef = React.createRef();
    }
    componentDidMount() {

        // console.log(this.props)
    }

    dateConvert(date) {
        var dateParse = new Date(date)
        return dateParse.toLocaleDateString()
    }

    handleToPdf = () => {
        let doc = new jsPDF('p', 'mm', 'a4')
        html2canvas(this.captureRef.current, { allowTaint: true, useCORS: true, backgroundColor: "rgba(0,0,0,0)", removeContainer: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1.0)
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;
            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;

        
           
                doc.addImage(IMG_BACK, 'PNG', 0, 0, 210, 300)
                doc.addImage(LOGO, 'PNG', 2, 2, 36, 13)
                doc.setTextColor(255, 255, 255)
                doc.setFontSize(20)
                doc.writeText("Relatório Qualiagro", { align: 'center' }, 20, 20)
                doc.writeText(`Parcela : ${this.props.data.nome}`, { align: 'center' }, 30, 30)
                doc.addImage(imgData, 'PNG', marginX + 2, 40, canvasWidth, canvasHeight)
                doc.save("icrop.pdf");
            })
    

}


render() {
    const { classes } = this.props
    return <>


        <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM, fontSize: '40px', marginBottom: '2vh' }}>
            {"Relatório Qualiagro"}
        </Typography>
        <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM, fontSize: '25px', marginBottom: '2vh' }}>
            {`Parcela : ${this.props.data.nome}`}
        </Typography>
        <div ref={this.captureRef} >
            <Grid container item className={classes.form}  >
                {this.props.data.qualiagros ? this.props.data.qualiagros.map((i) =>
                    new Date(i.createdAt).getTime() <= new Date(this.props.data.dateFilter.data_final).getTime() && new Date(i.createdAt).getTime() >= new Date(this.props.data.dateFilter.data_inicial).getTime() ?
                        <Card id={'chart'} key={i} className={classes.cardSpace}  >
                            <CardContent>
                                <img src={getFotoFile(i.id)} className={classes.image}></img>
                                <Typography align={'center'} style={{ color: appColors.ICONS_CONFIRM, fontSize: '25px' }}>
                                    {this.dateConvert(i.createdAt)}
                                </Typography>
                            </CardContent>
                        </Card>
                        : ""
                )
                    : ""}
            </Grid>

            <Grid style={{ marginTop: '8vh', marginLeft: 'auto', marginRight: 'auto' }} item xs={8}>
                <Typography style={{ color: '#ffffff', fontSize: '20px', marginBottom: '2vh' }}>
                    {"Observações:"}
                </Typography>
                <FormControl fullWidth >

                    <TextField
                        fullWidth
                        style={{

                            color: appColors.ICONS_CONFIRM, backgroundColor: '#ffffff',
                        }}
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        id="outlined-basic"
                        label="Observação"
                        variant="outlined"
                        multiline
                        rows={4}
                        rowsMax={4} />
                </FormControl>
            </Grid>

        </div>

        <Grid style={{ marginTop: '4vh' }} >

            <Button
                fullWidth
                style={{ color: appColors.ICONS_CONFIRM }}
                outlined
                onClick={this.handleToPdf}>
                <PictureAsPdf /> Gerar PDF</Button>

        </Grid>
    </>
}
}



const styles = (theme) => ({
    root: {
        color: '#ffffff',
        // backgroundColor: '#201F29',
    },
    nested: {
        paddingRight: theme.spacing(4),
    },
    labelColor: {
        color: 'white'
    },
    image: {
        width: '500px',
        height: '500px',
        borderRadius: '50%',
        backgroundColor: 'transparent',

    },
    cardSpace: {

        backgroundColor: 'transparent'
    },
    title: {
        color: appColors.ICONS_CONFIRM,
        align: 'center'
    },
    form: {
        //width: '750px',
        // height: '450px',
        marginLeft: '4vw',
        alignItems: 'center',
        align: 'center',
        padding: 'none',
        marginTop: '4vh',
        color: appColors.ICONS_CONFIRM,
        backgroundColor: 'transparent'

    },
    resize: {
        fontSize: 20
    },
})

export default withStyles(styles)(RelatorioQualiagro)