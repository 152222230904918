import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom"
import clsx from 'clsx';
import {
  CssBaseline, AppBar, Toolbar, List, Select,
  Divider, IconButton, Drawer, Container, Menu, Grid, Typography, Slider, Card, TextField
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {  Settings } from '@material-ui/icons'
import {
  makeObjectListDialog, makeAlertDialog, makeActionFormDialog,
} from "../default/dialogs/DialogRefInterface"
import { MainListItems } from './ListItems'
import { appColors } from '../../constants/app_constants'
import { userService } from '../../services/user_service'
import itemClick from './itemClick';
import TableFazenda from '../_fazenda/TableFazenda';
import WindyMap from '../_mapas/WindyMap';
import MapSettings from '../_mapas/MapSettings';
import { 
  handleOpenMenu, handleOpenSettings, 
  reloadDispositivos, reloadFazendas, reloadParcelas , reloadEquipamentos  
} from '../../reducers/dashboard/actions'
import TablePessoa from '../_pessoa/TablePessoa';
import browserHistory from '../../helpers/browser_history';
import TableDispositivo from '../_dispositivo/TableDispositivo';
import { fazendaService } from '../../services/fazendaService';
import { toast } from 'react-toastify';
import { validateShowResponseToast } from '../../helpers/toast_alerts';
import FormFazenda from '../_fazenda/FormFazenda';

import TableParcela from '../_parcela/TableParcela';
import { parcelaService } from '../../services/parcelaService';
import FormParcela from '../_parcela/FormParcela';

import ListRight from '../../components/_parcela/listRight'
import ChartOrbital from '../../components/_parcela/ChartOrbital'
import RelatorioQualiagro from '../../components/_parcela/RelatorioQualiagro'

import TableEquipamento from '../_equipamento/TableEquipamento';
import { equipamentoService } from '../../services/equipamentoService';
import FormEquipamento from '../_equipamento/FormEquipamento';

import { dispositivoService } from '../../services/dispositivoService';
import FormDispositivo from '../_dispositivo/FormDispositivo';

import EstacaoCebolaChart from '../_dispositivo/EstacaoCebolaChart';
import CSVUpload from '../_csv_upload/CsvUpload';
import { tipoDispositivoConst } from '../../constants/model_constants';

import EstacaoSimplificadaChart from '../_dispositivo/EstacaoSimplificadaChart'; 
import EstacaoSateliteChart from '../_dispositivo/EstacaoSateliteChart'; 


import i18n from '../../../i18n';

const drawerWidth = 200;

const defaultModalConfig = { minWidth: 1200, minHeight: 600 }

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.browserHistory = browserHistory
   
  }

  
  componentDidMount(){
    this.props.reloadFazendas({status: 1})
    this.props.reloadDispositivos()
    this.props.reloadParcelas()
    this.props.reloadEquipamentos()
  }

  

  handleItemClick = (itemClicked) => {
    if (itemClicked === itemClick.FAZENDAS) {
      makeObjectListDialog(TableFazenda, i18n.t('dashboard.fazenda', 'Fazendas'), defaultModalConfig, null, null).current.open()
    } else if (itemClicked === itemClick.PESSOAS) {
      makeObjectListDialog(TablePessoa, i18n.t('dashboard.pessoas', 'Pessoas'), defaultModalConfig, null, null).current.open()
    } else if (itemClicked === itemClick.DISPOSITIVOS) {
      makeObjectListDialog(TableDispositivo, i18n.t('dashboard.dispositivo', 'Dispositivos'), defaultModalConfig, null, null).current.open()
    } else if (itemClicked === itemClick.PARCELAS) {
      makeObjectListDialog(TableParcela, i18n.t('dashboard.parcela', 'Parcelas'), defaultModalConfig, null, null).current.open()
    } else if (itemClicked === itemClick.UPLOAD) {
      makeActionFormDialog(CSVUpload, i18n.t('dashboard.upload', 'Subir dados coletados de dispositivos'), false, null, 'Enviar').current.open()
    } else if (itemClicked === itemClick.EQUIPAMENTOS) {
      makeActionFormDialog(TableEquipamento, i18n.t('dashboard.equipamentos', 'Equipamentos- Pivô/Setorial'), false, null, null).current.open()
    }
  }

  handleShowChart = (e, dispositivo) => {
    console.log(dispositivo)
    if(dispositivo.tipo === tipoDispositivoConst.ESTACAO_CEBOLA){ 
      const title = `${i18n.t("dashboard.titleestacaocebola", "Gráfico ")} 
      ${dispositivo.nome} - ${dispositivo.idx_dispositivo}`
      makeObjectListDialog(EstacaoCebolaChart, title, 
        { minWidth: 1200, minHeight: 600 }, dispositivo).current.open()
    }else if(dispositivo.tipo === tipoDispositivoConst.ESTACAO_SIMPLIFICADA){
      const title = `${i18n.t('dashboard.titleestacaosimplificada', 'Gráfico ')}
       ${dispositivo.nome} - ${dispositivo.idx_dispositivo}`
      makeObjectListDialog(EstacaoSimplificadaChart, title, 
        { minWidth: 1200, minHeight: 600 }, dispositivo).current.open()
    }else if(dispositivo.tipo === tipoDispositivoConst.ESTACAO_ICROP){
      const title = `${i18n.t('dashboard.titleestacaoicrop', 'Gráfico ')}
       ${dispositivo.nome} - ${dispositivo.idx_dispositivo}`
      makeObjectListDialog(EstacaoSateliteChart, title,  
        { minWidth: 1200, minHeight: 600 }, dispositivo).current.open()
    }
  }

  handleEditDispositivo = (e, dispositivo) => {
    try{
       dispositivoService.get({id: dispositivo.id}, response => {
         if(validateShowResponseToast(response, '', false)){
           const resDispositivo = response.data[0]
           makeActionFormDialog(FormDispositivo, `${i18n.t('dashboard.editardispositivo', 'Editar Dispositivo')} ${dispositivo.nome}`, false, 
           resDispositivo, i18n.t('tableactions.save', 'Salvar'), data => {
               dispositivoService.update(data, response => {
                 if(validateShowResponseToast(response)){
                    this.props.reloadDispositivos()
                 }
               })
             }).current.open()
         }
       })
    }catch(error){
      toast.warn(i18n.t('dashboard.errorabrirdispositivo', 'Erro ao abrir edição de Dispositivo'))
    }
 }

  handleEditFazenda = (e, fazenda) => {
     try{
        fazendaService.get({id: fazenda.id}, response => {
          if(validateShowResponseToast(response, '', false)){
            const resFazenda = response.data[0]
            makeActionFormDialog(FormFazenda, `${i18n.t('dashboard.editarfazenda', 'Editar Fazenda')} ${resFazenda.nome}`, false, 
              resFazenda, i18n.t('tableactions.save', 'Salvar'), data => {
                fazendaService.update(data, response => {
                  if(validateShowResponseToast(response)){
                     this.props.reloadFazendas()
                  }
                })
              }).current.open()
          }
        })
     }catch(error){
       toast.warn(i18n.t('dashboard.erroabriredicao', 'Erro ao abrir edição de fazenda'))
     }
  }

  handleCreateParcela = (e, fazenda) => {
    try{
      const fazendaParams = {fazenda_id: fazenda.id, fazenda}
      makeActionFormDialog(FormParcela, i18n.t('dashboard.novaparcela', 'Nova Parcela'), 
      false, fazendaParams, i18n.t('dashboard.salvar', 'Salvar'), data => {
        parcelaService.create(data, response => {
          if(validateShowResponseToast(response)){
            this.props.reloadParcelas()
          }
        })
      }).current.open()
    }catch(error){}
  }

  handleEditParcela = (e, parcela) => {
    try{
       parcelaService.get({id: parcela.id}, response => {
         if(validateShowResponseToast(response, '', false)){
           const resParcela = response.data[0]
           makeActionFormDialog(FormParcela, `${i18n.t('dashboard.editarparcela', 'Editar Parcela')} ${resParcela.nome}`, false, 
           resParcela, i18n.t('dashboard.salvar', 'Salvar'), data => {
               parcelaService.update(data, response => {
                 if(validateShowResponseToast(response)){
                    this.props.reloadParcelas() 
                 }
               })
             }).current.open()
         }
       })
    }catch(error){
      toast.warn(i18n.t('dashboard.erroabriredicaoparcela', 'Erro ao abrir edição de Parcela'))
    }
 }

 handleEditEquipamentos = (e, parcela) => {
  try{
     equipamentoService.get({id: parcela.id}, response => {
       if(validateShowResponseToast(response, '', false)){
         const resParcela = response.data[0]
         makeActionFormDialog(FormEquipamento, `${i18n.t('dashboard.editarequipamento', 'Editar Equipamento')} ${resParcela.nome}`, false, 
         resParcela, i18n.t('dashboard.salvar', 'Salvar'), data => {
             parcelaService.update(data, response => {
               if(validateShowResponseToast(response)){
                  this.props.reloadEquipamentos() 
               }
             })
           }).current.open()
       }
     })
  }catch(error){
    toast.warn(i18n.t('dashboard.erroabriredicaoequipamento', 'Erro ao abrir edição de Equipamento'))
  }
}
 
 handleQualiagroParcela = (e, parcela) => {
  try{
    parcelaService.get({id: parcela.id}, response => {
      if(validateShowResponseToast(response, '', false)){
        const resParcela = response.data[0]
        makeActionFormDialog(ListRight, `${i18n.t('dashboard.imagensqualiagro', 'Imagens Qualiagro ')} ${resParcela.nome}`, false, 
        resParcela, false, data => {
           
          }).current.open()
      }
    })
 }catch(error){
    toast.warn(i18n.t('dashboard.erroabriredicaoparcela', 'Erro ao abrir edição de Parcela'))
  }
}

handleOrbitalParcela = (e, parcela) => {
  try{
    parcelaService.get({id: parcela.id}, response => {
      if(validateShowResponseToast(response, '', false)){
        const resParcela = response.data[0]
        makeActionFormDialog(ChartOrbital, `${i18n.t('dashboard.imagensqualiagro', 'Imagens Qualiagro ')} ${resParcela.nome}`, false, 
        resParcela, false, data => {
          
          }).current.open()
      }
    })
 }catch(error){
    toast.warn(i18n.t('dashboard.erroabriredicaoparcela', 'Erro ao abrir edição de Parcela'))
  }
}



  handleChangeWindyOpacity = (e, value) => {
    this.setState({ windyOpacity: value })
  }

  handleChangeZoom = (e, value) => {
    this.setState({ mapZoom: value })
  }

  handleAgree = (event) => {
    userService.logout()
    this.props.history.push("/")
  }

  handleLogout = () => {
    makeAlertDialog(this.handleAgree, i18n.t('dashboard.atencao', 'Atenção'), 
    i18n.t('dashboard.sairaplicacao', 'Deseja realmente sair da aplicação')).current.open()
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, this.props.open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={() => this.props.handleOpenMenu(true)}
              className={clsx(classes.menuButton, this.props.open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.title} />
            
            <IconButton color="inherit" onClick={e => this.props.handleOpenSettings(e.target)}>
              <Settings />
            </IconButton>
            <Menu
              id="menu_settings"
              anchorEl={this.props.openSettings}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(this.props.openSettings)}
              onClose={() => { this.props.handleOpenSettings(null) }}
              MenuListProps={{ onMouseLeave: () => this.props.handleOpenSettings(null) }}
            >
              <MapSettings
                handleChangeZoom={this.handleChangeZoom}
                zoom={this.props.mapZoom}
              />
            </Menu>

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.props.open && classes.drawerPaperClose),
          }}
          open={this.props.open}
        >
          {this.props.open ? (
            <Grid container className={classes.headerDrawer}>
              <Grid item xs={10}  >
                <img style={{ marginTop: 15 }} width={'65%'} src="/static/images/logo.png"></img>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.toolbarIcon}>
                  <IconButton onClick={() => this.props.handleOpenMenu(false)}>
                    <ChevronLeftIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          ) : (
              <div className={classes.toolbarIcon}>
                <IconButton onClick={() => this.props.handleOpenMenu(false)}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>)
          }
          <Divider />
          <List>
            <MainListItems
              itemClickHandle={this.handleItemClick}
              browserHistory={this.browserHistory} />
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
       
             <WindyMap
              opacity={this.props.windyOpacity}
              zoom={this.props.mapZoom}
              fazendas={this.props.fazendas || []}
              parcelas={this.props.parcelas || []}
              equipamentos ={this.props.equipamentos || []}
              dispositivos={this.props.dispositivos || []}
              handleEditFazenda={this.handleEditFazenda}
              handleEditParcela={this.handleEditParcela}
              handleEditEquipamentos={this.handleEditEquipamentos}
              handleOrbitalParcela={this.handleOrbitalParcela}
              handleQualiagroParcela={this.handleQualiagroParcela}
              handleCreateParcela={this.handleCreateParcela}
              handleEditDispositivo={this.handleEditDispositivo}
              handleShowChart={this.handleShowChart}
            />
          </Container>
        </main>
      </div>
    );
  }
}


const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: appColors.PRIMARY
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed

  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    height: 70,
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  headerDrawer: {
    backgroundColor: appColors.PRIMARY,
    height: 70
  },
  drawerPaper: {
    backgroundColor: appColors.PRIMARY,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tooltip: {
    color: '#f1f1f1',
    rippleBackgroundColor: 'blue'
  },
})

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  openSettings: PropTypes.object,
  openFilters: PropTypes.object,
  dispositivos: PropTypes.array,
  fazendas: PropTypes.array,
  parcelas: PropTypes.array,
  equipamentos: PropTypes.array,
  
 
}

const mapStateToProps = (state) => {
  return {
    open: state.dashboard.open,
    openSettings: state.dashboard.openSettings,
    dispositivos: state.dashboard.dispositivos,
    fazendas: state.dashboard.fazendas,
    parcelas: state.dashboard.parcelas,
    equipamentos :state.dashboard.equipamentos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 
    handleOpenMenu, handleOpenSettings,
    reloadDispositivos, reloadFazendas, reloadParcelas,reloadEquipamentos
  }, dispatch)
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
) 