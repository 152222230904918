import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {
  Typography,
} from '@material-ui/core'
import {
  People,
  Home,
  SettingsInputAntenna,
  ExitToApp,
  LocalPizza,
  Backup,
  AvTimer,
} from '@material-ui/icons';
import itemClick from './itemClick';
import { makeAlertDialog } from '../default/dialogs/DialogRefInterface';
import { userService } from '../../services/user_service';

function switch_route(browserHistory, route) {
  browserHistory.push(route)
}

export function MainListItems(props) {
  let { browserHistory, itemClickHandle } = props
  if (!itemClickHandle) itemClickHandle = () => { }
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()

  function handleAgree() {
    userService.logout()
    switch_route(browserHistory, '/')
  }

  function handleLogout() {
    makeAlertDialog(
      handleAgree,
      t('listitems.atencao', 'Atenção'),
      t('listitems.mensagemalertasair', 'Deseja realmente sair da aplicação')
    ).current.open()
  }

  return (
    <div>
      <ListItem button onClick={() => itemClickHandle(itemClick.FAZENDAS)}>
        <ListItemIcon>
          <Home className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.fazenda', 'Fazendas')}</Typography>
      </ListItem>

      <ListItem button onClick={() => itemClickHandle(itemClick.EQUIPAMENTOS)}>
        <ListItemIcon>
          <AvTimer className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.equipamentospivosetorial', 'Equipamentos-Pivô/Setorial')}</Typography>
      </ListItem>

      <ListItem button onClick={() => itemClickHandle(itemClick.PARCELAS)}>
        <ListItemIcon>
          <LocalPizza className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.parcela', 'Parcelas')}</Typography>
      </ListItem>

      <ListItem button onClick={() => itemClickHandle(itemClick.PESSOAS)}>
        <ListItemIcon>
          <People className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.pessoas', 'Pessoas')}</Typography>
      </ListItem>

      <ListItem button onClick={() => itemClickHandle(itemClick.DISPOSITIVOS)}>
        <ListItemIcon>
          <SettingsInputAntenna className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.dispositivo', 'Dispositivos')}</Typography>
      </ListItem>

      <ListItem button onClick={() => itemClickHandle(itemClick.UPLOAD)}>
        <ListItemIcon>
          <Backup className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.upload', 'Subir Dados')}</Typography>
      </ListItem>

      <ListItem button onClick={() => handleLogout()}>
        <ListItemIcon>
          <ExitToApp className={classes.labelColor} />
        </ListItemIcon>
        <Typography className={classes.labelColor}>{t('listitems.saiaplicacao', 'Sair da Aplicação')}</Typography>
      </ListItem>
    </div>
  )
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  labelColor: {
    color: 'white'
  }
}));
