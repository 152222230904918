import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, InputLabel } from '@material-ui/core'
import { filterAndDeleteValues, makeLikeQuerys } from '../../helpers/form_helper'
import { tipoDispositivo } from '../../constants/model_constants'
import i18n from '../../../i18n'

class FilterDispositivo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: "",
            status: "",
            exibir: "",
            idx_dispositivo: "",
            tipo: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({ ...data })
    }

    /** Required on all open components in ActionFormDialog */
    action() {
        this.handleSubmit()
    }

    handleSubmit = () => {
        let data = filterAndDeleteValues(this.state, { nome: [''], status: [''], 
            idx_dispositivo: [''], tipo: ['']  })
        data = makeLikeQuerys(data, ['nome'])
        this.props.handleFormState(data)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                noValidate
                ref={this.formRef}
                onSubmit={e => {
                    e.preventDefault()
                    this.handleSubmit()
                }
                }>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_nome"
                        label={ i18n.t('dispositivo.nome', 'Descrição do Dispositivo') }
                        name="nome"
                        fullWidth
                        type="text"
                        value={this.state.nome}
                        onChange={this.handleChange}
                        autoFocus={true}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <TextField
                        id="tf_idx_dispositivo"
                        label={ i18n.t('dispositivo.id', 'Id do Dispositivo') }
                        name="idx_dispositivo"
                        fullWidth
                        type="text"
                        value={this.state.idx_dispositivo}
                        onChange={this.handleChange}
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel >{ i18n.t('dispositivo.tipo', 'Tipo de Dispositivo') }</InputLabel>
                    <NativeSelect
                        id='ns_tipo'
                        fullWidth
                        value={this.state.tipo}
                        name='tipo'
                        onChange={this.handleChange}
                    >
                        <option value={""}></option>
                        {tipoDispositivo.map(td => {
                            return <option value={td.id}>{td.desc}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                <FormControl fullWidth item className={classes.container}>
                    <InputLabel >{ i18n.t('dispositivo.status', 'Status') }</InputLabel>
                    <NativeSelect
                        id='ns_status'
                        fullWidth
                        value={this.state.status}
                        name='status'
                        onChange={this.handleChange}
                    >
                        <option value={""}></option>
                        <option value={1}>{i18n.t('status.ativo', 'Ativo') }</option>
                        <option value={0}>{i18n.t('status.inativo', 'Inativo') }</option>
                    </NativeSelect>
                </FormControl>
            </form>
        )
    }
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        minHeigth: 300,
        maxWidth: 300,
        minWidth: 300
    },
    formControl: {
        minWidth: "200px"
    },
    container: {
        marginTop: "15px"
    }
})

export default withStyles(styles)(FilterDispositivo)