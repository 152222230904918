
import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import {
    Container,
    Grid,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import CustomDatePicker from '../default/custom/CustomDatePicker'
import { appColors } from '../../constants/app_constants'

class FilterRelatorio extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_inicial: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            data_final: moment().format('YYYY-MM-DD'),
            relatorioType: 1,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleAction = this.handleAction.bind(this)
    }

    action() {
        if (this.state.data_inicial !== '' && this.state.data_final !== '') {
            if (this.props.handleFormState) this.props.handleFormState(this.state)
        } else {
            toast.warn("Data não preenchida ou inválida!")
        }
    }

    handleAction(e) {
        if (e.key === 'Enter') {
            this.action()
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <form className={{ display: 'flex', flexWrap: 'wrap', }}
                    noValidate
                    onSubmit={e => { e.preventDefault() }}
                >
                    {/* <Container style={{ marginTop: 20 }} >
                        <InputLabel htmlFor="age-simple">Tipo de Relatório</InputLabel>
                        <Select
                            fullWidth
                            value={this.state.relatorioType}
                            name='relatorioType'
                            onChange={this.handleChange}
                        >
                            <MenuItem value={1}>{`Padrão Aneel`}</MenuItem>
                            <MenuItem value={2}>{`Memória de massa`}</MenuItem>
                        </Select>
                    </Container> */}
                    <Container style={{ marginTop: 20 }} >
                        <InputLabel htmlFor="age-simple">Data Inicial</InputLabel>
                        <CustomDatePicker
                            disableToolbar
                            variant="inline"
                            iconColor={appColors.PRIMARY}
                            format="DD/MM/YYYY"
                            name="data_inicial"
                            value={this.state.data_inicial}
                            onChange={this.handleChange}
                            KeyboardButtonProps={{
                                'aria-label': 'Mudar data',
                            }}
                        />
                    </Container>
                    <Container style={{ marginTop: 20 }} >
                        <InputLabel htmlFor="age-simple">Data Final</InputLabel>
                        <CustomDatePicker
                            disableToolbar
                            iconColor={appColors.PRIMARY}
                            variant="inline"
                            format="DD/MM/YYYY"
                            name="data_final"
                            value={this.state.data_final}
                            onChange={this.handleChange}
                            KeyboardButtonProps={{
                                'aria-label': 'Mudar data',
                            }}
                        />
                    </Container>
                </form>
            </Grid>
        )
    }
}

export default (FilterRelatorio)




