import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';
import {  generateGetWithAuth } from './service'

export const parcelaService = {
   get,
   create,
   update,
   getFotoFile
}

function getFotoFile(id){
    const url =  generateGetWithAuth(`/qualiagro/file/${id}`) 
    return url
 }

async function get(data, handleResponse, handleAction){
    let response = undefined 
    let errorResponse = undefined
    const query = make_query(data)
    response = await getAxiosInstance(`/parcelas${query}`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
    return response || errorResponse
}

async function create(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/parcelas').post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function update(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/parcelas/${data.id}`).put(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}
