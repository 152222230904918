import React from 'react'
import { Typography } from '@material-ui/core'
import { formatMoney, convertStatusToText } from '../../../util/utils'
import moment from 'moment'

const TABLE_FONT_SIZE = "12pt"

export default function FormatValue(props){
    const {value, type} = props 
    switch (type) {
        case 'money':
          return (
            <Typography style={{ fontSize: TABLE_FONT_SIZE }}>
              {formatMoney(value, 2)}
            </Typography>)
        case 'date':
          const date = value ? moment.utc(value).format('DD/MM/YYYY') : ""
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE }}>{date}</Typography>)
        case 'date_time':
          const dateTime = value ? moment.utc(value).format("DD/MM/YYYY HH:mm:ss") : ""
          return (<Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', display: 'block', fontSize: TABLE_FONT_SIZE }}>{dateTime}</Typography>)
        case 'status':
          const status = convertStatusToText(value)
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE, color: value == 1 ? "green" : "red" }}>{status}</Typography>)
        case 'signed_number':
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE, color: value > 0 ? "green" : "red" }}>{value}</Typography>)
        case 'precision2':
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE }}>{Number(value).toLocaleString(navigator.language)}</Typography>)
        case 'percent':
            return (<Typography style={{ fontSize: TABLE_FONT_SIZE }}>{Number(value).toLocaleString(navigator.language)}%</Typography>)
        case 'boolean':
            return value ?  <Typography style={{ fontSize: TABLE_FONT_SIZE, color: "green" }}>SIM</Typography>:
                            <Typography style={{ fontSize: TABLE_FONT_SIZE, color: 'red'}}>NÃO</Typography>
        case 'text':
          return (<Typography  style={{ maxWidth: "200px" }}>{value}</Typography>)
        case 'custom':
          return (<Typography >{value}</Typography>)
        case 'custom_row':
          return value
        case 'name':
          let name = value.split(" ")
          name = `${name[0]} ${name[1] && name[1].length > 3 ? name[1] : name[2] || ""}`
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE }}>{name}</Typography>)
        default:
          return (<Typography style={{ fontSize: TABLE_FONT_SIZE }}>{value}</Typography>)
    }
}