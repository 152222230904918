import { authHeader } from '../helpers/auth_header';
import { BASE_URL } from '../constants/urls'
import { getAxiosInstance } from '../services/service'
import { make_query } from './service_util';

require('dotenv').config()

export const userService = {
    login,
    logout,
    getAll,
    getUsuarios,
    createUsuario,
    updateUsuario,
    getUsuario,
    registerUsuario,
    recoverSenha,
    sendRecoverSenha
};

async function sendRecoverSenha(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuarios/recuperar/${data.email}/${data.url}`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function recoverSenha(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuarios/recuperar/${data.token}`).post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getUsuario(handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/usuario').get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function getUsuarios(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    const query = make_query(data)
    response = await getAxiosInstance(`/usuarios${query}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function updateUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuarios/${data.id}`).put(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function registerUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/usuarios/register/${data.token}`).post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function createUsuario(data, handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/usuarios').post(undefined, data).catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}

async function login(usuario, senha) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario, senha })
    };
    const response = await fetch(`${BASE_URL}/usuarios/login`, requestOptions);
    const user = await handleResponse(response);
    if (user) {
        user.authdata = window.btoa(usuario + ':' + senha);
        localStorage.setItem('user', JSON.stringify(user));
    }
    return user;
}

function logout() {
    localStorage.removeItem('user');
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${BASE_URL}/usuario`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
