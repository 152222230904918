import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { headRowsDispositivo } from '../../constants/headRows'
import ObjectTable from '../default/table/ObjectTable'
import { Add, Edit, NotListedLocation, FilterList, ShowChart, BarChart} from '@material-ui/icons'
import { appColors } from '../../constants/app_constants'
import { validateShowResponseToast } from '../../helpers/toast_alerts'
import {  makeActionFormDialog, makeObjectListDialog2 } from '../default/dialogs/DialogRefInterface'
import { dispositivoService } from '../../services/dispositivoService'
import FormDispositivo from './FormDispositivo'
import FilterDispositivo from './FilterDispositivo'
import { reloadDispositivos } from '../../reducers/dashboard/actions'
import EstacaoCebolaChart from './EstacaoCebolaChart'
import { tipoDispositivoConst } from '../../constants/model_constants'
import EstacaoSimplificadaChart from './EstacaoSimplificadaChart'
import i18n from '../../../i18n'

class TableDispositivo extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: [],
            headRows: headRowsDispositivo(),
            lastFilter: {status: 1}
         }
         this.objectTableRef = React.createRef()
    }

    getDispositivos = () => {
        dispositivoService.get(this.state.lastFilter, response => {
            if(validateShowResponseToast(response, "", false)){
                this.setState({
                    rows: response.data
                })
            }
        })
    }
  
    componentDidMount(){
       this.getDispositivos()
    }

    selectedToolActions = [] 

    toolActions = [
        {
            title: i18n.t('tableactions.filter', "Filtrar"),
            color: appColors.PRIMARY,
            MaterialIcon: FilterList,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FilterDispositivo, i18n.t('tabledispositivo.filtrardispositivos', 'Filtrar Dispositivos'), false, 
                    this.state.lastFilter, "Filtrar", data => {
                    this.state.lastFilter = data
                    this.getDispositivos()
                }).current.open()
            },
        },
        {
            title: i18n.t('tableactions.add', 'Adicionar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Add,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormDispositivo, "Cadastrar Dispositivo", false, {}, "Salvar", data => {
                    dispositivoService.create(data, response => {
                        if(validateShowResponseToast(response)){
                            this.getDispositivos()
                            this.props.reloadDispositivos()
                        }
                    })
                }).current.open()
            },
        },
    ]
    actions = [
        {
            title: i18n.t('tabledispositivo.graficos', 'Gráficos'),
            color: appColors.PRIMARY,
            MaterialIcon: BarChart,
            handleClick: (e, selected) => {
                e.stopPropagation()
                if(selected.tipo === tipoDispositivoConst.ESTACAO_CEBOLA){
                    const title = `${i18n.t('tabledispositivo.graficode', 'Gráfico de')} 
                    ${selected.nome} - ${selected.idx_dispositivo}`
                    makeObjectListDialog2(EstacaoCebolaChart, title,{ minWidth: 1200, minHeight: 600 }, selected).current.open()
                }else if(selected.tipo === tipoDispositivoConst.ESTACAO_SIMPLIFICADA){
                    const title = `${i18n.t('tabledispositivo.graficode', 'Gráfico de')} 
                    ${selected.nome} - ${selected.idx_dispositivo}`
                    makeObjectListDialog2(EstacaoSimplificadaChart, title,{ minWidth: 1200, minHeight: 600 }, selected).current.open()
                }
            },
        },
        {
            title: i18n.t('tableactions.edit', 'Editar'),
            color: appColors.ICONS_CONFIRM,
            MaterialIcon: Edit,
            handleClick: (e, selected) => {
                e.stopPropagation()
                makeActionFormDialog(FormDispositivo, i18n.t('tabledispositivo.editardispositivo', 'Editar Dispositivo'), 
                false, selected, "Salvar", data => {
                    dispositivoService.update(data, response => {
                        if(validateShowResponseToast(response)){
                            this.getDispositivos()
                            this.props.reloadDispositivos()
                        }
                    })
                }).current.open()
            },
        },
    ]

    render(){
        return(
            <ObjectTable 
                    rowId='id'
                    tableName='' 
                    headRows={this.state.headRows} 
                    actions={this.actions}
                    selectedToolActions={this.selectedToolActions}
                    toolActions={this.toolActions}
                    rowSize={10}
                    rowsOptions={[20, 40, 80]}
                    dense={true}
                    ref={this.objectTableRef}
                    rows={this.state.rows || []}
                /> 
            )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ reloadDispositivos}, dispatch)
}

export default connect(null, mapDispatchToProps)(TableDispositivo)