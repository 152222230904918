import moment from 'moment'

export function sortByDate(data = [], attr) {
    try {
        return data.sort((a, b) => {
            if (moment(a[attr]).isAfter(moment(b[attr]))) {
                return 1
            } else if (moment(a[attr]).isBefore(moment(b[attr]))) {
                return -1
            }
            return 0
        })
    } catch (ex) {
        return data
    }
}

export function formatDateTime(dateTime) {
    return moment.utc(dateTime).format('DD/MM/YYYY : HH:mm:ss')
}

export function convertStatusToNumber(status) {
    if (status === "ATIVO" || status === "ATIVA" ||
        status == "ABERTO" || status === "ABERTA"
        || status === 1) {
        return 1
    } else {
        return 0
    }
}

export function convertStatusToText(status) {
    if (status == 1) {
        return "ATIVO"
    } else {
        return "INATIVO"
    }
}

export function formatCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function clearCPF(cpf) {
    return cpf.replace(/-/g, '').replace(/\./g, '')
}

export function clearAllMask(value) {
    try{
        return value.replace(/-/g, '').
        replace(/\./g, '').
        replace(/\(/g, '').
        replace(/\)/g, '').
        replace(/-/g, '').
        replace(/\//g, '')
    }catch(error){
        return ""
    }
}

export function formatName(value) {
    let name = value.split(" ")
    name = `${name[0]} ${name[1] && name[1].length > 3 ? name[1] : name[2] || ""}`
    return name
}

/**
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export function formatMoney(value, decimal = 2) {
    if (value >= 0) {
        return `R$${Number(value).format(decimal, 3, '.', ',')}`
    } else {
        return Number(value).format(decimal, 3, '.', ',').replace('-', '-R$')
    }
}

export function formatDecimal(value, decimal = 2) {
    if (decimal == -1) {
        return Number(value).toLocaleString()
    } else {
        return Number(value).format(decimal, 3, '.', ',')
    }
}

export function makeSum(rows, sumCols=[], headRows=[], rowId){
	let ret = {}
	headRows.map(hr => {
		if(sumCols.indexOf(hr.id) > -1){
			ret[hr.id] = 0
		}else{
			ret[hr.id] = '----'
		}
	})
	ret[rowId] = "Totais"
	rows.map(row => {
		sumCols.map(sc => {
			ret[sc] += row[sc] 
		})
	})
	return ret
}
