
export const appColors = {
    PRIMARY: "#201F29", // PRETO
    SECONDARY: "#1da70b", // VERDE ESCURO
    LIGHT:  "#9C9AAF", // CINZA
    DARK: "#00B050", // VERDE CLARO
    ICONS_CONFIRM: "#00B050", // VERDE CLARO
    ICONS_DANGER: 'red'
}

export const appCredentials = {
    WIND_KEY: "BiwGBSsyn15PjypJaqjSUoIjOvsFWq4c"
}


